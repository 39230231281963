import { Sentry } from '@cancellation-portal/assets/js/sentry';
import { SigneeType } from '@cancellation-portal/models/LoaInformation';
import type { Language } from '@cancellation-portal/models/SupplierInformation';
import { Locale, Market } from '@cancellation-portal/modules/app-context/constants';
import { PlatformName } from './models/platform';

export const getSigningName = (localizeMessage: (id: string) => string) => (signeeType?: SigneeType) => {
  switch (signeeType) {
    case SigneeType.BankIdSignee:
      return localizeMessage('bankId');
    case SigneeType.NemIdSignee:
      return localizeMessage('nemId');
    default:
      return undefined;
  }
};

export function languageToLocale(language: Language): Locale {
  switch (language) {
    case 'Swedish':
      return Locale.svSE;
    case 'Danish':
      return Locale.daDK;
    case 'English':
      return Locale.enGB;
    case 'AmericanEnglish':
      return Locale.enUS;
    case 'French':
      return Locale.frBE;
    case 'Flemish':
      return Locale.nlBE;
    case 'Dutch':
      return Locale.enGB; // TODO: Should this be available in dutch??
    case 'Finnish':
      return Locale.fiFI;
    case 'Norwegian':
      return Locale.nbNO;
    case 'Spanish':
      return Locale.esES;
    default:
      Sentry.captureMessage(`Unknown language ${language}, falling back to en-GB`);

      return Locale.enGB;
  }
}

export function platformNameToBankLegalName(platformName: PlatformName): string {
  switch (platformName) {
    case PlatformName.Lloyds:
      return 'Lloyds Bank plc';
    case PlatformName.Minna:
      return 'Minna Technologies Inc.';
    case PlatformName.CapitalOne:
      return 'Capital One Services, LLC';
    default:
      Sentry.captureMessage(`No bank legal name is set up yet for ${platformName}.`);

      return '';
  }
}

export function platformNameToBankRegNumber(platformName: PlatformName): string {
  switch (platformName) {
    case PlatformName.Lloyds:
      return 'United Kingdom Registration No. 763256';
    case PlatformName.CapitalOne:
      return 'LEI: 549300V7U04X9CVH5926';
    default:
      Sentry.captureMessage(`No bank registration number is set up yet for ${platformName}.`);

      return '';
  }
}

export function getMinnaTechonoligesLegalName(market: Market): string {
  switch (market) {
    case Market.UnitedStates:
      return 'Minna Technologies Inc.';
    default:
      return 'Minna Technologies AB';
  }
}
