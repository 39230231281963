import classNames from 'classnames/bind';
import type { FC } from 'react';
import React from 'react';
import styles from './style.scss';

const cx = classNames.bind(styles);

interface Props {
  children: React.ReactNode;
  header?: React.ReactNode;
  desktop?: boolean;
}

/**
 * @deprecated - use @minna-technologies/minna-ui/components/MultiDeviceLayout instead
 */
export const MultiDeviceLayout: FC<Props> = ({ header, children, desktop }) => {
  return (
    <div className={cx('page', { desktop: desktop })}>
      {header}
      <div className={cx('content', { desktop: desktop })}>{children}</div>
    </div>
  );
};
