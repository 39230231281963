import verboseMinnaLogo from '@cancellation-portal/assets/images/logo.svg';
import minnaTechWhiteLogo from '@cancellation-portal/assets/images/minna-tech-logo-white.svg';
import minnaTechLogo from '@cancellation-portal/assets/images/minna-tech-logo.svg';
import minnaLogoWhite from '@cancellation-portal/images/logotypes/mt-logo-horizontal-white.svg';
import type { AppContext } from '../modules/app-context';
import { AppType, Locale, Market } from '../modules/app-context/constants';

// cancellation-portal only
export const getLogo = ({ market }: Pick<AppContext, 'market'>): string => {
  const minnaLogo = verboseMinnaLogo;
  return market === Market.Sweden ? minnaLogo : minnaTechLogo;
};

export const getLogoAltText = ({
  appType,
  locale,
  market,
}: Pick<AppContext, 'appType' | 'locale' | 'market'>): string => {
  const englishName = 'Minna Tech';
  const swedishName = 'Minatjänster.se';

  if (locale === Locale.enGB) {
    return englishName;
  }

  switch (appType) {
    case AppType.Standalone:
    case AppType.DanskeBank:
    case AppType.Swedbank:
      return swedishName;
    case AppType.CancellationPortal:
      return market === Market.Sweden ? swedishName : englishName;

    default:
      return englishName;
  }
};

export const getWhiteLogo = (appType: AppType, market?: Market): string => {
  switch (appType) {
    case AppType.Standalone:
    case AppType.DanskeBank:
    case AppType.Swedbank:
      return minnaLogoWhite;
    case AppType.CancellationPortal:
      return market === Market.Sweden ? minnaLogoWhite : minnaTechWhiteLogo;

    default:
      return minnaTechWhiteLogo;
  }
};
