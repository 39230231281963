import isEqual from 'lodash/isEqual';
import last from 'lodash/last';
import {
  CHAT_ROUTE,
  HELP_CENTER_ROUTE,
  POP_ROUTE,
  PUSH_ROUTE,
  RESET_NAVIGATION_ON_CLOSE,
  SAVE_CALL_BACK_REQUEST_DATA,
  SET_DIALOG_OPEN_STATE,
} from './zendesk-action-types';

const DEFAULT_ALL = 'default-all';
export const CHAT_ONLY = 'chat-only';

export const CHAT_OPEN = 'chat-open';
export const CHAT_MINIMIZED = 'chat-minimized';
export const CHAT_CLOSED = 'chat-closed';

interface IState {
  dialogOpenState: string;
  articles?: any;
  navigation: any[];
  featureMode: string;
}

export const defaultState: IState = {
  dialogOpenState: CHAT_CLOSED,
  navigation: [{ route: HELP_CENTER_ROUTE }],
  featureMode: DEFAULT_ALL,
};

export function reducer(state: IState = defaultState, action: any) {
  switch (action.type) {
    case PUSH_ROUTE: {
      const pushingSameRoute = isEqual(last(state.navigation), action.route);
      if (pushingSameRoute) {
        return state;
      }
      const navigation = [...state.navigation];
      navigation.push(action.route);

      return {
        ...state,
        navigation,
      };
    }
    case POP_ROUTE: {
      const navigation = [...state.navigation];
      navigation.pop();
      if (navigation.length === 0) {
        navigation.push({ route: HELP_CENTER_ROUTE });
      }

      return {
        ...state,
        navigation,
      };
    }
    case SET_DIALOG_OPEN_STATE: {
      return {
        ...state,
        dialogOpenState: action.state,
        articles: {
          ...state.articles,
          userSearchQuery: undefined,
        },
      };
    }
    case RESET_NAVIGATION_ON_CLOSE: {
      const currentRoute = last(state.navigation);
      let navigation;
      if (action.keepChatRoute && currentRoute.route === CHAT_ROUTE) {
        navigation = [{ route: CHAT_ROUTE }];
      } else {
        navigation = defaultState.navigation;
      }

      return {
        ...state,
        navigation,
      };
    }
    case SAVE_CALL_BACK_REQUEST_DATA: {
      return {
        ...state,
        callBackRequestData: action.requestData,
      };
    }
    default:
      return state;
  }
}

export const selectorZendeskCallBackRequestData = (state: any) => state.zendesk.callBackRequestData;
export const selectorZendeskNavigationState = (state: any) => state.zendesk.navigation;
