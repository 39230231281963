import PropTypes from 'prop-types';
import React from 'react';

const VerticalSpacing = ({ sizeWithUnit }: any) => {
  const verticalSpacingStyle = {
    width: '100%',
    minHeight: sizeWithUnit,
    maxHeight: sizeWithUnit,
  };

  return <div style={verticalSpacingStyle} />;
};

VerticalSpacing.propTypes = {
  sizeWithUnit: PropTypes.string.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default VerticalSpacing;
