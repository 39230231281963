import { LoaDocument } from '@cancellation-portal/components/LoaDocument/component';
import { CancellationSummary } from '@cancellation-portal/components/MerchantCancellationPage/components/CancellationSummary/component';
import { MerchantQuestion } from '@cancellation-portal/components/MerchantCancellationPage/components/MerchantQuestion/component';
import type { SupplierInputForm } from '@cancellation-portal/ducks/termination';
import { LocalizedMessage, withLocalization } from '@cancellation-portal/internationalization';
import type { SupplierTerminationDecision } from '@cancellation-portal/models/SupplierTerminationDecision';
import type { SupplierTerminationOverview } from '@cancellation-portal/models/SupplierTerminationOverview';
import type { AppContext } from '@cancellation-portal/modules/app-context';
import { loaHasSigning, Market } from '@cancellation-portal/modules/app-context/constants';
import { PrintIcon } from '@cancellation-portal/modules/shared-components/icons/PrintIcon';
import { MultiDeviceLayout } from '@cancellation-portal/shared-components/layout/MultiDeviceLayout';
import grid from '@cancellation-portal/styles/grid.css';
import { getMinnaTechonoligesLegalName, platformNameToBankLegalName } from '@cancellation-portal/utils';
import { parseDate } from '@cancellation-portal/utils/date';
import { useMountEffect } from '@cancellation-portal/utils/hooks/use-mount-effect';
import { getLogoAltText, getWhiteLogo } from '@cancellation-portal/utils/logo';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import useTheme from '@material-ui/core/styles/useTheme';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { HelpIcon } from '@minna-technologies/minna-ui/icons/Help';
import classNames from 'classnames';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import React from 'react';
import { BankVerified } from '../BankVerified';
import { DocumentStatus } from '../DocumentStatus';
import { ExpandablePaper } from '../ExpandablePaper';
import { LoaStepper } from '../LoaStepper';
import { Parties } from '../Parties';
import { SigningLog } from '../SigningLog';
import styles from './styles.scss';
import { makeStyles } from '@material-ui/core';

const cx = classNames.bind(grid);

interface Props {
  appContext: AppContext;
  verifiedByBank: boolean;
  useAgencyAgreementCopy: boolean;
  overviewInformation: SupplierTerminationOverview;
  downloadSigningProofLink: string;
  supplierInputForm: SupplierInputForm;
  isFormSubmitting: boolean;
  onPrint(): void;
  onDownloadSigningProof(): void;
  onFormChange(property: string, value: any): void;
  onFormSubmit(supplierTerminationDecision: SupplierTerminationDecision): void;
  openZendeskDialog(): void;
}

const useStyles = makeStyles((theme) => ({
  banner: {
    position: 'sticky',
    top: '56px',
    backgroundColor: '#3E223E',
    color: '#fff',
    padding: theme.spacing(2),
    textAlign: 'center',
    left: 0,
    right: 0,
    borderRadius: 0,
    zIndex: theme.zIndex.appBar + 1,
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightLight,
  },
}));

const MerchantCancellationPageInner: FC<Props> = (
  {
    overviewInformation,
    onPrint,
    downloadSigningProofLink,
    onDownloadSigningProof,
    supplierInputForm,
    onFormChange,
    onFormSubmit,
    isFormSubmitting,
    appContext,
    verifiedByBank,
    useAgencyAgreementCopy,
    openZendeskDialog,
  },
  { localizeMessage }
) => {
  const { colors } = useTheme();

  useMountEffect(() => {
    const documentTitleId = useAgencyAgreementCopy ? 'agencyAgreementTitle' : 'loaTitle';
    window.document.title = localizeMessage(documentTitleId, { supplierName: overviewInformation.supplier.name });
    if (overviewInformation.termination.minimumCancellationDate) {
      onFormChange('contractEndsAt', parseDate(overviewInformation.termination.minimumCancellationDate));
    }
  });

  const { termination, supplierDecision } = overviewInformation;
  const decisionTakenBySupplier = !isNil(supplierDecision);
  const terminationFinishedWithoutSupplierDecision = !isNil(termination.finishedAt) && !decisionTakenBySupplier;
  const firstName = get(overviewInformation, 'user.name.first');
  const lastName = get(overviewInformation, 'user.name.last');
  const hasSigning = loaHasSigning(overviewInformation?.loa);

  const loaText = hasSigning
    ? get(overviewInformation, 'loa.text')
    : useAgencyAgreementCopy
    ? localizeMessage('agencyAgreementText3', {
        supplierName: overviewInformation.supplier.name,
        userFullName: `${firstName} ${lastName}`,
        bankLegalName: platformNameToBankLegalName(overviewInformation.termination.marketPlatform?.platform?.name),
        subAgentName: getMinnaTechonoligesLegalName(appContext.market),
      })
    : localizeMessage('loaTextNoSigning', {
        supplierName: overviewInformation.supplier.name,
        userFullName: `${firstName} ${lastName}`,
      });
  const titleId = useAgencyAgreementCopy ? 'agencyAgreementTitle' : 'loaTitle';

  const onHelpClick = () => {
    openZendeskDialog();
  };

  const classes = useStyles();

  const showPersonalNumber = appContext.market === Market.Sweden;
  const showDocumentStatus = (hasSigning && overviewInformation.loa) || useAgencyAgreementCopy || verifiedByBank;

  const header = (
    <>
      <AppBar position="sticky" className={classNames(styles['hidden-print'], styles['app-bar'])}>
        <div className={styles['app-bar-container']}>
          <div>
            <img
              style={{ paddingLeft: '32px', display: 'flex', alignItems: 'center' }}
              src={getWhiteLogo(appContext.appType)}
              alt={getLogoAltText(appContext)}
              className={styles['mt-logo']}
            />
          </div>
          <div>
            <PrimaryButton
              key="help"
              data-test="button-help"
              label={<LocalizedMessage id="help" />}
              onClick={onHelpClick}
              iconPlacement="left"
              icon={<HelpIcon nativeColor={colors.base.surface} />}
              type={'button'}
            />
            <PrimaryButton
              key="print"
              label={<LocalizedMessage id="print" />}
              onClick={onPrint}
              iconPlacement="left"
              icon={<PrintIcon nativeColor={colors.base.surface} />}
              type={'button'}
            />
          </div>
        </div>
      </AppBar>
      <Paper className={classes.banner}>
        <LocalizedMessage
          id="migrationBanner"
          values={{
            url: 'https://minna.tech/hubfs/minna-action-portal-overview.pdf',
          }}
        />
      </Paper>
    </>
  );

  return (
    <MultiDeviceLayout header={header} desktop>
      <div className={cx('row')}>
        <div className={cx('col-xs-12')}>
          <div style={{ padding: '14px' }}>
            <LoaStepper
              finished={decisionTakenBySupplier || terminationFinishedWithoutSupplierDecision}
              marketHasSigning={hasSigning}
            />
          </div>
        </div>

        <div className={cx('col-md-8', 'col-xs-12')}>
          {terminationFinishedWithoutSupplierDecision ? null : (
            <Paper className={styles['paper-left']}>
              {decisionTakenBySupplier ? (
                <CancellationSummary
                  supplierDecision={supplierDecision}
                  firstName={firstName}
                  market={overviewInformation.termination.marketPlatform.market}
                />
              ) : (
                <MerchantQuestion
                  firstName={firstName}
                  lastName={lastName}
                  supplierInputForm={supplierInputForm}
                  onFormChange={onFormChange}
                  onFormSubmit={onFormSubmit}
                  isFormSubmitting={isFormSubmitting}
                  market={appContext.market}
                  termination={termination}
                  useAgencyAgreementCopy={useAgencyAgreementCopy}
                  platformName={overviewInformation.termination.marketPlatform?.platform?.name}
                />
              )}
            </Paper>
          )}
          <Paper className={styles['paper-left']}>
            <LoaDocument
              appContext={appContext}
              text={loaText}
              user={overviewInformation.user}
              desiredTerminationAt={overviewInformation.termination.desiredTerminationAt}
              terminationQuestionResults={overviewInformation.termination.terminationQuestionResults}
              terminatingServiceName={overviewInformation.termination.terminatingServiceName}
              supplierName={overviewInformation.supplier.name}
              titleId={titleId}
            />
          </Paper>
        </div>

        <div className={cx('col-md-4', 'col-xs-12')}>
          {showDocumentStatus && (
            <Paper
              className={classNames(
                styles['paper-outer-margin'],
                styles['paper-inner-spacing-sides'],
                styles['paper-inner-spacing-ends']
              )}
            >
              {(verifiedByBank && <BankVerified />) || (
                <DocumentStatus
                  // @ts-ignore
                  userSignedLoaAt={overviewInformation?.loa?.signedAt}
                  supplierLanguage={overviewInformation.supplier.language}
                />
              )}
            </Paper>
          )}

          <Parties
            overviewInformation={overviewInformation}
            showPersonalNumber={showPersonalNumber}
            verifiedByBank={verifiedByBank}
            useAgencyAgreementCopy={useAgencyAgreementCopy}
          />

          {!verifiedByBank && hasSigning && overviewInformation.loa && (
            <ExpandablePaper
              header={<LocalizedMessage id="signingLog" />}
              className={styles['paper-outer-margin']}
              contentClassName={styles['paper-inner-spacing-sides']}
              initiallyOpen
            >
              <SigningLog
                loa={overviewInformation.loa}
                user={overviewInformation.user}
                signingProofDownloadLink={downloadSigningProofLink}
                onDownloadSigningProof={onDownloadSigningProof}
                showPersonalNumber={showPersonalNumber}
              />
            </ExpandablePaper>
          )}
        </div>
      </div>
    </MultiDeviceLayout>
  );
};

export const MerchantCancellationPage = withLocalization(
  ({ useAgencyAgreementCopy }) =>
    (useAgencyAgreementCopy && 'cancellations/cancellation-portal/cancellation-portal-agency-agreement') ||
    'CancellationPortal'
)(MerchantCancellationPageInner);

MerchantCancellationPageInner.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};
