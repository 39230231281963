//@ts-ignore
import { isValidEmail } from '@cancellation-portal/utils/email/email-validation-utils';
import isString from 'lodash/isString';

export const VisitorInfo = (userName?: any, email?: any) => {
  const defaultName = 'Anonymous';

  if (!isString(userName) || !isString(email)) {
    return { display_name: defaultName, email: '' };
  }

  const trimmedUsername = userName.trim();
  const trimmedEmail = email.trim();

  return {
    display_name: !trimmedUsername ? defaultName : trimmedUsername,
    email: isValidEmail(trimmedEmail) ? trimmedEmail : '',
  };
};
