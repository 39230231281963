import { defaultFeatures } from '@cancellation-portal/feature-toggling';
import { InternationalizationActions } from '@cancellation-portal/internationalization/duck';
import { LocalizationProvider } from '@cancellation-portal/internationalization/LocalizationProvider';
import { allMarkets, AppType, Locale, Market } from '@cancellation-portal/modules/app-context/constants';
import { AppContextActions } from '@cancellation-portal/modules/app-context/duck';
import { getDefaultLocale } from '@cancellation-portal/modules/app-context/state';
import { setGlobalStore } from '@cancellation-portal/modules/global-store';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { minnaTheme } from '@minna-technologies/minna-ui/styles/themes/minna';
import type { History } from 'history';
import type { FC } from 'react';
import React from 'react';
import { Provider } from 'react-redux';
import URI from 'urijs';
import { ZendeskDialog } from './modules/Zendesk/ZendeskDialog';
import { CancellationPortalRoute } from './route';
import { store } from './store';

const displayNameSweden = 'Mina Tjänster';
const displayNameDefault = 'Minna Technologies';

setGlobalStore(store);

// Tha main reason that market information still needed is for localization market-specific messages,
// so this getting information if possible from URL.
const currentMarket: Market = detectCancellationPortalMarket();

// We still can't use here user locale (with getUserBrowserSupportedLocale) instead of market locale,
// because localization has limited support for locales.
const defaultLocale = getDefaultLocale(currentMarket);

store.dispatch(InternationalizationActions.fetchMessagesAndLocaleSupplierPortal(defaultLocale, currentMarket));

store.dispatch(
  AppContextActions.initialize({
    appType: AppType.CancellationPortal,
    displayName: defaultLocale === Locale.svSE ? displayNameSweden : displayNameDefault,
    locale: defaultLocale,
    features: defaultFeatures,
    market: currentMarket,
  })
);

interface Props {
  history: History;
}

const CancellationPortal: FC<Props> = ({ history }) => (
  <Provider store={store}>
    <MuiThemeProvider theme={minnaTheme}>
      <LocalizationProvider>
        <div id="cancellation-portal-page">
          <CancellationPortalRoute history={history} />
          <ZendeskDialog />
        </div>
      </LocalizationProvider>
    </MuiThemeProvider>
  </Provider>
);

export const CancellationPortalApp = CancellationPortal;

/**
 * Searches for market in URI path.
 * If it isn't present in URI then returns fallback market
 * from window.market (for migration purposes).
 */
function detectCancellationPortalMarket(): Market {
  const marketNameInUrl = new URI(window.location).segmentCoded()[0];
  return allMarkets.find((market) => market.toLowerCase() === marketNameInUrl.trim()) || Market.Sweden; //TODO fix default
}
