import { LocalizedMessage } from '@cancellation-portal/internationalization';
import { RejectReason } from '@cancellation-portal/models/SupplierTerminationDecision';
import { AssignmentLateIcon } from '@cancellation-portal/modules/shared-components/icons/AssignmentLateIcon';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import useTheme from '@material-ui/core/styles/useTheme';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import classNames from 'classnames';
import classNamesBind from 'classnames/bind';
import type { FC } from 'react';
import React from 'react';
import grid from '../../styles/grid.css';
import styles from './styles.scss';

const cx = classNamesBind.bind(grid);

const declineReasons = (firstName: string) => [
  {
    // binding time
    text: <LocalizedMessage id="hasBindingPeriod" />,
    value: RejectReason.HasBindingPeriod,
  },
  {
    // no account
    text: <LocalizedMessage id="userHasNoSubscription" values={{ firstName: firstName }} />,
    value: RejectReason.NoAccount,
  },
  {
    // other reason
    text: <LocalizedMessage id="otherReason" />,
    value: RejectReason.OtherReason,
  },
];

interface Props {
  firstName: string;
  selectedReason?: string;
  showErrorChooseDeclineReason: boolean;
  onFormChange(key: string, value: string): void;
  setErrorChooseDeclineReasonVisibility(visible: boolean): void;
}

export const CannotCancelReason: FC<Props> = ({
  firstName,
  selectedReason,
  showErrorChooseDeclineReason,
  onFormChange,
  setErrorChooseDeclineReasonVisibility,
}) => {
  const reasonKey = 'declinedReason';
  const { colors } = useTheme();
  const reasonRadioButtons = declineReasons(firstName).map((question) => {
    return (
      <FormControlLabel
        key={question.value}
        value={question.value}
        control={<Radio />}
        label={question.text}
        data-test={`decline-reason-${question.value}`}
      />
    );
  });

  return (
    <div className={classNames(cx('row'), styles['margin-top-16'])}>
      <AssignmentLateIcon nativeColor={colors.textOn.surfaceSubdued} className={cx('col-xs-1')} />
      <Title color={colors.textOn.surfaceSubdued} className={classNames(cx('col-xs-4'), styles['form-label'])}>
        <LocalizedMessage id="reasonForRejection" />
      </Title>
      <div className={cx('col-xs-7')}>
        {showErrorChooseDeclineReason ? <ErrorMessageRadioButtonNotSelected /> : null}
        <RadioGroup
          name={reasonKey}
          className={styles['radio-button-group']}
          onChange={(_, value) => {
            setErrorChooseDeclineReasonVisibility(false);
            onFormChange(reasonKey, value);
          }}
          value={selectedReason}
        >
          {reasonRadioButtons}
        </RadioGroup>
      </div>
    </div>
  );
};

const ErrorMessageRadioButtonNotSelected = () => {
  const { colors } = useTheme();

  return (
    <Caption color={colors.action.danger}>
      <LocalizedMessage id="requiredToChooseOption" />
    </Caption>
  );
};
