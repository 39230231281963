import sentry from '@cancellation-portal/assets/js/sentry';
import { InternationalizationActions } from '@cancellation-portal/internationalization/duck';
import type { LocalDate } from '@cancellation-portal/models/LocalDate';
import type {
  DecisionType,
  RejectReason,
  SupplierTerminationDecision,
} from '@cancellation-portal/models/SupplierTerminationDecision';
import { PaymentsDecision } from '@cancellation-portal/models/SupplierTerminationDecision';
import type { SupplierTerminationOverview } from '@cancellation-portal/models/SupplierTerminationOverview';
import { AppContextActions } from '@cancellation-portal/modules/app-context/duck';
import { getMixpanel } from '@cancellation-portal/tracking/mixpanel';
import type { AxiosError } from 'axios';
import localIpUrl from 'local-ip-url';
import get from 'lodash/get';
import has from 'lodash/has';
import moment from 'moment';
import * as queryString from 'querystring';
import type { Dispatch } from 'redux';
import type { GetState } from 'redux-pack';
import type { ErrorStatusTypes } from '../components/MerchantCancellationErrorPage';
import * as SupplierTerminationErrorPage from '../components/MerchantCancellationErrorPage';
import {
  finishedCancellationInCancellationPortalMixpanelEvent,
  viewedCancellationPageMixpanelEvent,
} from '../mixpanel-events';
import { getOverview, postSupplierTerminationDecision } from '../models/cancellation';
import { languageToLocale } from '../utils';

export const NAMESPACE = 'SUPPLIER_TERMINATION';
const OVERVIEW_LOADING = `${NAMESPACE}/OVERVIEW_LOADING`;
const OVERVIEW_LOADED = `${NAMESPACE}/OVERVIEW_LOADED`;
const OVERVIEW_FAILED_LOADING = `${NAMESPACE}/OVERVIEW_FAILED_LOADING`;
const UPDATE_SUPPLIER_INPUT_FORM_PROPERTY = `${NAMESPACE}/UPDATE_SUPPLIER_INPUT_FORM_PROPERTY`;
const FORM_SUBMITTING = `${NAMESPACE}/FORM_SUBMITTING`;
const FORM_SUBMITTED = `${NAMESPACE}/FORM_SUBMITTED`;
const FORM_SUBMIT_FAILED = `${NAMESPACE}/FORM_SUBMIT_FAILED`;

// @ts-ignore this is a workaround for an invalid type declaration in package local-ip-url, see https://github.com/jaywcjlove/local-ip-url/issues/5
const localIpUrlWorkaround: (name?: 'public' | 'private', family?: 'ipv4' | 'ipv6') => string = localIpUrl;

interface State {
  termination: TerminationState;
}

export interface SupplierInputForm {
  bindingPeriodEndsAt?: LocalDate;
  contractEndsAt?: LocalDate;
  declinedReason?: RejectReason;
  lastPaymentAt?: LocalDate;
  messageToUser: string;
  payment: PaymentsDecision;
  type?: DecisionType;
}

export interface TerminationState {
  errorStatus?: ErrorStatusTypes;
  formSubmitting: boolean;
  loading?: boolean;
  loadingFailed: boolean;
  namespace: string;
  overview?: SupplierTerminationOverview;
  supplierAuthToken?: string;
  supplierInputForm: SupplierInputForm;
}

const initialState: TerminationState = {
  formSubmitting: false,
  loadingFailed: false,
  supplierInputForm: {
    messageToUser: '',
    payment: PaymentsDecision.NoPayments,
  },
  namespace: NAMESPACE,
};

export function reducer(state = initialState, action: any) {
  switch (action.type) {
    case OVERVIEW_LOADING:
      return {
        ...state,
        loading: true,
        supplierAuthToken: action.supplierAuthToken,
        overview: null,
      };

    case OVERVIEW_FAILED_LOADING: {
      return { ...state, loading: false, loadingFailed: true, errorStatus: errorToErrorStatus(action.error) };
    }

    case OVERVIEW_LOADED:
      return { ...state, overview: action.overview, loading: false };

    case UPDATE_SUPPLIER_INPUT_FORM_PROPERTY: {
      const { property, value } = action;

      return {
        ...state,
        supplierInputForm: { ...state.supplierInputForm, [property]: value },
      };
    }

    case FORM_SUBMITTING:
      return { ...state, formSubmitting: true };

    case FORM_SUBMITTED:
      return {
        ...state,
        formSubmitting: false,
        overview: action.overview,
      };

    case FORM_SUBMIT_FAILED:
      return { ...state, formSubmitting: false, errorStatus: errorToErrorStatus(action.error) };

    default:
      return state;
  }
}

export const selectorIsLoading = (state: State): boolean => state.termination.loading ?? false;
export const selectorOverview = (state: State): SupplierTerminationOverview | undefined => state.termination.overview;
export const selectorLoadingFailed = (state: State): boolean => state.termination.loadingFailed;
export const selectorErrorStatus = (state: State): ErrorStatusTypes | undefined => state.termination.errorStatus;
export const selectorSupplierAuthToken = (state: State): string | undefined => state.termination.supplierAuthToken;
export const selectorSupplierInputForm = (state: State): SupplierInputForm => state.termination.supplierInputForm;
export const selectorFormSubmitting = (state: State): boolean => state.termination.formSubmitting;
export const selectorSupplierName = (state: State): string | undefined => state.termination.overview?.supplier.name;

export function overviewLoading(supplierAuthToken: string) {
  return { type: OVERVIEW_LOADING, supplierAuthToken };
}

export function overviewLoaded(overview: SupplierTerminationOverview) {
  return { type: OVERVIEW_LOADED, overview };
}

export function overviewFailedLoading(error: AxiosError) {
  return { type: OVERVIEW_FAILED_LOADING, error };
}

export function onFormChange(property: string, value: string) {
  return { type: UPDATE_SUPPLIER_INPUT_FORM_PROPERTY, property, value };
}

export function formSubmitting(supplierTerminationDecision: SupplierTerminationDecision) {
  return { type: FORM_SUBMITTING, form: supplierTerminationDecision };
}

export function formSubmitted(overview: SupplierTerminationOverview) {
  return { type: FORM_SUBMITTED, overview };
}

export function formSubmitFailed(error: AxiosError) {
  return { type: FORM_SUBMIT_FAILED, error };
}

export function fetchOverview(supplierAuthToken: string, currentLocale: string) {
  return (dispatch: Dispatch) => {
    dispatch(overviewLoading(supplierAuthToken));

    getOverview(supplierAuthToken)
      .then((overview) => {
        const supplierLocale = languageToLocale(overview.supplier.language);

        if (supplierLocale !== currentLocale) {
          dispatch(AppContextActions.setLocale(supplierLocale));
          moment.locale(supplierLocale);

          const market = overview.termination.marketPlatform.market;

          dispatch(InternationalizationActions.fetchMessagesAndLocaleSupplierPortal(supplierLocale, market));
        } else {
          dispatch(overviewLoaded(overview));

          const query = queryString.parse(window.location.search.slice(1));
          const token = query.token;
          const supplierName = get(overview, 'supplier.name');
          const platform = get(overview, 'termination.marketPlatform.platform.name');
          viewedCancellationPageMixpanelEvent(supplierName, token, localIpUrlWorkaround('public'), platform);
        }
      })
      .catch((error: AxiosError) => {
        dispatch(overviewFailedLoading(error));

        sentry.captureException(error, {
          extra: {
            supplierAuthToken,
          },
        });

        getMixpanel().track('Failed to load termination page', {
          'Error status': error.response?.status ?? SupplierTerminationErrorPage.NetworkError,
        });
      });
  };
}

export const onFormSubmit = (token: string) => (supplierTerminationDecision: SupplierTerminationDecision) => {
  return (dispatch: Dispatch, getState: GetState<State>): void => {
    if (!selectorFormSubmitting(getState())) {
      dispatch(formSubmitting(supplierTerminationDecision));

      postSupplierTerminationDecision(token, supplierTerminationDecision)
        .then((overview) => {
          dispatch(formSubmitted(overview));
          const platform = get(overview, 'termination.marketPlatform.platform.name');
          finishedCancellationInCancellationPortalMixpanelEvent(
            overview,
            supplierTerminationDecision,
            token,
            localIpUrlWorkaround('public'),
            platform
          );
        })
        .catch((error: AxiosError) => {
          dispatch(formSubmitFailed(error));
          sentry.captureException(error, { extra: { token } });
          getMixpanel().track('Failed to submit supplier termination result', {
            'Error status': error.response?.status ?? SupplierTerminationErrorPage.NetworkError,
          });
        });
    }
  };
};

function errorToErrorStatus(error: AxiosError) {
  const errorStatus = error.response?.status ?? -1;

  if (errorStatus === 403) {
    return SupplierTerminationErrorPage.TerminationNotAvailable;
  } else if (errorStatus === 401) {
    return SupplierTerminationErrorPage.Unauthorized;
  } else if (!has(error, 'response')) {
    return SupplierTerminationErrorPage.NetworkError;
  } else {
    return SupplierTerminationErrorPage.UnknownError;
  }
}
