import { Market } from '@cancellation-portal/modules/app-context/constants';
import random from 'lodash/random';
import type { FC, ReactNode } from 'react';
import React from 'react';
import styles from './styles.scss';

export interface PreventTextCopyingProps {
  market: Market;
  children: React.ReactNode | React.ReactNode[];
}

export const PreventTextCopying: FC<PreventTextCopyingProps> = ({ market, children }) => {
  if (isCopyProtectionEnabled(market)) {
    // Preventing copying data using CSS user-select
    return <div className={styles['no-user-select']}> {children} </div>;
  } else {
    return <> {children} </>;
  }
};

export const PrivateText: FC<PrivateTextProps> = ({ market, text }) => {
  const randomChars = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let charSpanIndexGenerator = 0;

  // Generating spans for each letter and additional invisible random spans
  // for private texts to make copying harder from inspecting elements in browsers
  function generateCharSpan(ch: string) {
    const countOfRandomSpans = random(2);

    return [
      <span key={charSpanIndexGenerator++} className={styles['no-user-select']}>
        {ch}
      </span>,
    ].concat(
      Array.from({ length: countOfRandomSpans }, () => (
        <span key={charSpanIndexGenerator++} className={styles['invisible-span']}>
          {randomChars.charAt(random(randomChars.length))}
        </span>
      ))
    );
  }

  if (isCopyProtectionEnabled(market) && text && text.length > 0) {
    // Preventing copying data using CSS user-select as well
    return <>{[...text].map(generateCharSpan)}</>;
  } else {
    return <> {text} </>;
  }
};

export interface PrivateTextProps {
  market: Market;
  text: string;
}

export interface PreventTextCopyingIframeProps {
  market: Market;
  width: string;
  height: string;
  srcDoc: string;
}

export class PreventTextCopyingIframe extends React.Component<PreventTextCopyingIframeProps> {
  private rootContainer: HTMLElement | null = null;

  public componentDidMount() {
    const rootElement = this.rootContainer;
    if (rootElement) {
      const iframeEl = document.createElement('iframe');
      iframeEl.setAttribute('sandbox', 'allow-same-origin');
      iframeEl.setAttribute('height', this.props.height);
      iframeEl.setAttribute('width', this.props.width);
      iframeEl.setAttribute('srcDoc', this.props.srcDoc);
      iframeEl.addEventListener('load', () => {
        const contentDocument = iframeEl.contentDocument;
        const embeddedHtml = contentDocument?.querySelector('body');
        if (embeddedHtml) {
          const existingStyles = embeddedHtml.getAttribute('style');
          embeddedHtml.setAttribute(
            'style',
            existingStyles ? `user-select: none; ${existingStyles}` : 'user-select: none;'
          );
          const editableElements = embeddedHtml.querySelectorAll('[contenteditable=true]');
          editableElements.forEach((editableEl) => {
            editableEl.setAttribute('contenteditable', 'false');
          });
        }
      });

      rootElement.appendChild(iframeEl);
    }
  }

  public render(): ReactNode {
    const { market, width, height, srcDoc } = this.props;

    if (isCopyProtectionEnabled(market)) {
      return <div ref={(ctx) => (this.rootContainer = ctx)} />;
    } else {
      return <iframe srcDoc={srcDoc} width={width} height={height} sandbox="" />;
    }
  }
}

// This is a temporary requirement that we prevent copying on the HTML level private data only in particular markets
function isCopyProtectionEnabled(market: Market) {
  // A hard-coded market for now until it is implemented in a bigger story (BE + FE) / admin migrates to feature-toggles
  return market === Market.UnitedKingdom;
}
