import { LocalizedMessage } from '@cancellation-portal/internationalization';
import { getSupportCode } from '@cancellation-portal/models/support';
import { selectorAppContext } from '@cancellation-portal/modules/app-context/duck';
import { AppIntegration } from '@cancellation-portal/modules/app-integration';
import styles from '@cancellation-portal/modules/Zendesk/ZendeskHelpCenter/components/CallUs/styles.scss';
import { ZendeskSubHeader } from '@cancellation-portal/modules/Zendesk/ZendeskSubHeader';
import { useMountEffect } from '@cancellation-portal/utils/hooks/use-mount-effect';
import {
  getMarketSpecificSupportHours,
  getRawSupportNumber,
  getSupportNumber,
} from '@cancellation-portal/utils/support';
import Paper from '@material-ui/core/Paper';
import useTheme from '@material-ui/core/styles/useTheme';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import type { FC } from 'react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const forceMarginTop = 'force-margin-top';

export const ZendeskCallUs: FC = () => {
  const { colors } = useTheme();
  const appContext = useSelector(selectorAppContext);

  const [code, setCode] = useState<string>('');

  useMountEffect(() => {
    getSupportCode().then((res: any) => setCode(res.code));
  });

  const marketSpecificSupportHours = getMarketSpecificSupportHours(appContext.market);
  const supportHoursLocalizedMessage = (
    <LocalizedMessage
      id="contactUsSectionOpenHours"
      values={{
        mondayOpeningHour: marketSpecificSupportHours.mondayOpeningHour,
        lunchBreakStartingHour: marketSpecificSupportHours.lunchBreakStartingHour,
        lunchBreakEndingHour: marketSpecificSupportHours.lunchBreakEndingHour,
        openingHour: marketSpecificSupportHours.openingHour,
        closingHour: marketSpecificSupportHours.closingHour,
      }}
    />
  );

  const openLink = (event: any): void => {
    const url = `tel://${getRawSupportNumber(appContext.market)}`;
    AppIntegration.openExternalWebPage(url, event);
  };

  return (
    <Paper className={styles.paper}>
      <div className={styles.container}>
        <ZendeskSubHeader title={<LocalizedMessage id="callUsTitle" />} />
        <Caption style={{ color: colors.textOn.surfaceSubdued }} className={styles[forceMarginTop]}>
          <LocalizedMessage id="contactUsSectionCallUsOpenHoursSubtitleV2" />
        </Caption>
        <Body style={{ color: colors.textOn.surfaceSubdued }}>{supportHoursLocalizedMessage}</Body>

        <Body className={styles[forceMarginTop]}>
          <LocalizedMessage id="callUsDescription" />
        </Body>

        <Caption className={styles[forceMarginTop]} style={{ color: colors.textOn.surfaceSubdued }}>
          <LocalizedMessage id="callUsCodeDescription" />
        </Caption>
        <Body variant="bold" data-test="zendesk-phone-support-code">
          {code}
        </Body>

        <Caption className={styles[forceMarginTop]} style={{ color: colors.textOn.surfaceSubdued }}>
          <LocalizedMessage id="callUsDescriptionV2" />
        </Caption>
        <Body
          variant="bold"
          data-test="zendesk-support-number"
          className={styles['support-number']}
          style={{ color: colors.action.primary }}
          role="link"
          onClick={openLink}
        >
          <LocalizedMessage id="callUsSupportNumber" values={{ supportNumber: getSupportNumber(appContext.market) }} />
        </Body>
      </div>
    </Paper>
  );
};
