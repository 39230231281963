import { formatPersonalNumber } from '@cancellation-portal/assets/js/formatters';
import { LocalizedMessage } from '@cancellation-portal/internationalization';
import type { UserInformation } from '@cancellation-portal/models/UserInformation';
import type { AppContext } from '@cancellation-portal/modules/app-context';
import { hasPersonalIdentifier } from '@cancellation-portal/modules/app-context/constants';
import grid from '@cancellation-portal/styles/grid.css';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import classNames from 'classnames/bind';
import type { FC } from 'react';
import React from 'react';
import { InfoComponent } from '../component';

const cx = classNames.bind(grid);

interface Props {
  user: UserInformation;
  appContext: AppContext;
}

export const UserTerminationInfo: FC<Props> = ({ user, appContext }) => {
  const addressInfo = user.address && (
    <InfoComponent label={<LocalizedMessage id="address" />} className={cx('col-xs-12', 'col-sm-6')}>
      {user.address.careOf ? (
        <span>
          C/O {user.address.careOf}
          <br />
        </span>
      ) : null}
      {user.address.street}
      <br />
      {`${user.address.postalCode} ${user.address.city}`}
    </InfoComponent>
  );
  const emailInfo = user.email && (
    <InfoComponent label={<LocalizedMessage id="emailAddress" />} className={cx('col-xs-12', 'col-sm-6')}>
      {user.email}
    </InfoComponent>
  );
  const marketHasPersonalIdentifier = hasPersonalIdentifier(appContext.market);

  return (
    <div data-test="loa-user-info">
      <Title>
        <LocalizedMessage id="userInformationTitle" />
      </Title>
      <div className={cx('row')}>
        <InfoComponent label={<LocalizedMessage id="name" />} className={cx('col-xs-12', 'col-sm-6')}>
          {`${user.name.first} ${user.name.last}`}
        </InfoComponent>
        {marketHasPersonalIdentifier && (
          <InfoComponent label={<LocalizedMessage id="personalNumber" />} className={cx('col-xs-12', 'col-sm-6')}>
            {formatPersonalNumber(user.personalNumber)}
          </InfoComponent>
        )}
        {addressInfo}
        {emailInfo}
      </div>
    </div>
  );
};
