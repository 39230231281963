import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import {
  ARTICLES_FILTERED_BY_CATEGORY,
  ARTICLES_FILTERED_BY_LABELS,
  ARTICLES_FILTERED_BY_SECTION,
  ARTICLES_FILTERED_BY_USER_SEARCH,
} from './zendesk-help-center-actions';
import {
  articlesByCategoryEndpoint,
  articlesByLabelsEndpoint,
  articlesByQueryEndpoint,
  articlesBySectionEndpoint,
} from './zendesk-help-center-api-endpoint';

const mapDataFromAxiosResponse = ({ data }: any) => {
  if (data.articles && !data.results) {
    data.results = data.articles;
  }

  return data;
};

export const fetchZendeskArticlesPromise = async (zendeskBaseUrl: string, locale: any, filter: any) => {
  if (filter.filterType === ARTICLES_FILTERED_BY_LABELS && !isEmpty(filter.labels)) {
    return axios.get(articlesByLabelsEndpoint(zendeskBaseUrl, locale, filter.labels)).then(mapDataFromAxiosResponse);
  } else if (filter.filterType === ARTICLES_FILTERED_BY_USER_SEARCH && filter.userSearchQuery) {
    return axios
      .get(articlesByQueryEndpoint(zendeskBaseUrl, locale, filter.userSearchQuery))
      .then(mapDataFromAxiosResponse);
  } else if (filter.filterType === ARTICLES_FILTERED_BY_CATEGORY && filter.categoryId) {
    return axios
      .get(articlesByCategoryEndpoint(zendeskBaseUrl, locale, filter.categoryId))
      .then(mapDataFromAxiosResponse);
  } else if (filter.filterType === ARTICLES_FILTERED_BY_SECTION && filter.sectionId) {
    return axios
      .get(articlesBySectionEndpoint(zendeskBaseUrl, locale, filter.sectionId))
      .then(mapDataFromAxiosResponse);
  } else {
    return Promise.resolve({ articles: [] });
  }
};
