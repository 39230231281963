import type { History } from 'history';
import type { FC } from 'react';
import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { MerchantCancellationErrorPage } from './components/MerchantCancellationErrorPage';
import { MerchantCancellationPageContainer } from './MerchantCancellation';

interface Props {
  history: History;
}

export const CancellationPortalRoute: FC<Props> = ({ history }) => {
  return (
    <Router history={history}>
      <Switch>
        <Route path={'/:market'} exact render={() => <MerchantCancellationPageContainer />} />
        {/* Legacy route without market in URL for migration */}
        <Route path={'/'} exact render={() => <MerchantCancellationPageContainer />} />
        <Route render={() => <MerchantCancellationErrorPage />} />
      </Switch>
    </Router>
  );
};
