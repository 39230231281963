import type { Market } from '@cancellation-portal/modules/app-context/constants';

export enum PlatformName {
  Undefined = 'Undefined',
  OpBank = 'OpBank',
  Swedbank = 'Swedbank',
  Ing = 'Ing',
  DanskeBank = 'DanskeBank',
  Sb1 = 'Sb1',
  Lloyds = 'Lloyds',
  Anyfin = 'Anyfin',
  Zlantar = 'Zlantar',
  Steven = 'Steven',
  CapitalOne = 'CapitalOne',
  LittleBirdie = 'LittleBirdie',
  Minna = 'Minna',
}

export interface Platform {
  name: PlatformName;
  branch?: string;
}

export interface MarketPlatform {
  market: Market;
  platform: Platform;
}
