import type { WithLocalizationContextType } from '@cancellation-portal/internationalization';
import { LocalizedMessage } from '@cancellation-portal/internationalization';
import { selectorAppContext } from '@cancellation-portal/modules/app-context/duck';
import { LoadingPage } from '@cancellation-portal/modules/fetching/LoadingPage';
import type { Route, ZendeskCategorySection } from '@cancellation-portal/modules/Zendesk/models';
import { ZendeskContactUs } from '@cancellation-portal/modules/Zendesk/ZendeskHelpCenter/components/ContactUs';
import { DisplayArticle } from '@cancellation-portal/modules/Zendesk/ZendeskHelpCenter/components/DisplayArticle';
import { DisplayCategory } from '@cancellation-portal/modules/Zendesk/ZendeskHelpCenter/components/DisplayCategory';
import { DisplaySection } from '@cancellation-portal/modules/Zendesk/ZendeskHelpCenter/components/DisplaySection';
import { ZendeskEmailUs } from '@cancellation-portal/modules/Zendesk/ZendeskHelpCenter/components/EmailUs';
import { StartScreen } from '@cancellation-portal/modules/Zendesk/ZendeskHelpCenter/components/StartScreen';
import { UserSearchResult } from '@cancellation-portal/modules/Zendesk/ZendeskHelpCenter/components/UserSearchResult';
import { getSupportNumber } from '@cancellation-portal/utils/support';
import { Paper } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { SearchBar } from '@minna-technologies/minna-ui/components/Inputs/SearchBar';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import find from 'lodash/find';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ARTICLE_DETAILS_ROUTE,
  CATEGORY_ROUTE,
  CONTACT_US_ROUTE,
  EMAIL_US_ROUTE,
  HELP_CENTER_ROUTE,
  navigateToUserSearchResult,
  SECTION_ROUTE,
  USER_SEARCH_RESULT_ROUTE,
} from '../ducks';
import {
  filterArticlesByQuery,
  selectorArticlesUserSearchQuery,
  selectorCategories,
  selectorCommunicationWithZendeskApiFailed,
  selectorLoadingCategories,
  updateArticlesUserSearchQuery,
} from '../ducks/zendesk-help-center';

const useStyles = makeStyles((theme) => ({
  visibleComponentContainer: {
    padding: theme.spacing(2, 1),
  },
  padding: {
    padding: theme.spacing(2),
  },
}));

interface Props {
  currentRoute: Route;
}

export const ZendeskHelpCenter: FC<Props> = ({ currentRoute }, { localizeMessage }: WithLocalizationContextType) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const appContext = useSelector(selectorAppContext);
  const categories = useSelector(selectorCategories);
  const userSearchQuery = useSelector(selectorArticlesUserSearchQuery);
  const loadingCategories = useSelector(selectorLoadingCategories);
  const communicationWithApiFailed = useSelector(selectorCommunicationWithZendeskApiFailed);

  const handleUpdateUserSearchQuery = (userSearchQuery: string) => {
    dispatch(updateArticlesUserSearchQuery(userSearchQuery));
  };

  const handleSearchArticles = () => {
    const trimmedQuery = userSearchQuery && userSearchQuery.trim();
    dispatch(filterArticlesByQuery(appContext.features.helpCenter.zendeskBaseUrl, appContext.locale, trimmedQuery));
    dispatch(navigateToUserSearchResult());
  };

  const visibleComponent = () => {
    const getSectionFromRoute = (route: Route): ZendeskCategorySection | undefined => {
      const category = find(categories, ['id', route.parameters.categoryId]);

      return find(get(category, 'sections'), ['id', route.parameters.sectionId]);
    };

    switch (currentRoute.route) {
      case HELP_CENTER_ROUTE:
        return <StartScreen />;
      case CATEGORY_ROUTE:
        // @ts-ignore
        return <DisplayCategory category={find(categories, ['id', currentRoute.parameters.categoryId])} />;
      case SECTION_ROUTE:
        // @ts-ignore
        return <DisplaySection section={getSectionFromRoute(currentRoute)} />;
      case USER_SEARCH_RESULT_ROUTE:
        return <UserSearchResult />;
      case ARTICLE_DETAILS_ROUTE:
        return (
          <DisplayArticle articleId={currentRoute.parameters.articleId} sectionId={currentRoute.parameters.sectionId} />
        );
      case CONTACT_US_ROUTE:
        return <ZendeskContactUs />;
      case EMAIL_US_ROUTE:
        return <ZendeskEmailUs />;
      default:
        return null;
    }
  };

  const handleSearchBarInput = (event: React.ChangeEvent<HTMLInputElement>) =>
    handleUpdateUserSearchQuery(event.target.value);

  return loadingCategories ? (
    <LoadingPage />
  ) : (
    <div>
      {communicationWithApiFailed ? (
        <Paper className={classes.padding}>
          <Body data-test="zendesk-error-screen">
            <LocalizedMessage
              id="unableToConnectToHelpCenterErrorV2"
              values={{ supportNumber: getSupportNumber(appContext.market) }}
            />
          </Body>
        </Paper>
      ) : (
        <div className={classes.visibleComponentContainer}>
          <SearchBar
            fullWidth
            data-test="zendesk-search"
            onClick={handleSearchArticles}
            value={userSearchQuery}
            placeholder={localizeMessage('searchBarGreeting')}
            onChange={handleSearchBarInput}
          />
          {visibleComponent()}
        </div>
      )}
    </div>
  );
};

ZendeskHelpCenter.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};
