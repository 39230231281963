import axios from 'axios';

export const getSupportCode = async () => {
  const response = await axios.get(`/api/user/support-id`);

  return response.data;
};

export const getEmailSupportCode = async (): Promise<EmailSupportId> => {
  const response = await axios.get(`/api/user/email-support-id`);

  return response.data;
};

export interface EmailSupportId {
  createdAt: string;
  code: string;
  type: string;
  userId: string;
}
