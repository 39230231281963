import { Headline2 } from '@minna-technologies/minna-ui/components/Typography/Headline2';
import classNames from 'classnames';
import React from 'react';
import styles from './styles.scss';

interface ZendeskSubHeaderProps {
  title: React.ReactNode;
}

export const ZendeskSubHeader = ({ title }: ZendeskSubHeaderProps) => {
  const titleClassNames = [styles['font-weight']];

  return (
    <div className={styles['flex-row']}>
      <div>
        <Headline2 className={classNames(titleClassNames)}>{title}</Headline2>
      </div>
    </div>
  );
};
