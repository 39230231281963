import merge from 'lodash/merge';
import PropTypes from 'prop-types';
import React from 'react';

export const OnlineOfflineIcon = ({ online, style, className }: any) => {
  const chatStatusColor = online ? 'green' : 'red';
  const mergedStyles = merge(
    {
      display: 'inline-block',
      height: '8px',
      width: '8px',
      borderRadius: '50%',
      backgroundColor: chatStatusColor,
    },
    style
  );

  return <span style={mergedStyles} className={className} />;
};
OnlineOfflineIcon.propTypes = {
  online: PropTypes.bool.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
};
