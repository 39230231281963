import makeStyles from '@material-ui/core/styles/makeStyles';
import isArray from 'lodash/isArray';
import React from 'react';
import { isNotUserJoinedEvent } from './chat-history-utils';
import { ChatEvent } from './ChatEvent';
import { MessageGroup } from './MessageGroup';
import { TypingImage } from './TypingImage';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1, 2),
    background: theme.colors.base.surface,
    fontFamily: 'Roboto, sans-serif',
    overflow: 'scroll',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface ChatHistoryProps {
  partitionedEvents: any[];
  agentIsTyping: boolean;
}

export const ChatHistory = ({ partitionedEvents, agentIsTyping }: ChatHistoryProps) => {
  const classes = useStyles();

  const eventElements = partitionedEvents.filter(isNotUserJoinedEvent).map((event: any, i: any) => {
    if (isArray(event)) {
      return <MessageGroup messages={event} key={i} />;
    }

    return <ChatEvent event={event} key={i} />;
  });

  return (
    <div className={classes.container}>
      {eventElements}
      {agentIsTyping && <TypingImage />}
      <div data-js="the-very-last-element-in-chat-history" />
    </div>
  );
};
