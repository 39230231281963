import { LocalizedMessage } from '@cancellation-portal/internationalization';
import FullscreenContainerView from '@cancellation-portal/shared-components/layout/FullscreenContainerView';
import makeStyles from '@material-ui/core/styles/makeStyles';
import type { FC } from 'react';
import React from 'react';
import { ZendeskHeader } from '../../../ZendeskHeader/component';
import styles from '../../styles.scss';
import { ZendeskMinimized } from '../ZendeskMinimized/container';

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    overflow: 'scroll',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors.base.surface,
  },
}));

interface Props {
  isOpen: boolean;
  onCloseDialog(): void;
  onNavigateBack(): void;
  visibleComponent: React.ReactNode;
}

export const ZendeskMobileDialog: FC<Props> = ({ isOpen, onCloseDialog, onNavigateBack, visibleComponent }) => {
  const classes = useStyles();

  return (
    <div>
      <FullscreenContainerView isOpen={isOpen} name="zendesk-mobile-dialog">
        <div data-test="zendesk-mobile-dialog" className={styles['header-container']}>
          <ZendeskHeader
            onNavigateBack={onNavigateBack}
            title={<LocalizedMessage id="mobileDialogHeaderTitle" />}
            onClose={onCloseDialog}
          />
        </div>
        <div className={classes.contentContainer}>{visibleComponent}</div>
      </FullscreenContainerView>
      <ZendeskMinimized />
    </div>
  );
};
