import { withLocalization } from '@cancellation-portal/internationalization';
import * as chatDuck from '@cancellation-portal/modules/Zendesk/ducks/zendesk-chat';
import { ZendeskChat } from '@cancellation-portal/modules/Zendesk/ZendeskChat';
import { ZendeskDesktopDialog } from '@cancellation-portal/modules/Zendesk/ZendeskDialog/components/ZendeskDesktopDialog';
import { ZendeskMobileDialog } from '@cancellation-portal/modules/Zendesk/ZendeskDialog/components/ZendeskMobileDialog';
import { ZendeskHelpCenter } from '@cancellation-portal/modules/Zendesk/ZendeskHelpCenter';
import { ZendeskCallUs } from '@cancellation-portal/modules/Zendesk/ZendeskHelpCenter/components/CallUs';
import Visible from '@cancellation-portal/shared-components/layout/Visible';
import { useMountEffect } from '@cancellation-portal/utils/hooks/use-mount-effect';
import type { FC } from 'react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CALL_US_ROUTE,
  CHAT_ROUTE,
  close,
  HELP_CENTER_ROUTE,
  navigateBack,
  selectorCurrentRoute,
  selectorDialogIsOpen,
} from '../ducks';
import { endChat } from '../ducks/zendesk-chat';

export const ZendeskDialog: FC = withLocalization('zendesk')(() => {
  const dispatch = useDispatch();

  const isOpen = useSelector(selectorDialogIsOpen);
  const currentRoute = useSelector(selectorCurrentRoute);

  useMountEffect(() => {
    dispatch(chatDuck.initialize());
  });

  const handleCloseDialog = () => {
    const shouldEndChat = currentRoute.route === CHAT_ROUTE;
    if (shouldEndChat) {
      dispatch(endChat());
    }
    dispatch(close());
  };

  const handleNavigateBack = () => {
    dispatch(navigateBack());
  };

  const routeMap: any = {};
  routeMap[HELP_CENTER_ROUTE] = <ZendeskHelpCenter currentRoute={currentRoute} />;
  routeMap[CHAT_ROUTE] = <ZendeskChat />;
  routeMap[CALL_US_ROUTE] = <ZendeskCallUs />;

  const mainRoute = currentRoute && currentRoute.route.split('/')[0];
  const visibleComponent = mainRoute && routeMap[mainRoute];

  return (
    <div>
      <Visible cssQuery="only screen and (max-height: 649px), only screen and (max-width: 639px)">
        <ZendeskMobileDialog
          onNavigateBack={handleNavigateBack}
          isOpen={isOpen}
          onCloseDialog={handleCloseDialog}
          visibleComponent={visibleComponent}
        />
      </Visible>
      <Visible cssQuery="only screen and (min-width: 640px) and (min-height: 650px)">
        <ZendeskDesktopDialog
          isOpen={isOpen}
          onNavigateBack={handleNavigateBack}
          onCloseDialog={handleCloseDialog}
          visibleComponent={visibleComponent}
        />
      </Visible>
    </div>
  );
});
