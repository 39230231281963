import type { History } from 'history';
import type { AppIntegrationImplementation } from './';
import { AppIntegrationType, AppPlatform } from './';

declare global {
  interface Window {
    webkit?: { messageHandlers: any };
    nativeHost?: any;
    onWebViewHostMessage?(message: any): void;
  }
}

/**
 * A default implementation which is only intended to be used as a placeholder.
 */

export function defaultWebBrowserNativeImplementation(history: History): AppIntegrationImplementation {
  return {
    sendAnalyticsEvent(): boolean {
      return false;
    },
    closeApplicationOrGoTo(url: string): void {
      history.push(url);
    },
    closeApplication(): boolean {
      return false;
    },
    getAppIntegrationType(): AppIntegrationType {
      return AppIntegrationType.WEB_BROWSER;
    },
    getAppPlatform(): AppPlatform {
      return AppPlatform.WEB_BROWSER_NATIVE;
    },
    openExternalWebPage(url: string, event?: Event): boolean {
      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }

      window.open(url, '_blank');

      return true;
    },
  };
}
