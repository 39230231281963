import type { Action as PackAction } from 'redux-pack';
import { handle as packHandle } from 'redux-pack';
import { LoadingState } from './LoadingState';

/**
 * `Fetchable<P>` is a useful type for the redux state to not have to store both
 * the current loading status and the value of the thing with type P. It also ensures
 * that you cannot try to read the thing with type P before the api-call has finished.
 * Why not use a Promise? Because a promise is not serializable - meaning it should not be stored in redux.
 */
export type Fetchable<P> =
  | { type: LoadingState.NotStarted }
  | { type: LoadingState.Loading }
  | { type: LoadingState.Success; data: P }
  | { type: LoadingState.Failure; error: Error };

/**
 * Utility functions to reduce boilerplate in your duck.
 * This tries to cover the most common use case of only saving the loaded resource to the redux state.
 * If you want to do anything extra you'll probably have to e.g. write your own handler or axios call.
 */

/**
 * "Constructors" for Fetchable
 */
export const FetchableNotStarted: Fetchable<never> = { type: LoadingState.NotStarted };
export const FetchableLoading: Fetchable<never> = { type: LoadingState.Loading };
export const FetchableSuccess: <P>(data: P) => Fetchable<P> = (data) => ({ type: LoadingState.Success, data });
export const FetchableFailure: (error: Error) => Fetchable<never> = (error) => ({ type: LoadingState.Failure, error });

/**
 * A handler (used to handle an action in the reducer) that just calls the callback.
 * The callback is used to create the new state. The typical callback looks like this:
 * (l) => ({...state, fetchableResource: l})
 */
// I can't figure out a way around using object here. Record<string, unknown> is too narrow,
// since it requires an index type on the object used, and adding that to all the types that
// use fetchableHandle seems worse
// eslint-disable-next-line @typescript-eslint/ban-types
export function fetchableHandle<S extends object, P>(
  reduxState: S,
  action: PackAction<Record<string, unknown>, P, Error>,
  callback: (fetchable: Fetchable<P>) => S
): S {
  return packHandle(reduxState, action, {
    start: () => callback(FetchableLoading),
    failure: (_, a) => callback(FetchableFailure(a.payload)),
    success: (_, a) => callback(FetchableSuccess(a.payload)),
  });
}

export function extractSuccess<P>(item: Fetchable<P>): P | null;
export function extractSuccess<P, Default>(item: Fetchable<P>, defaultValue: Default): P | Default;

export function extractSuccess<P, Default>(
  item: Fetchable<P>,
  defaultValue: Default | null = null
): P | Default | null {
  if (item.type === LoadingState.Success) {
    return item.data;
  } else {
    return defaultValue;
  }
}

export function fetchableIsLoading(item: Fetchable<any>): boolean {
  return item.type === LoadingState.Loading;
}

export function mapSuccess<A, B>(item: Fetchable<A>, f: (a: A) => B): Fetchable<B> {
  if (item.type === LoadingState.Success) {
    return FetchableSuccess(f(item.data));
  } else {
    return item;
  }
}
