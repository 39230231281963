import type { LocalizeMessage } from '@cancellation-portal/internationalization';
import { LocalizedMessage } from '@cancellation-portal/internationalization';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import { FloatingButton } from '@minna-technologies/minna-ui/components/Buttons/FloatingButton';
import { Form } from '@minna-technologies/minna-ui/components/Form';
import { useMinnaForm } from '@minna-technologies/minna-ui/components/Form/use-form';
import { TextArea } from '@minna-technologies/minna-ui/components/Inputs/TextArea';
import { ArrowForwardIcon } from '@minna-technologies/minna-ui/icons/ArrowForward';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import React from 'react';
import { ChatHistory } from './ChatHistory';

const useStyles = makeStyles((theme) => ({
  chatContainer: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    backgroundColor: theme.colors.base.surface,
  },
  closeChatButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  closeChatButton: {
    marginTop: theme.spacing(1),
    width: 'fit-content',
    padding: '6px 32px',
    borderRadius: '48px',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(1),
    backgroundColor: theme.colors.base.surface,
  },
  inputText: {
    fontSize: '12px',
    margin: theme.spacing(1),
  },
  inputSubmitButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRadius: '50%',
    backgroundColor: theme.colors.action.primary,
  },
}));

interface MessagingProps {
  isAgentAvailable: boolean;
  partitionedEvents: any[];
  messageForm: MessageFormProps;
  onMinimizeChat: () => void;
  onMessageFormChange: (key: string, value: string) => void;
  sendChatMsg: (value: string) => Promise<any>;
  agentIsTyping: boolean;
}

interface FormValues {
  message: string;
}

export const Messaging = ({
  isAgentAvailable,
  partitionedEvents,
  messageForm,
  agentIsTyping,
  onMinimizeChat,
  onMessageFormChange,
  sendChatMsg,
}: MessagingProps) => {
  const classes = useStyles();

  return (
    <div className={classes.chatContainer}>
      <div className={classes.closeChatButtonContainer}>
        <FloatingButton variant="inverse" className={classes.closeChatButton} onClick={onMinimizeChat}>
          <LocalizedMessage id="chatBarFloatingButton" />
        </FloatingButton>
      </div>
      <ChatHistory partitionedEvents={partitionedEvents} agentIsTyping={agentIsTyping} />
      <MessageForm
        message={messageForm.message}
        sendChatMsg={sendChatMsg}
        disabled={!isAgentAvailable}
        onMessageChange={(value) => onMessageFormChange('message', value)}
      />
    </div>
  );
};

export interface MessageFormProps {
  message: string;
  onMessageChange: (value: string) => void;
  sendChatMsg: (value: string) => Promise<any>;
  disabled: boolean;
}

const MessageForm: FC<MessageFormProps> = (
  { message, sendChatMsg, onMessageChange, disabled },
  { localizeMessage }: { localizeMessage: LocalizeMessage }
) => {
  const classes = useStyles();
  const { colors } = useTheme();
  const methods = useMinnaForm<FormValues>();

  const onValidSubmit = async () => {
    const messageTrimmed = message.trim();
    if (messageTrimmed) {
      onMessageChange('');
      await sendChatMsg(message);
    }
  };

  return (
    <Form onSubmit={async () => onValidSubmit()} formMethods={methods}>
      <div className={classes.inputContainer}>
        <TextArea
          //@ts-ignore
          autoComplete={false}
          value={message}
          onChange={({ target: { value } }) => onMessageChange(value)}
          name="message"
          disabled={disabled}
          className={classes.inputText}
          label={localizeMessage('chatTextAreaLabel')}
          rows={3}
          fullWidth
          placeholder={localizeMessage('chatFormMessageInputPlaceHolder')}
        />
        <div className={classes.inputSubmitButton}>
          <IconButton type="submit" disabled={disabled}>
            <ArrowForwardIcon nativeColor={colors.base.surface} />
          </IconButton>
        </div>
      </div>
    </Form>
  );
};

//@ts-ignore
MessageForm.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};
