import { LocalizedMessage } from '@cancellation-portal/internationalization';
import StickyContainerView from '@cancellation-portal/shared-components/layout/StickyContainerView';
import makeStyles from '@material-ui/core/styles/makeStyles';
import type { FC } from 'react';
import React from 'react';
import { ZendeskHeader } from '../../../ZendeskHeader/component';
import { ZendeskMinimized } from '../ZendeskMinimized/container';

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    overflow: 'scroll',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors.base.surface,
  },
  headerContainer: {
    width: '100%',
  },
}));

interface ZendeskDesktopDialogProps {
  isOpen: boolean;
  onNavigateBack(): void;
  onCloseDialog(): void;
  visibleComponent: React.ReactNode;
}

export const ZendeskDesktopDialog: FC<ZendeskDesktopDialogProps> = ({
  isOpen,
  onNavigateBack,
  onCloseDialog,
  visibleComponent,
}: ZendeskDesktopDialogProps) => {
  const classes = useStyles();

  return (
    <div>
      <StickyContainerView isOpen={isOpen} name="zendesk-desktop-dialog">
        <div className={classes.headerContainer}>
          <ZendeskHeader
            title={<LocalizedMessage id="desktopDialogHeaderTitle" />}
            onNavigateBack={onNavigateBack}
            onClose={onCloseDialog}
          />
        </div>
        <div className={classes.contentContainer}>{visibleComponent}</div>
      </StickyContainerView>
      <ZendeskMinimized />
    </div>
  );
};
