import { selectorAppContext } from '@cancellation-portal/modules/app-context/duck';
import { LoadingPage } from '@cancellation-portal/modules/fetching/LoadingPage';
import type { ZendeskCategory } from '@cancellation-portal/modules/Zendesk/models';
import { ContactUsButton } from '@cancellation-portal/modules/Zendesk/ZendeskHelpCenter/components/ContactUsButton';
import { getIngBankContactUrl } from '@cancellation-portal/utils/support';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { CardRowLink } from '@minna-technologies/minna-ui/components/CardRowLink';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import type { FC } from 'react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppType } from '../../../../app-context/constants';
import { navigateToCategory, navigateToContactUs } from '../../../ducks';
import {
  filterArticlesByLabels,
  selectorCategories,
  selectorLabels,
  selectorLoadingArticles,
  selectorLoadingCategories,
} from '../../../ducks/zendesk-help-center';

const useStyles = makeStyles((theme) => ({
  startScreenContainer: {
    marginBottom: theme.spacing(2),
  },
}));

export const StartScreen: FC = () => {
  const dispatch = useDispatch();

  const appContext = useSelector(selectorAppContext);
  const categories = useSelector(selectorCategories);
  const labels = useSelector(selectorLabels);
  const loadingArticles = useSelector(selectorLoadingArticles);
  const loadingCategories = useSelector(selectorLoadingCategories);

  const features = appContext.features;
  // TODO: use feature toggle when adding full support for 'contact your bank' in Zendesk
  const isContactSupportEnabled =
    features.helpCenter &&
    (features.helpCenter.chatEnabled ||
      features.helpCenter.callUsEnabled ||
      features.helpCenter.emailUsEnabled ||
      appContext.appType === AppType.ING);

  const isLoading = loadingArticles || loadingCategories;

  useEffect(() => {
    dispatch(filterArticlesByLabels(appContext.features.helpCenter.zendeskBaseUrl, appContext.locale, labels));
  }, [appContext.features.helpCenter.zendeskBaseUrl, appContext.locale, dispatch, labels]);

  const handleNavigateToContactUs = () => {
    // TODO: use feature toggle when adding full support for 'contact your bank' in Zendesk
    if (appContext.appType === AppType.ING) {
      window.open(getIngBankContactUrl(appContext.locale), '_blank', 'noopener noreferrer external nofollow');
    } else {
      dispatch(navigateToContactUs());
    }
  };

  const handleNavigateToCategory = (categoryId: number) => {
    dispatch(navigateToCategory(categoryId));
  };

  return isLoading ? (
    <LoadingPage />
  ) : (
    <div>
      <BrowseHelpCenter categories={categories} onNavigateToCategory={handleNavigateToCategory} />
      <Divider />
      {isContactSupportEnabled && <ContactUsButton onNavigateToContactUs={handleNavigateToContactUs} />}
    </div>
  );
};

interface BrowseHelpCenterProps {
  categories: ZendeskCategory[];
  onNavigateToCategory(categoryId: number): void;
}

const BrowseHelpCenter: FC<BrowseHelpCenterProps> = ({ categories, onNavigateToCategory }) => {
  const classes = useStyles();
  const categoriesLength = categories.length;

  return (
    <div className={classes.startScreenContainer}>
      <Card variant="border" noPadding data-test="zendesk-help-center-start-screen-div">
        {categories.map((category, index) => (
          <div key={category.id}>
            <CardRowLink onClick={() => onNavigateToCategory(category.id)} body={category.name} />
            {index !== categoriesLength - 1 && <Divider slim />}
          </div>
        ))}
      </Card>
    </div>
  );
};
