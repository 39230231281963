/* eslint-disable import/no-unassigned-import */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { promisify } from '@cancellation-portal/utils/promise';
import debounce from 'lodash/debounce';
import type { Dispatch } from 'redux';
import type { GetState } from 'redux-pack';
//@ts-ignore
import zChat from '../../../../../../../../vendor/web_sdk';
import { close, minimize, navigateToChat } from '../zendesk-actions';
import { selectorDialogIsClosed, selectorDialogIsOpen } from '../zendesk-selectors';
import { HIDE_RECENT_MESSAGE_TIMEOUT, ZENDESK_CHAT_ACCOUNT_KEY } from './zendesk-chat-config';
import {
  ADD_LOCAL_MESSAGE_TO_HISTORY,
  ADD_UNREAD_MESSAGE,
  CLEAR_MESSAGES,
  END_CHAT,
  FORM_CHANGE,
  HIDE_RECENT_MESSAGE,
  MARK_ALL_MESSAGES_AS_READ,
  SERVER_EVENT,
  SHOW_RECENT_MESSAGE,
  START_CHAT,
} from './zendesk-chat-event-types';
import {
  selectorIsChatting,
  selectorShouldInitialize,
  selectorZChatIsDoneInitializing,
} from './zendesk-chat-selectors';
import { logErrors, scrollToBottom } from './zendesk-chat-utils';
import { ZendeskChatInitializeEvent } from './ZendeskChatInitializeEvent';

export { ZendeskChatVisitorIsTypingEvent } from './ZendeskChatVisitorIsTypingEvent';

export const initialize = () => (dispatch: Dispatch, getState: GetState<any>) => {
  if (!selectorShouldInitialize(getState())) {
    return;
  }
  // Initialize zChat client
  zChat.init({
    account_key: ZENDESK_CHAT_ACCOUNT_KEY,
  });
  zendeskChatEvents.forEach((event) => {
    zChat.on(event, (data: any) => {
      serverEventHandler(dispatch, getState, event, data);
      dispatch(
        serverEvent({
          type: event,
          data: data,
        })
      );
    });
  });

  // Send visitor path to Zendesk whenever user navigates anywhere on page
  const pushState = window.history.pushState;
  window.history.pushState = async function () {
    /* eslint-disable prefer-rest-params */
    //@ts-ignore
    pushState.apply(this, arguments);
    if (selectorZChatIsDoneInitializing(getState())) {
      await sendVisitorPath(document.title, document.URL.toString());
    }
  };
  const replaceState = window.history.replaceState;
  window.history.replaceState = async function () {
    //@ts-ignore
    replaceState.apply(this, arguments);
    if (selectorZChatIsDoneInitializing(getState())) {
      await sendVisitorPath(document.title, document.URL.toString());
    }
  };

  dispatch(ZendeskChatInitializeEvent());
};

const handleConnectionUpdate = (data: any) => {
  switch (data) {
    case 'closed': {
      zChat.reconnect();

      return;
    }

    default:
      return;
  }
};

const handleChatEvent = (dispatch: any, getState: GetState<any>, data: any) => {
  scrollToBottom();
  if (!selectorIsChatting(getState())) {
    dispatch(navigateToChat());
    dispatch(startChat());
  } else if (data.type === 'chat.memberleave' && data.nick === 'visitor') {
    dispatch({ type: END_CHAT });
    dispatch({ type: CLEAR_MESSAGES }); // TODO do we want this?
    dispatch(close(false));
  } else if (!selectorDialogIsOpen(getState()) && data.type === 'chat.msg' && data.nick.includes('agent')) {
    if (selectorDialogIsClosed(getState())) {
      // chat is started, but no message has arrived yet, so chat head is not visible
      dispatch(minimize());
    }
    dispatch(addUnreadMessage(data));
    dispatch(showRecentMessage());
  }
};

const serverEventHandler = (dispatch: Dispatch, getState: GetState<any>, type: any, data: any) => {
  switch (type) {
    case 'connection_update':
      handleConnectionUpdate(data);

      return;

    case 'chat':
      handleChatEvent(dispatch, getState, data);

      return;

    case 'error': {
      logErrors(data, 'validation error');

      return;
    }

    default:
      return;
  }
};

const serverEvent = (event: any) => ({ type: SERVER_EVENT, event });

export const firstMessageFormChange = (property: unknown, value: unknown) => ({
  type: FORM_CHANGE,
  form: 'firstMessageForm',
  property,
  value,
});
export const messageFormChange = (property: any, value: any) => ({
  type: FORM_CHANGE,
  form: 'messageForm',
  property,
  value,
});
export const addLocalMessageToHistory = (message: any) => {
  scrollToBottom();

  return {
    type: ADD_LOCAL_MESSAGE_TO_HISTORY,
    timestamp: Number(new Date()),
    message,
  };
};
export const sendVisitorPath = async (title: any, url: any) => {
  const fn = zChat.sendVisitorPath.bind(zChat, { title, url });
  await promisify(fn).catch((err: any) => logErrors(err, 'sendVisitorPath', { title, url }));
};

export const addUnreadMessage = (message: any) => (dispatch: Dispatch) => {
  dispatch({
    type: ADD_UNREAD_MESSAGE,
    message,
  });
};
export const markAllMessagesAsRead = () => ({
  type: MARK_ALL_MESSAGES_AS_READ,
});

// Show/hide of "recent message" in minimized state
const hideRecentMessageDebounced = debounce((dispatch) => {
  dispatch({
    type: HIDE_RECENT_MESSAGE,
  });
}, HIDE_RECENT_MESSAGE_TIMEOUT);

export const showRecentMessage = () => (dispatch: Dispatch) => {
  dispatch({
    type: SHOW_RECENT_MESSAGE,
  });
  hideRecentMessageDebounced(dispatch);
};

export const hideRecentMessage = () => (dispatch: Dispatch) => {
  hideRecentMessageDebounced(dispatch);
  hideRecentMessageDebounced.flush();
};

export const startChat = () => ({ type: START_CHAT });
export const endChat = () => () => {
  promisify(zChat.endChat).catch((err: any) => logErrors(err, 'endChat')); // TODO: show error message in GUI
};

const zendeskChatEvents = [
  'account_status',
  'connection_update',
  'department_update',
  'visitor_update',
  'agent_update',
  'chat',
  'error',
];
