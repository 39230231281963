import { PreventTextCopying } from '@cancellation-portal/components/MerchantCancellationPage/components/PrivateText/component';
import { merchantCancellationDecisions } from '@cancellation-portal/components/MerchantCancellationPage/merchantCancellationDecisions';
import styles from '@cancellation-portal/components/MerchantCancellationPage/styles.scss';
import { LocalizedMessage } from '@cancellation-portal/internationalization';
import type { Market } from '@cancellation-portal/modules/app-context/constants';
import { AssignmentLateIcon } from '@cancellation-portal/modules/shared-components/icons/AssignmentLateIcon';
import { DateIcon } from '@cancellation-portal/modules/shared-components/icons/DateIcon';
import { FlagIcon } from '@cancellation-portal/modules/shared-components/icons/FlagIcon';
import VerticalSpacing from '@cancellation-portal/shared-components/layout/VerticalSpacing';
import { formatDateTime, formatToIsoDate } from '@cancellation-portal/utils/date';
import useTheme from '@material-ui/core/styles/useTheme';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import { Subheading1 } from '@minna-technologies/minna-ui/components/Typography/Subheading1';
import { ChatIcon } from '@minna-technologies/minna-ui/icons/Chat';
import classNames from 'classnames';
import classNamesBind from 'classnames/bind';
import find from 'lodash/find';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import React from 'react';
import grid from '../../../../styles/grid.css';

const cx = classNamesBind.bind(grid);

const declineReasons = (firstName: string) => [
  {
    // binding time
    text: <LocalizedMessage id="hasBindingPeriod" />,
    value: 'HasBindingPeriod',
  },
  {
    // no account
    text: <LocalizedMessage id="userHasNoSubscription" values={{ firstName: firstName }} />,
    value: 'NoAccount',
  },
  {
    // other reason
    text: <LocalizedMessage id="otherReason" />,
    value: 'OtherReason',
  },
];

interface Props {
  firstName: string;
  supplierDecision: any;
  market: Market;
}

export const CancellationSummary: FC<Props> = ({ firstName, supplierDecision, market }) => {
  const { colors } = useTheme();

  const supplierDecisionTypeTaken = find(merchantCancellationDecisions, [
    'value',
    get(supplierDecision, 'decision.type'),
  ]);
  const statusText = (
    <span>
      <LocalizedMessage id="theSubscription" /> {supplierDecisionTypeTaken?.text}
    </span>
  );
  const messageToUser = get(supplierDecision, 'decision.messageToUser');
  const decisionTakenAt = get(supplierDecision, 'decisionTakenAt');
  const isTerminated = !isNil(get(supplierDecision, 'decision.contractEndsAt'));
  const contractEndsAtLabel =
    supplierDecisionTypeTaken === merchantCancellationDecisions.terminationApproved ? (
      <LocalizedMessage id="theSubscriptionWillEndAt" />
    ) : (
      <LocalizedMessage id="theSubscriptionEndedAt" />
    );
  const isDeclined = !isNil(get(supplierDecision, 'decision.reason'));
  const declineReason = find(declineReasons(firstName), ['value', get(supplierDecision, 'decision.reason.name')]);
  const declinedBecauseOfBindingPeriod = isDeclined && get(declineReason, 'value') === 'HasBindingPeriod';

  return (
    <div>
      <Subheading1 color={colors.action.primary}>
        <LocalizedMessage id="terminationHandled" />
      </Subheading1>
      <VerticalSpacing sizeWithUnit="16px" />

      <div className={cx('row')}>
        <FlagIcon nativeColor={colors.textOn.surfaceSubdued} className={cx('col-xs-1')} />
        <Caption color={colors.textOn.surfaceSubdued} className={classNames(cx('col-xs-4'), styles['form-label'])}>
          <LocalizedMessage id="status" />
        </Caption>
        <Body>{statusText}</Body>
      </div>
      {isTerminated ? (
        <div className={cx('row')}>
          <DateIcon nativeColor={colors.textOn.surfaceSubdued} className={cx('col-xs-1')} />
          <Caption color={colors.textOn.surfaceSubdued} className={classNames(cx('col-xs-4'), styles['form-label'])}>
            {contractEndsAtLabel}
          </Caption>
          <Body>{formatToIsoDate(get(supplierDecision, 'decision.contractEndsAt'))}</Body>
        </div>
      ) : null}
      {isDeclined ? (
        <div className={cx('row')}>
          <AssignmentLateIcon nativeColor={colors.textOn.surfaceSubdued} className={cx('col-xs-1')} />
          <Caption color={colors.textOn.surfaceSubdued} className={classNames(cx('col-xs-4'), styles['form-label'])}>
            <LocalizedMessage id="reasonForRejection" />
          </Caption>
          <Body>{get(declineReason, 'text')}</Body>
        </div>
      ) : null}
      {declinedBecauseOfBindingPeriod ? (
        <div className={cx('row')}>
          <DateIcon nativeColor={colors.textOn.surfaceSubdued} className={cx('col-xs-1')} />
          <Caption color={colors.textOn.surfaceSubdued} className={classNames(cx('col-xs-4'), styles['form-label'])}>
            <LocalizedMessage id="subscriptionBindingPeriodUntil" />
          </Caption>
          <Body>{get(supplierDecision, 'decision.reason.bindingPeriodEndsAt')}</Body>
        </div>
      ) : null}
      <div className={cx('row')}>
        <ChatIcon nativeColor={colors.textOn.surfaceSubdued} className={cx('col-xs-1')} />
        <Caption color={colors.textOn.surfaceSubdued} className={classNames(cx('col-xs-4'), styles['form-label'])}>
          <LocalizedMessage id="messageToUser" values={{ firstName: firstName }} />
        </Caption>
        <Body className={styles['user-message']} inline>
          <PreventTextCopying market={market}>{messageToUser}</PreventTextCopying>
        </Body>
      </div>
      <div className={cx('row')}>
        <div className={cx('col-xs-12')}>
          <Body className={styles['flex-row-reverse']}>{decisionTakenAt ? formatDateTime(decisionTakenAt) : null}</Body>
        </div>
      </div>
    </div>
  );
};

CancellationSummary.propTypes = {
  firstName: PropTypes.string.isRequired,
  supplierDecision: PropTypes.object.isRequired,
};
