import PropTypes from 'prop-types';
import React from 'react';
import without from 'lodash/without';
import keys from 'lodash/keys';
import head from 'lodash/head';
import every from 'lodash/every';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
/* eslint-disable-next-line no-restricted-imports */
import _ from 'lodash';
import noop from 'lodash/noop';
import ReactDOM from 'react-dom';

export const validationContextPropType = PropTypes.shape({
  register: PropTypes.func,
  unregister: PropTypes.func,
  addFormSubmittedListener: PropTypes.func,
  removeFormSubmittedListener: PropTypes.func
});

export default class Form extends React.Component {
  static propTypes = {
    onValidSubmit: PropTypes.func,
    onValidSubmitWithEvent: PropTypes.func,
    onInvalidSubmit: PropTypes.func,
    children: PropTypes.node,
    className: PropTypes.string,
    noValidate: PropTypes.bool,
    disabled: PropTypes.bool
  };

  static defaultProps = {
    noValidate: true,
    onValidSubmit: noop,
    onInvalidSubmit: noop
  };

  static childContextTypes = {
    validation: validationContextPropType
  };

  nodes = [];
  formSubmittedListeners = [];

  childContext = {
    validation: {
      register: (node) => {
        this.nodes = [...this.nodes, node];
      },
      unregister: (node) => {
        this.nodes = without(this.nodes, node);
      },
      addFormSubmittedListener: (func) => {
        this.formSubmittedListeners = [...this.formSubmittedListeners, func];
      },
      removeFormSubmittedListener: (func) => {
        this.formSubmittedListeners = without(this.formSubmittedListeners, func);
      }
    }
  };

  getChildContext() {
    return this.childContext;
  }

  onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (this.props.disabled) {
      return;
    }

    this.setFormSubmitted();

    const values = _(this.nodes)
      .keyBy('props.name')
      .mapValues('props.value')
      .value();

    if (this.isValid() && this.props.onValidSubmitWithEvent) {
      this.props.onValidSubmitWithEvent(event, values);
    } else if (this.isValid()) {
      this.props.onValidSubmit(values);
    } else {
      const errors = _(this.nodes)
        .keyBy('props.name')
        .mapValues('state.errors')
        .value();
      this.scrollToFirstError(errors);
      this.props.onInvalidSubmit(values, errors);
    }
  };

  validateAsSubmitted = () => {
    this.setFormSubmitted();

    if (!this.isValid()) {
      const errors = _(this.nodes)
        .keyBy('props.name')
        .mapValues('state.errors')
        .value();
      this.scrollToFirstError(errors);
    }

    return this.isValid();
  };

  scrollToFirstError(errors) {
    const firstInvalidInputName = head(keys(errors));
    const firstInvalidNode = find(this.nodes, {props: {name: firstInvalidInputName}});
    ReactDOM.findDOMNode(firstInvalidNode).scrollIntoView(); // eslint-disable-line react/no-find-dom-node
  }

  isValid = () => {
    return every(this.nodes, 'state.valid');
  };

  setFormSubmitted = () => {
    forEach(this.formSubmittedListeners, func => func(true));
  };

  render() {
    const {noValidate, children, className} = this.props;

    return (
      <form onSubmit={ this.onSubmit } noValidate={ noValidate } className={ className }>
        { children }
      </form>);
  }
}
