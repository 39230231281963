import type { Store } from 'redux';

let globalStore: Store | undefined;

/**
 * This is a hack to keep old non-React code working.
 * Please do not rely on this, just use the store from the context instead.
 * Note that you have no idea of what type the store is. (Is it the standalone store or the swedbank store?) Good luck.
 *
 * Gets the Redux store, even if you are outside React.
 */
export function getGlobalStore(): Store {
  if (globalStore === undefined) {
    throw new Error('The global store was undefined, it should be defined as soon the application is starting.');
  } else {
    return globalStore;
  }
}

/**
 * This is a hack to keep old non-React code working.
 *
 * This must be called exactly ONCE during the initialization of the app.
 * Any other call is undefined behaviour.
 */
export function setGlobalStore(store: Store) {
  if (globalStore && __VERSION__ !== 'storybook') {
    // eslint-disable-next-line no-console
    console.warn(
      'The global store was set multiple times. Expected it to only be set once when the application is starting.',
      {
        old: globalStore,
        new: store,
      }
    );
  }

  globalStore = store;
}
