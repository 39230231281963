import { LocalizedMessage } from '@cancellation-portal/internationalization';
import type { UserInformation } from '@cancellation-portal/models/UserInformation';
import type { AppContext } from '@cancellation-portal/modules/app-context';
import { parseDate } from '@cancellation-portal/utils/date';
import { getLogo, getLogoAltText } from '@cancellation-portal/utils/logo';
import useTheme from '@material-ui/core/styles/useTheme';
import { SecondaryButton } from '@minna-technologies/minna-ui/components/Buttons/SecondaryButton';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import { Modal } from '@minna-technologies/minna-ui/components/Modal';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Subheading1 } from '@minna-technologies/minna-ui/components/Typography/Subheading1';
import { Subheading2 } from '@minna-technologies/minna-ui/components/Typography/Subheading2';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import React from 'react';
import styles from './styles.scss';
import { UserTerminationInfo } from './UserTerminationInfo';

interface LoaDocumentPreviewProps {
  previewDialogLoaOpen: boolean;
  onPreviewLoa(requestPreviewLoa: () => void): void;
  requestPreviewLoa(): void;
  closePreviewDialogOpen(): void;
  appContext: AppContext;
  text: string;
  user: UserInformation;
  desiredTerminationAt: string;
  terminationQuestionResults: any[];
  terminatingServiceName?: string;
  supplierName: string;
}

export const LoaDocumentPreview: FC<LoaDocumentPreviewProps> = ({
  onPreviewLoa,
  previewDialogLoaOpen,
  requestPreviewLoa,
  closePreviewDialogOpen,
  ...props
}) => {
  const onToggleLoa = () => {
    if (!previewDialogLoaOpen) {
      onPreviewLoa(requestPreviewLoa);
    } else {
      closePreviewDialogOpen();
    }
  };

  return (
    <>
      <Modal
        open={previewDialogLoaOpen}
        onClose={onToggleLoa}
        button1={<SecondaryButton label={<LocalizedMessage id="closeLoaDialogButton" />} onClick={onToggleLoa} />}
      >
        <LoaDocument {...props} />
      </Modal>
      <SecondaryButton label={<LocalizedMessage id="displayLoaButton" />} onClick={onToggleLoa} fullWidth />
    </>
  );
};

export interface LoaDocumentProps {
  appContext: AppContext;
  text: string;
  user: UserInformation;
  desiredTerminationAt?: string;
  terminationQuestionResults: any[];
  terminatingServiceName?: string;
  supplierName: string;
  titleId?: string;
}

export const LoaDocument: FC<LoaDocumentProps> = (
  {
    titleId,
    text,
    user,
    desiredTerminationAt,
    terminationQuestionResults,
    terminatingServiceName,
    supplierName,
    appContext,
  },
  { localizeDate }
) => {
  const desiredTerminationAtText = desiredTerminationAt ? (
    localizeDate(parseDate(desiredTerminationAt))
  ) : (
    <LocalizedMessage id="asSoonAsPossible" />
  );
  const { colors } = useTheme();

  return (
    <div>
      <LoaHeader supplierName={supplierName} appContext={appContext} titleId={titleId} />

      <Body color={colors.textOn.surfaceSubdued}>{text}</Body>

      <Divider />

      <UserTerminationInfo user={user} appContext={appContext} />

      <Divider />

      <Subheading1>
        <LocalizedMessage id="otherInformation" />
      </Subheading1>

      <ServiceName terminatingServiceName={terminatingServiceName} />
      <TerminationAnswers terminationQuestionResults={terminationQuestionResults} />
      <InfoComponent label={<LocalizedMessage id="desiredTerminationDate" />}>{desiredTerminationAtText}</InfoComponent>
    </div>
  );
};

LoaDocument.contextTypes = {
  localizeDate: PropTypes.func.isRequired,
};

interface LoaHeaderProps {
  appContext: AppContext;
  supplierName: string;
  titleId?: string;
}

const LoaHeader: FC<LoaHeaderProps> = ({ titleId, supplierName, appContext }) => {
  const logo = getLogo(appContext);
  const logoAltText = getLogoAltText(appContext);
  const titleIdValue = titleId || 'loaTitle';

  return (
    <div className={styles['header-container']}>
      <Subheading2>
        <LocalizedMessage id={titleIdValue} values={{ supplierName: supplierName }} />
      </Subheading2>
      <img className={styles.logo} src={logo} alt={logoAltText} />
    </div>
  );
};

interface TerminationAnswersProps {
  terminationQuestionResults: any[];
}

const TerminationAnswers: FC<TerminationAnswersProps> = ({ terminationQuestionResults }) => {
  const { colors } = useTheme();
  const fields = map(terminationQuestionResults, (questionResult, index) => {
    const value = questionResult.value;

    return (
      <div key={index} style={{ marginBottom: '8px' }}>
        <Body>{questionResult.label}</Body>
        {value ? <Body color={colors.textOn.surfaceSubdued}>{value}</Body> : null}
      </div>
    );
  });

  return <div>{fields}</div>;
};

interface ServiceNameProps {
  terminatingServiceName?: string;
}

const ServiceName: FC<ServiceNameProps> = ({ terminatingServiceName }) => {
  const { colors } = useTheme();
  if (terminatingServiceName) {
    return (
      <div>
        <Body>
          <LocalizedMessage id="service" />
        </Body>
        <Body color={colors.textOn.surfaceSubdued} style={{ marginTop: '0' }}>
          {terminatingServiceName}
        </Body>
      </div>
    );
  } else {
    return null;
  }
};

interface InfoComponentProps {
  label: React.ReactNode | string;
  className?: string;
  children: React.ReactNode;
}

export const InfoComponent: FC<InfoComponentProps> = ({ label, className, children }) => {
  const { colors } = useTheme();

  return (
    <div className={className} style={{ marginBottom: '8px' }}>
      <Body>{label}</Body>
      <Body color={colors.textOn.surfaceSubdued}>{children}</Body>
    </div>
  );
};
