import { Locale } from '@cancellation-portal/modules/app-context/constants';
import { languageCodeFromLocale } from '../../../app-context/constants';

const zendeskApiUrl = (zendeskBaseUrl: string) => `${zendeskBaseUrl}/api/v2/help_center`;

export const articlesByLabelsEndpoint = (zendeskBaseUrl: string, locale: any, labels: any) =>
  `${zendeskApiUrl(zendeskBaseUrl)}/articles/search.json?label_names=${labels.join(
    ','
  )}&locale=${getZendeskLanguageCode(locale)}`;

export const articlesByQueryEndpoint = (zendeskBaseUrl: string, locale: any, userSearchQuery: any) =>
  `${zendeskApiUrl(zendeskBaseUrl)}/articles/search.json?query=${userSearchQuery}&locale=${getZendeskLanguageCode(
    locale
  )}`;

export const articlesByCategoryEndpoint = (zendeskBaseUrl: string, locale: any, categoryId: any) =>
  `${zendeskApiUrl(zendeskBaseUrl)}/${getZendeskLanguageCode(locale)}/categories/${categoryId}/articles`;

export const articlesBySectionEndpoint = (zendeskBaseUrl: string, locale: any, sectionId: any) =>
  `${zendeskApiUrl(zendeskBaseUrl)}/${getZendeskLanguageCode(locale)}/sections/${sectionId}/articles`;

export const zendeskCategoriesEndpoint = (zendeskBaseUrl: string, locale: any) =>
  `${zendeskApiUrl(zendeskBaseUrl)}/${getZendeskLanguageCode(locale)}/categories.json`;

export const zendeskSectionsEndpoint = (zendeskBaseUrl: string, locale: any, categoryId: any) =>
  `${zendeskApiUrl(zendeskBaseUrl)}/${getZendeskLanguageCode(locale)}/categories/${categoryId}/sections.json`;

export const zendeskArticleEndpoint = (zendeskBaseUrl: string, locale: any, articleId: any) =>
  `${zendeskApiUrl(zendeskBaseUrl)}/${getZendeskLanguageCode(locale)}/articles/${articleId}.json`;

/*
  Going forward, we will specific language as lowercase locale. Language code will still be used for Swedish, Danish, Norwegian and Finnish so that UR do not need to migrate already existing articles.
  If updated, update the copy in /integration-tests/cypress/models/index.ts
*/
function getZendeskLanguageCode(locale: any) {
  switch (locale) {
    case Locale.svSE:
    case Locale.daDK:
    case Locale.nbNO:
    case Locale.fiFI:
      return languageCodeFromLocale(locale);
    default:
      return locale.toLowerCase();
  }
}
