import { LocalizedMessage } from '@cancellation-portal/internationalization';
import { DecisionType, PaymentsDecision } from '@cancellation-portal/models/SupplierTerminationDecision';
import React from 'react';

export const merchantCancellationDecisions = {
  terminationApproved: { value: DecisionType.TerminationApproved, text: <LocalizedMessage id="hasBeenTerminated" /> },
  terminatedWithPendingPayments: {
    value: DecisionType.TerminatedWithPendingPayments,
    text: <LocalizedMessage id="hasBeenTerminatedWithUpcomingPayments" />,
  },
  alreadyTerminated: { value: DecisionType.AlreadyTerminated, text: <LocalizedMessage id="wasAlreadyTerminated" /> },
  terminationDeclined: {
    value: DecisionType.TerminationDeclined,
    text: <LocalizedMessage id="couldNotBeTerminated" />,
  },
};

export const paymentsDecisions = {
  noPayments: { value: PaymentsDecision.NoPayments, text: <LocalizedMessage id="noPaymentsRemaining" /> },
  yesPayments: { value: PaymentsDecision.YesPayments, text: <LocalizedMessage id="yesPaymentsRemaining" /> },
};
