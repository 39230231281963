import { getSigningIcon } from '@cancellation-portal/components/AuthenticationMethod/utils';
import { selectorOverview } from '@cancellation-portal/ducks/termination';
import { LocalizedMessage, useLocalization } from '@cancellation-portal/internationalization';
import { getSigningName } from '@cancellation-portal/utils';
import { makeStyles } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import type { FC } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  link: {
    color: theme.colors.textOn.primary,
    textDecoration: 'none',
    fontWeight: 'normal',
  },
  bankId: {
    height: '60px',
    width: '60px',
    marginBottom: '4px',
    alignSelf: 'center',
  },
  nemId: {
    height: '30px',
    padding: '15px 0',
    marginBottom: '4px',
    alignSelf: 'center',
  },
}));

export const AuthenticationMethod: FC<AuthenticationMethodProps> = ({ supplierLanguage }) => {
  const { colors } = useTheme();
  const localization = useLocalization('CancellationPortal');
  const overview = useSelector(selectorOverview);
  const classes = useStyles();
  const signing = getSigningIcon(classes, overview?.loa?.signee?.type);
  const signingName = getSigningName(localization.localizeMessage)(overview?.loa?.signee?.type);

  return (
    (signing && (
      <div className={classes.container}>
        <img src={signing.icon} alt={signingName} className={signing.styling} />
        <Body>
          <LocalizedMessage id="authenticationMethod" />
        </Body>
        <Body color={colors.textOn.surfaceSubdued} data-test="auth-method">
          {signingName}
        </Body>
        {supplierLanguage === 'English' ? (
          <Caption color={colors.textOn.surfaceSubdued}>
            (
            <a
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
              // nosemgrep
              href={signing.infoLink}
            >
              <LocalizedMessage id="whatIsAuthenticationMethod" values={{ name: signingName }} />
            </a>
            )
          </Caption>
        ) : null}
      </div>
    )) || <></>
  );
};

interface AuthenticationMethodProps {
  supplierLanguage: string;
}
