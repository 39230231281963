import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import has from 'lodash/has';
import includes from 'lodash/includes';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import repeat from 'lodash/repeat';
import replace from 'lodash/replace';
import set from 'lodash/set';
import size from 'lodash/size';
import toString from 'lodash/toString';
import moment from 'moment';

/**
 * Takes the state, or any other object that needs to be masked, and depending on the setting
 * supplied to the function (shouldKeepKeysIntact) will either mask the keys with asterisks or
 * keep them intact and replace all other values with asterisks.
 * If no shouldKeepKeysIntact value is supplied, all fields will be masked.
 *
 * @param state - Object to be masked.
 * @param pathForMasking - string representation of object path for masking.
 * @param keys - string or array of values excepted from masking.
 * @param shouldKeepKeysIntact -
 * true = they keys supplied to this function will remain the same as they currently are.
 * false = the keys supplied to this function will be replaced with asterisks.
 * @returns masked representation of the object
 */
export const stateToMaskedState = (state: any, pathForMasking: string, keys: any, shouldKeepKeysIntact: boolean) => {
  // eslint-disable-next-line no-shadow
  const maskValues = (objectForMasking: any, keys: any, shouldKeepKeysIntact: boolean, replaceRegexp: RegExp): any =>
    mapValues(objectForMasking, (value, key) => {
      const keyExists = includes(keys, key);
      if (keyExists === shouldKeepKeysIntact) {
        return value;
      } else if (isArray(value)) {
        return map(value, (val) => maskValues(val, keys, shouldKeepKeysIntact, replaceRegexp));
      } else if (isObject(value)) {
        if (keyExists) {
          // If we matched a key, we want all values under that key to be masked
          // => force keys to be empty and forcing shouldKeepKeysIntact to be true.
          return maskValues(value, undefined, true, replaceRegexp);
        }

        return maskValues(value, keys, shouldKeepKeysIntact, replaceRegexp);
      }
      const sizeOfString = size(toString(value));
      const repeatNumber = sizeOfString > 5 ? 5 : sizeOfString;

      return replace(value, replaceRegexp, repeat('*', repeatNumber));
    });

  const useRootObject = pathForMasking === undefined || pathForMasking === '';
  const stateCopy = cloneDeep(state);
  const objectForMasking = useRootObject ? stateCopy : get(stateCopy, pathForMasking);
  if (objectForMasking) {
    const replaceRegexp = new RegExp('.*');
    const updatedMaskedObject = maskValues(objectForMasking, keys, shouldKeepKeysIntact, replaceRegexp);

    return useRootObject ? updatedMaskedObject : set(stateCopy, pathForMasking, updatedMaskedObject);
  } else {
    return stateCopy;
  }
};

/**
 * Takes the state, or any object that needs to be trimmed, and omits the content specified by the path
 */
const omitFromState = (state: any, pathForOmission: any) => {
  if (has(state, pathForOmission)) {
    const copiedState = cloneDeep(state);

    return set(copiedState, pathForOmission, 'Omitted for space reasons');
  } else {
    return state;
  }
};

const omitInArray = (state: any, pathForArray: any, pathForOmission: any) => {
  if (has(state, pathForArray)) {
    const copiedState = cloneDeep(state);
    const array = get(copiedState, pathForArray);

    const res = isArray(array) ? map(array, (val) => omitFromState(val, pathForOmission)) : array;

    return set(copiedState, pathForArray, res);
  } else {
    return state;
  }
};

/**
 * Takes the the entire state and cleans the paths that is known to contain sensitive data or huge objects.
 * NOTE:
 * Make sure to add your real example of the state that needs to be masked to the json files used for the cleanState test
 */
export const cleanState = (state: any) => {
  const shouldKeepKeysIntact = true;
  const shouldOnlyMaskMentionedKeys = false;

  const formattedMomentJsDates = mapValuesDeep(
    state,
    (value: any) => {
      if (moment.isMoment(value)) {
        // Sometimes MomentJS date objects are stored in the state, they take lot's of space and is not human readable.
        return value.format();
      } else {
        return value;
      }
    },
    10
  );

  // Mask everything under user.data
  const cleanUserState = stateToMaskedState(formattedMomentJsDates, 'user.data', 'id', shouldKeepKeysIntact);
  // Only mask login.personalNumber
  const halfCleanedLoginState = stateToMaskedState(
    cleanUserState,
    'login',
    ['personalNumber', 'email'],
    shouldOnlyMaskMentionedKeys
  );
  // Mask everything under login.status.user
  const cleanedLoginState = stateToMaskedState(
    halfCleanedLoginState,
    'login.status',
    ['id', 'finished', 'type'],
    shouldKeepKeysIntact
  );
  // Mask everything under zendeskChat.firstMessageForm and zendeskChat.visitorData
  const cleanedZendeskState = stateToMaskedState(
    cleanedLoginState,
    'zendeskChat',
    ['firstMessageForm', 'visitorData'],
    shouldOnlyMaskMentionedKeys
  );
  // Mask everything under termination.terminationForm
  const cleanedTerminationState = stateToMaskedState(
    cleanedZendeskState,
    'termination.terminationForm',
    '',
    shouldKeepKeysIntact
  );
  // Mask everything under bankDiscoverySynchronisation.status
  const cleanedBankDiscoverySynchronizationState = stateToMaskedState(
    cleanedTerminationState,
    'bankDiscoverySynchronization.status',
    '',
    shouldKeepKeysIntact
  );

  // Delete all the messages since they can be huge
  const removedMessages = omitFromState(cleanedBankDiscoverySynchronizationState, 'internationalization.messages.data');
  // Delete everything under howToCancel.suppliers
  const removedHowToCancel = omitFromState(removedMessages, 'howToCancel.suppliers');
  // Delete manualAddSuppliers suppliers
  const removedManualAddSupplier = omitFromState(removedHowToCancel, 'manualAddSupplier.suppliers');
  // Delete supplier statistics (in admin)
  const removedSupplierStatistics = omitFromState(removedManualAddSupplier, 'supplier.supplierStatistics');
  // Delete mobile guide suppliers and products
  const removedMobileGuideSuppliers = omitFromState(removedSupplierStatistics, 'mobileGuide.suppliersWithProducts');
  // Delete electricity guide suppliers and products
  const removedElectricityGuideProducts = omitFromState(removedMobileGuideSuppliers, 'electricityGuide.products');
  const removedElectricityGuideProviderLogos = omitFromState(
    removedElectricityGuideProducts,
    'electricityGuide.providerLogos'
  );

  // Delete answers to termination questions on a subscription
  const removedTerminationFormData = omitFromState(
    removedElectricityGuideProviderLogos,
    'subscription.subscription.contracts'
  );
  // Delete all form data related to cancellations
  const removedCancellationTerminationForm = omitFromState(removedTerminationFormData, 'cancellation.terminationForm');
  // Delete all answers to cancellation questions on the cancellation object
  const removedCancellationTerminationAnswers = omitFromState(
    removedCancellationTerminationForm,
    'cancellation.terminationAnswers'
  );

  // Delete message from insights cancellation outcome
  const removedInsightCancellationOutcomeMessages = omitInArray(
    removedCancellationTerminationAnswers,
    'insights.insights.data',
    'content.message'
  );
  // Delete termination question results
  const removedTerminationQuestionResults = omitFromState(
    removedInsightCancellationOutcomeMessages,
    'termination.overview.termination.terminationQuestionResults'
  );
  // Delete termination supplier email address
  const removeTerminationSupplierEmail = omitFromState(
    removedTerminationQuestionResults,
    'termination.overview.supplier.emailAddresses'
  );

  //Delete message to user from subscription.contracts
  const removedMessageInSubscriptionContract = omitInArray(
    removeTerminationSupplierEmail,
    'overview.subscriptions',
    'contracts'
  );

  //Delete loa text
  return omitFromState(removedMessageInSubscriptionContract, 'termination.overview.loa.text');
};

function mapValuesDeep(value: any, mapper: any, maxIterations: any): any {
  if (maxIterations <= 0) {
    return mapper(value);
  }
  if (isArray(value)) {
    return map(value, (innerValue) => mapValuesDeep(mapper(innerValue), mapper, maxIterations - 1));
  } else if (isObject(value)) {
    return mapValues(value, (innerValue) => mapValuesDeep(mapper(innerValue), mapper, maxIterations - 1));
  } else {
    return mapper(value);
  }
}
