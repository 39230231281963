//@ts-ignore
import Sentry from '@cancellation-portal/assets/js/sentry';
import { isValidEmail } from '@cancellation-portal/utils/email';
//@ts-ignore
import { promisify } from '@cancellation-portal/utils/promise';
//@ts-ignore
import zChat from '../../../../../../../../vendor/web_sdk';
import { VisitorInfo } from './VisitorInfo';
import { INITIALIZE } from './zendesk-chat-event-types';

const initZChat = async (userName: any, email: any, userId: any, locale: any) => {
  if (!userName || !email || !isValidEmail(email)) {
    return zChat;
  }

  // Set initial visitor info to zendesk if exists
  const visitorInfo = VisitorInfo(userName, email);
  const setVisitorInfo = zChat.setVisitorInfo.bind(zChat, visitorInfo);
  await promisify(setVisitorInfo).catch(); // Ignore initial error logging to sentry

  // Set user locale
  if (locale) {
    // As all tags are converted to lower case in Zendesk we need to send locale in lower case otherwise it will not be matched with skill tag
    const addSkillTag = zChat.addTag.bind(zChat, locale.toLowerCase());
    await promisify(addSkillTag).catch((error: any) =>
      Sentry.captureExceptionWithMessage(error, 'Failed to tag locale on Zendesk chat for user', {
        extra: { userId: userId, locale: locale },
      })
    );
  } else {
    Sentry.captureMessage('Locale is missing in Zendesk chat', { extra: { userId: userId, locale: locale } });
  }

  // Set userId tag
  if (userId) {
    const addTag = zChat.addTag.bind(zChat, `User id: ${userId}`);
    await promisify(addTag).catch();
  }
};

export const ZendeskChatInitializeEvent = (userName?: any, email?: any, userId?: any, locale?: any) => {
  const initializePromise = initZChat(userName, email, userId, locale);

  return { type: INITIALIZE, promise: initializePromise };
};
