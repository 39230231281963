import { useLocalization } from '@cancellation-portal/internationalization';
import { getEmailSupportCode } from '@cancellation-portal/models/support';
import { selectorAppContext } from '@cancellation-portal/modules/app-context/duck';
import { ZendeskSubHeader } from '@cancellation-portal/modules/Zendesk/ZendeskSubHeader';
import { ExternalLink } from '@cancellation-portal/shared-components/ExternalLink';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Snackbar } from '@minna-technologies/minna-ui/components/Snackbar';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import { Headline2 } from '@minna-technologies/minna-ui/components/Typography/Headline2';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  emailUsCard: {
    padding: theme.spacing(2, 0, 1),
  },
  contactUsSectionEmailUsDescriptionV2: {
    marginTop: theme.spacing(2),
  },
  emailUsDescription: {
    padding: theme.spacing(3, 0),
  },
  emailUsCodeDescription: {
    margin: theme.spacing(4, 0, 1),
  },
  emailUsCode: {
    borderWidth: '1.5px',
    borderStyle: 'solid',
    borderColor: theme.colors.base.border,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(3),
    textAlign: 'center',
    width: '100%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  clickToCopy: {
    marginTop: theme.spacing(1),
    float: 'right',

    '&:hover': {
      cursor: 'pointer',
    },
  },
  container: {
    padding: theme.spacing(2),
  },
  hidden: {
    opacity: 0,
  },
}));

export const ZendeskEmailUs: FC = () => {
  const { localizeMessage } = useLocalization('zendesk');
  const appContext = useSelector(selectorAppContext);

  const [code, setCode] = useState('');
  const [copiedSuccessfully, setCopiedSuccessfully] = useState(false);

  const resetCopiedState = () => {
    setCopiedSuccessfully(false);
  };

  const copyOnClick = () => {
    /**
     * Input element with id "hiddenInput" is added for this purpose only
     */
    const inputField = document.getElementById('hiddenInput') as HTMLInputElement;
    if (inputField) {
      navigator.clipboard.writeText(inputField.value).then(() => {
        setCopiedSuccessfully(true);
      });
    }
  };

  useEffect(() => {
    const fetchCode = async () => {
      const res = await getEmailSupportCode();
      setCode(res.code);
    };
    fetchCode();
  }, []);

  const classes = useStyles();
  const { colors } = useTheme();

  const { supportEmail } = appContext.features.helpCenter;

  return (
    <Card className={classes.emailUsCard} variant="border">
      <div className={classes.container} data-test="zendesk-email-us-div">
        <ZendeskSubHeader title={localizeMessage('emailUsTitle')} />
        <Body color={colors.textOn.surfaceSubdued} className={classes.contactUsSectionEmailUsDescriptionV2}>
          {localizeMessage('contactUsSectionEmailUsDescriptionV2')}
        </Body>

        <Body color={colors.textOn.surfaceSubdued} className={classes.emailUsDescription}>
          {localizeMessage('emailUsDescription')}
        </Body>

        <Body color={colors.textOn.surfaceSubdued}>{localizeMessage('emailUsDescriptionV2')}</Body>
        <ExternalLink
          href={`mailto:${supportEmail}?subject=${localizeMessage(
            'emailUsEmailSubject'
          )} ${code}&body=${localizeMessage('emailUsEmailSubject')} ${code}`}
        >
          <Body data-test="zendesk-support-email" color={colors.action.primary} variant="bold">
            {localizeMessage('emailUsSupportEmail', { supportEmail: supportEmail })}
          </Body>
        </ExternalLink>

        <Body variant="bold" className={classes.emailUsCodeDescription}>
          {localizeMessage('emailUsCodeDescription')}
        </Body>
        {code !== '' ? (
          <>
            <Headline2 data-test="zendesk-email-support-code" className={classes.emailUsCode} onClick={copyOnClick}>
              {code}
            </Headline2>
            <Caption color={colors.base.border} className={classes.clickToCopy} onClick={copyOnClick}>
              {localizeMessage('emailUsCopyCodeDescription')}
            </Caption>
          </>
        ) : null}

        {copiedSuccessfully ? (
          <Snackbar
            open
            autoClose
            variant="success"
            message={localizeMessage('emailUsCodeCopiedNotification')}
            onClose={resetCopiedState}
            data-test="snackbar-success"
          />
        ) : null}
        <input className={classes.hidden} id="hiddenInput" value={code} disabled />
      </div>
    </Card>
  );
};
