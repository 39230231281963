import { formatPersonalNumber } from '@cancellation-portal/assets/js/formatters';
import { LocalizedMessage } from '@cancellation-portal/internationalization';
import type { UserInformation } from '@cancellation-portal/models/UserInformation';
import { parseDate, parseTime } from '@cancellation-portal/utils/date';
import useTheme from '@material-ui/core/styles/useTheme';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Subheading1 } from '@minna-technologies/minna-ui/components/Typography/Subheading1';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import React from 'react';

interface Props {
  showPersonalNumber: boolean;
  showIpAddress: boolean;
  showEmail: boolean;
  titleId: string;
  user: UserInformation;
  userSignedLoaAt?: string;
  userSignedLoaFromIpAddress?: string;
}

export const SigneeInfo: FC<Props> = (
  { user, userSignedLoaAt, userSignedLoaFromIpAddress, showPersonalNumber, showEmail, showIpAddress, titleId },
  { localizeDate, localizeMessage }
) => {
  const { colors } = useTheme();

  return (
    <div style={{ marginBottom: '16px' }}>
      <Subheading1 style={{ marginBottom: '16px', marginTop: '8px' }}>
        <LocalizedMessage id={titleId} />
      </Subheading1>
      <Body>{`${user.name.first} ${user.name.last} (${localizeMessage('theUser')})`}</Body>
      {showPersonalNumber && (
        <Body color={colors.textOn.surfaceSubdued}>
          <LocalizedMessage id="personalNumber" />: {formatPersonalNumber(user.personalNumber)}
        </Body>
      )}
      {userSignedLoaAt && (
        <Body color={colors.textOn.surfaceSubdued}>
          <LocalizedMessage id="signedAt" />:{' '}
          {`${localizeDate(parseDate(userSignedLoaAt))} ${parseTime(userSignedLoaAt)}`}
        </Body>
      )}
      {showIpAddress && userSignedLoaFromIpAddress && (
        <Body color={colors.textOn.surfaceSubdued}>
          <LocalizedMessage id="ipAddress" />: {userSignedLoaFromIpAddress}
        </Body>
      )}
      {showEmail && user.email && (
        <Body color={colors.textOn.surfaceSubdued}>
          <LocalizedMessage id="emailAddress" />: {user.email}
        </Body>
      )}
    </div>
  );
};

SigneeInfo.contextTypes = {
  localizeDate: PropTypes.func.isRequired,
  localizeMessage: PropTypes.func.isRequired,
};
