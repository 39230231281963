import type { LocalDate } from '@cancellation-portal/models/LocalDate';

export enum DecisionType {
  TerminationApproved = 'TerminationApproved',
  TerminatedWithPendingPayments = 'TerminatedWithPendingPayments',
  AlreadyTerminated = 'AlreadyTerminated',
  TerminationDeclined = 'TerminationDeclined',
}

export enum PaymentsDecision {
  NoPayments = 'NoPayments',
  YesPayments = 'YesPayments',
}

export type SupplierTerminationDecision =
  | TerminationApproved
  | AlreadyTerminated
  | TerminationDeclined
  | TerminatedWithPendingPayments;

export interface TerminationApproved {
  type: DecisionType.TerminationApproved;
  contractEndsAt: LocalDate;
  messageToUser?: string;
}

export interface AlreadyTerminated {
  type: DecisionType.AlreadyTerminated;
  contractEndsAt: LocalDate;
  messageToUser: string;
}

export enum RejectReason {
  HasBindingPeriod = 'HasBindingPeriod',
  NoAccount = 'NoAccount',
  OtherReason = 'OtherReason',
}

export type TerminationSupplierRejectReason = HasBindingPeriod | NoAccount | OtherReason;

export interface NoAccount {
  name: RejectReason.NoAccount;
}

export interface OtherReason {
  name: RejectReason.OtherReason;
}

export interface HasBindingPeriod {
  name: RejectReason.HasBindingPeriod;
  bindingPeriodEndsAt: LocalDate;
}

export interface TerminationDeclined {
  type: DecisionType.TerminationDeclined;
  reason: TerminationSupplierRejectReason;
  messageToUser?: string;
}

export interface TerminatedWithPendingPayments {
  type: DecisionType.TerminatedWithPendingPayments;
  contractEndsAt: LocalDate;
  messageToUser: string;
  // This field doesn't exist on model in Core
  paymentsDecision: PaymentsDecision;
  lastPaymentAt: LocalDate;
}
