import { selectorAppContext } from '@cancellation-portal/modules/app-context/duck';
import { LoadingPage } from '@cancellation-portal/modules/fetching/LoadingPage';
import type { ZendeskCategorySection } from '@cancellation-portal/modules/Zendesk/models';
import { ArticlesResult } from '@cancellation-portal/modules/Zendesk/ZendeskHelpCenter/components/ArticlesResult';
import { ZendeskSubHeader } from '@cancellation-portal/modules/Zendesk/ZendeskSubHeader';
import { useMountEffect } from '@cancellation-portal/utils/hooks/use-mount-effect';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import type { FC } from 'react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ARTICLES_FILTERED_BY_SECTION,
  filterArticlesBySection,
  selectorLoadingArticles,
} from '../../../ducks/zendesk-help-center';

const useStyles = makeStyles((theme) => ({
  displaySection: {
    padding: 0,
  },
  displaySectionSubheader: {
    padding: theme.spacing(2),
  },
}));

interface Props {
  section: ZendeskCategorySection;
}

export const DisplaySection: FC<Props> = ({ section }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const appContext = useSelector(selectorAppContext);
  const loadingArticles = useSelector(selectorLoadingArticles);

  useMountEffect(() => {
    dispatch(filterArticlesBySection(appContext.features.helpCenter.zendeskBaseUrl, appContext.locale, section.id));
  });

  return loadingArticles ? (
    <LoadingPage />
  ) : (
    <Card className={classes.displaySection} variant="border">
      <div className={classes.displaySectionSubheader}>
        <ZendeskSubHeader title={section?.name} />
      </div>
      <ArticlesResult articlesFilteredBy={ARTICLES_FILTERED_BY_SECTION} embedded />
    </Card>
  );
};
