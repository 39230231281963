import type { Article } from '@cancellation-portal/modules/Zendesk/models';
import { CardRowLink } from '@minna-technologies/minna-ui/components/CardRowLink';
import type { FC } from 'react';
import * as React from 'react';

interface Props {
  article: Article;
  onNavigateToArticle(sectionId: number, articleId: number): void;
}

export const ArticleResultItem: FC<Props> = ({ article, onNavigateToArticle }) => {
  const handleArticleClick = () => {
    onNavigateToArticle(article.sectionId, article.id);
  };

  return <CardRowLink onClick={handleArticleClick} body={article.title} />;
};
