import { PlatformName } from '@cancellation-portal/models/platform';
import type { SupplierTerminationDecision } from '@cancellation-portal/models/SupplierTerminationDecision';
import { Market } from '@cancellation-portal/modules/app-context/constants';
import { selectorAppContext, selectorLocale } from '@cancellation-portal/modules/app-context/duck';
import { LoadingPage } from '@cancellation-portal/modules/fetching/LoadingPage';
import { open } from '@cancellation-portal/modules/Zendesk/ducks';
import { getMixpanel } from '@cancellation-portal/tracking/mixpanel';
import { languageToLocale } from '@cancellation-portal/utils';
import { useMountEffect } from '@cancellation-portal/utils/hooks/use-mount-effect';
import noop from 'lodash/noop';
import type { FC } from 'react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import URI from 'urijs';
import { MerchantCancellationErrorPage } from '../components/MerchantCancellationErrorPage';
import { MerchantCancellationPage } from '../components/MerchantCancellationPage';
import {
  fetchOverview,
  onFormChange,
  onFormSubmit,
  selectorErrorStatus,
  selectorFormSubmitting,
  selectorIsLoading,
  selectorLoadingFailed,
  selectorOverview,
  selectorSupplierAuthToken,
  selectorSupplierInputForm,
} from '../ducks/termination';

export function isVerifiedByBank(market: Market, platformName: PlatformName) {
  const verifiedByOp = market === Market.Finland && platformName === PlatformName.OpBank;
  const verifiedByIng = market === Market.Belgium && platformName === PlatformName.Ing;
  const verifiedBySb1 = market === Market.Norway && platformName === PlatformName.Sb1;
  const verifiedByLloyds = market === Market.UnitedKingdom && platformName === PlatformName.Lloyds;
  const verifiedByCapitalOne = market === Market.UnitedStates && platformName === PlatformName.CapitalOne;

  return verifiedByOp || verifiedByIng || verifiedBySb1 || verifiedByLloyds || verifiedByCapitalOne;
}

export function shouldUseAgencyAgreementCopy(market: Market, platformName: PlatformName) {
  const verifiedByLloyds = market === Market.UnitedKingdom && platformName === PlatformName.Lloyds;
  const verifiedByCapitalOne = market === Market.UnitedStates && platformName === PlatformName.CapitalOne;
  const verifiedByMinna = market === Market.UnitedStates && platformName === PlatformName.Minna;

  return verifiedByLloyds || verifiedByCapitalOne || verifiedByMinna;
}

export const MerchantCancellationPageContainer: FC = () => {
  const dispatch = useDispatch();

  const appContext = useSelector(selectorAppContext);
  const locale = useSelector(selectorLocale);
  const overview = useSelector(selectorOverview);
  const supplierInputForm = useSelector(selectorSupplierInputForm);
  const isLoading = useSelector(selectorIsLoading);
  const loadingFailed = useSelector(selectorLoadingFailed);
  const errorStatus = useSelector(selectorErrorStatus);
  const supplierAuthToken = useSelector(selectorSupplierAuthToken);
  const isFormSubmitting = useSelector(selectorFormSubmitting);

  const handleDownloadSigningProof = (): void => {
    getMixpanel().track('Downloading termination signing proof', {
      'Supplier name': overview?.supplier.name,
    });
  };

  const verifiedByBank = overview?.termination?.marketPlatform?.market
    ? isVerifiedByBank(
        overview?.termination?.marketPlatform?.market,
        overview?.termination?.marketPlatform?.platform?.name
      )
    : false;
  const useAgencyAgreementCopy = overview?.termination?.marketPlatform?.market
    ? shouldUseAgencyAgreementCopy(
        overview?.termination?.marketPlatform?.market,
        overview?.termination?.marketPlatform?.platform?.name
      )
    : false;

  const downloadSigningProofLink = `/api/v1/supplier-portal/cancellations/signature?token=${supplierAuthToken}`;

  const showError = !supplierAuthToken || loadingFailed;

  useMountEffect(() => {
    const supplierAuthToken = cleanToken(new URI(window.location).search(true).token);
    const supplierLocale = overview ? languageToLocale(overview?.supplier?.language) : undefined;

    // Prevent unnecessary fetching
    if (supplierAuthToken && supplierLocale !== locale) {
      dispatch(fetchOverview(supplierAuthToken, locale));
    } else {
      getMixpanel().track('Supplier auth token missing');
    }
  });

  const handleFormChange = (property: string, value: any): void => {
    dispatch(onFormChange(property, value));
  };

  const openZendeskDialog = (): void => {
    dispatch(open(appContext.features.helpCenter.zendeskBaseUrl, appContext.locale));
  };

  const onFormSubmitWithToken = supplierAuthToken ? onFormSubmit(supplierAuthToken) : noop;

  const handleFormSubmit = (supplierTerminationDecision: SupplierTerminationDecision): void => {
    dispatch(onFormSubmitWithToken(supplierTerminationDecision));
  };

  return (
    <>
      {isLoading && <LoadingPage />}
      {showError && <MerchantCancellationErrorPage errorStatus={errorStatus} />}
      {!showError && !isLoading && overview && (
        <MerchantCancellationPage
          appContext={appContext}
          downloadSigningProofLink={downloadSigningProofLink}
          isFormSubmitting={isFormSubmitting}
          overviewInformation={overview}
          supplierInputForm={supplierInputForm}
          useAgencyAgreementCopy={useAgencyAgreementCopy}
          verifiedByBank={verifiedByBank}
          onDownloadSigningProof={handleDownloadSigningProof}
          onFormChange={handleFormChange}
          onFormSubmit={handleFormSubmit}
          onPrint={() => window.print()}
          openZendeskDialog={openZendeskDialog}
        />
      )}
    </>
  );
};

function cleanToken(originalToken?: string) {
  const authTokenLength = 32;
  const decodedToken = originalToken ? decodeURI(originalToken).replace(' ', '') : originalToken;
  return decodedToken?.substr(0, authTokenLength);
}
