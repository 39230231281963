import { LocalizedMessage } from '@cancellation-portal/internationalization';
import type { SupplierInformation } from '@cancellation-portal/models/SupplierInformation';
import { parseDate, parseTime } from '@cancellation-portal/utils/date';
import useTheme from '@material-ui/core/styles/useTheme';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Subheading1 } from '@minna-technologies/minna-ui/components/Typography/Subheading1';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import React from 'react';

interface Props {
  supplier: SupplierInformation;
}

export const SupplierInfo: FC<Props> = ({ supplier }, { localizeDate }) => {
  const { colors } = useTheme();

  return (
    <div>
      <Subheading1 style={{ marginBottom: '16px', marginTop: '8px' }}>
        <LocalizedMessage id="stakeholders" />
      </Subheading1>
      <Body>{supplier.name}</Body>
      {supplier.emailAddresses && (
        <Body color={colors.textOn.surfaceSubdued}>{supplier.emailAddresses.join(', ')}</Body>
      )}
      <Body color={colors.textOn.surfaceSubdued}>
        <LocalizedMessage id="receivedAt" />:{' '}
        {supplier.sentEmailAt
          ? `${localizeDate(parseDate(supplier.sentEmailAt))} ${parseTime(supplier.sentEmailAt)}`
          : undefined}
      </Body>
      <Body color={colors.textOn.surfaceSubdued} style={{ marginBottom: '16px' }}>
        <LocalizedMessage id="openedAt" />:{' '}
        {supplier.viewedPageAt &&
          `${localizeDate(parseDate(supplier.viewedPageAt))} ${parseTime(supplier.viewedPageAt)}`}
      </Body>
    </div>
  );
};

SupplierInfo.contextTypes = {
  localizeDate: PropTypes.func.isRequired,
};
