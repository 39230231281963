import { LocalizedMessageWithElementsInjected, useLocalization } from '@cancellation-portal/internationalization';
import { zendeskOnlyEmailEnabled } from '@cancellation-portal/modules/app-context';
import { AppType } from '@cancellation-portal/modules/app-context/constants';
import { selectorAppContext } from '@cancellation-portal/modules/app-context/duck';
import { OnlineOfflineIcon } from '@cancellation-portal/modules/shared-components/icons/OnlineOfflineIcon';
import { StartScreen } from '@cancellation-portal/modules/Zendesk/ZendeskHelpCenter/components/StartScreen';
import { ZendeskSubHeader } from '@cancellation-portal/modules/Zendesk/ZendeskSubHeader';
import { getMarketSpecificSupportHours } from '@cancellation-portal/utils/support';
import { ListItem, ListItemIcon } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { CardRowLink } from '@minna-technologies/minna-ui/components/CardRowLink';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import { Subheading2 } from '@minna-technologies/minna-ui/components/Typography/Subheading2';
import { ChatIcon } from '@minna-technologies/minna-ui/icons/Chat';
import { ChevronRightIcon } from '@minna-technologies/minna-ui/icons/ChevronRight';
import { EmailIcon } from '@minna-technologies/minna-ui/icons/Email';
import { PhoneLandlineIcon } from '@minna-technologies/minna-ui/icons/PhoneLandline';
import classNames from 'classnames';
import noop from 'lodash/noop';
import type { FC } from 'react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigateToCallUs, navigateToChat, navigateToEmailUs } from '../../../ducks';
import { selectorIsAgentAvailable } from '../../../ducks/zendesk-chat';

const useStyles = makeStyles((theme) => ({
  contactUsCard: {
    padding: theme.spacing(2, 0, 1),
  },
  contactUsCardLinkBody: {
    paddingLeft: theme.spacing(1),
  },
  openHoursSubtitle: {
    margin: theme.spacing(0.5, 0),
  },
  forceNoBottomPadding: {
    '& div': {
      paddingBottom: '0 !important',
      '& div': {
        paddingBottom: '0 !important',
      },
    },
  },
  listItem: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridTemplateRows: '1fr',
    alignItems: 'center',
    marginLeft: 0,
  },
  paper: {
    padding: theme.spacing(2, 2, 1, 0),
    flex: 1,
  },
  disabled: {
    color: 'gray',
    backgroundColor: 'transparent !important',
    cursor: 'default !important',
  },
  paddingLeft: {
    paddingLeft: theme.spacing(2),
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  leftContainer: {
    alignSelf: 'flex-start',
  },
  rightContainer: {
    alignSelf: 'center',
    justifySelf: 'center',
    marginRight: '0 !important',
  },
  statusLabel: {
    fontSize: '12px',
    marginLeft: '4px',
  },
}));

export const ZendeskContactUs: FC = () => {
  const classes = useStyles();
  const { colors } = useTheme();
  const { localizeMessage } = useLocalization('zendesk');
  const dispatch = useDispatch();

  const appContext = useSelector(selectorAppContext);
  const isAgentAvailable = useSelector(selectorIsAgentAvailable);

  const market = appContext.market;
  const helpCenter = appContext.features.helpCenter;

  const handleNavigateToCallUs = (): void => {
    dispatch(navigateToCallUs());
  };

  const handleNavigateToEmailUs = (): void => {
    dispatch(navigateToEmailUs());
  };

  const handleNavigateToChat = (): void => {
    dispatch(navigateToChat());
  };

  const chatOfflineClassName = !isAgentAvailable && classes.disabled;

  const marketSpecificSupportHours = getMarketSpecificSupportHours(market);
  const supportHoursLocalizedMessage = localizeMessage('contactUsSectionOpenHours', {
    mondayOpeningHour: marketSpecificSupportHours.mondayOpeningHour,
    lunchBreakStartingHour: marketSpecificSupportHours.lunchBreakStartingHour,
    lunchBreakEndingHour: marketSpecificSupportHours.lunchBreakEndingHour,
    openingHour: marketSpecificSupportHours.openingHour,
    closingHour: marketSpecificSupportHours.closingHour,
  });

  const onlineOfflineMessage = (isFutureAvailable: any) => {
    return (
      <span data-test={isFutureAvailable ? 'online' : 'offline'}>
        <OnlineOfflineIcon online={isFutureAvailable} style={{ marginLeft: '2px', marginBottom: '1px' }} />
        <span className={classes.statusLabel}>
          {isFutureAvailable
            ? localizeMessage('contactUsSectionAgentOnline')
            : localizeMessage('contactUsSectionAgentOffline')}
        </span>
      </span>
    );
  };

  return (
    <>
      {zendeskOnlyEmailEnabled(appContext.features) && (
        <Card className={classes.contactUsCard} variant="border">
          <div data-test="zendesk-contact-us-header" className={classes.paddingLeft}>
            <ZendeskSubHeader title={localizeMessage('contactUsSectionTitleV2')} />
          </div>
          {helpCenter.chatEnabled && (
            <>
              <CardRowLink
                body={
                  <div data-test="zendesk-support-chat" className={classes.contactUsCardLinkBody}>
                    <Subheading2>
                      <LocalizedMessageWithElementsInjected
                        id="contactUsSectionChatTitle"
                        elements={{ chatOnlineOffline: onlineOfflineMessage(isAgentAvailable) }}
                      />
                    </Subheading2>
                    <Caption className={classes.marginBottom}>
                      {/* Value of the key should be a space character in the code. For more information: https://minnatechnologies.atlassian.net/wiki/spaces/GOB/pages/1505722506/Zendesk+widget*/}
                      {localizeMessage('chatTemporaryMessagePlaceholder')}
                    </Caption>
                    <Body color={colors.textOn.surfaceSubdued} className={classes.openHoursSubtitle}>
                      {localizeMessage('contactUsSectionCallUsOpenHoursSubtitleV2')}
                    </Body>
                    <Caption color={colors.textOn.surfaceSubdued}>{supportHoursLocalizedMessage}</Caption>
                  </div>
                }
                onClick={isAgentAvailable ? handleNavigateToChat : noop}
                icon={<ChatIcon />}
                className={classNames(classes.forceNoBottomPadding, chatOfflineClassName)}
              />
              <Divider slim />
            </>
          )}

          {helpCenter.callUsEnabled && (
            <ListItem
              key="callUs"
              data-test="zendesk-call-us-list-item"
              className={classes.forceNoBottomPadding}
              onClick={handleNavigateToCallUs}
            >
              <ListItemIcon className={classes.rightContainer}>
                <PhoneLandlineIcon />
              </ListItemIcon>
              <div className={classes.paddingLeft}>
                <div className={classes.listItem}>
                  <div className={classes.leftContainer}>
                    <Body>{localizeMessage('contactUsSectionCallUsTitleV2')}</Body>
                    <Caption className={classes.marginBottom}>
                      {/* Value of the key should be a space character in the code. For more information: https://minnatechnologies.atlassian.net/wiki/spaces/GOB/pages/1505722506/Zendesk+widget*/}
                      {localizeMessage('callUsTemporaryMessagePlaceholder')}
                    </Caption>
                    <Body color={colors.textOn.surfaceSubdued} className={classes.openHoursSubtitle}>
                      {localizeMessage('contactUsSectionCallUsOpenHoursSubtitleV2')}
                    </Body>
                    <Caption color={colors.textOn.surfaceSubdued}>{supportHoursLocalizedMessage}</Caption>
                  </div>
                  <ListItemIcon className={classes.rightContainer}>
                    <ChevronRightIcon />
                  </ListItemIcon>
                </div>
                <Divider />
              </div>
            </ListItem>
          )}
          {helpCenter.emailUsEnabled && (
            <CardRowLink
              key="emailUs"
              data-test="zendesk-email-us-list-item"
              icon={<EmailIcon />}
              className={classes.forceNoBottomPadding}
              onClick={handleNavigateToEmailUs}
              body={
                <div className={classes.contactUsCardLinkBody}>
                  <Subheading2>{localizeMessage('contactUsSectionEmailUsTitle')}</Subheading2>
                  <Caption>
                    {/* Value of the key should be a space character in the code. For more information: https://minnatechnologies.atlassian.net/wiki/spaces/GOB/pages/1505722506/Zendesk+widget*/}
                    {localizeMessage('emailTemporaryMessagePlaceholder')}
                  </Caption>
                  <Body color={colors.textOn.surfaceSubdued} className={classes.openHoursSubtitle}>
                    {localizeMessage('contactUsSectionEmailUsDescriptionSubtitleV2')}
                  </Body>
                  <Caption color={colors.textOn.surfaceSubdued}>
                    {localizeMessage('contactUsSectionEmailUsDescriptionV2')}
                  </Caption>
                </div>
              }
            />
          )}
        </Card>
      )}
      {/*// TODO: use feature toggle when adding full support for 'contact your bank' in Zendesk*/}
      {appContext.appType === AppType.ING && <StartScreen />}
    </>
  );
};
