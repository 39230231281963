import {
  forceLoadMessagesAndLocale,
  loadMessagesAndLocale,
  loadMessagesAndLocaleCancellationPortal,
} from '@cancellation-portal/internationalization/load';
import type { Messages } from '@cancellation-portal/internationalization/types';
import type { Fetchable } from '@cancellation-portal/models/Fetchable';
import {
  fetchableHandle,
  FetchableNotStarted,
  FetchableSuccess,
  mapSuccess,
} from '@cancellation-portal/models/Fetchable';
import type { ActionsUnion } from '@cancellation-portal/utils/redux';
import { createAction, createPromiseAction } from '@cancellation-portal/utils/redux';
import type { AppType, Locale, Market } from '../modules/app-context/constants';

export interface InternationalizationState {
  messages: Fetchable<Messages>;
  locale: Fetchable<Locale>;
}

const initialState: InternationalizationState = {
  messages: FetchableNotStarted,
  locale: FetchableNotStarted,
};

export enum ActionKeys {
  FETCH_MESSAGES_AND_LOCALE = 'INTERNATIONALIZATION/FETCH_MESSAGES_AND_LOCALE',
  FORCE_SET_MESSAGES_AND_LOCALE = 'INTERNATIONALIZATION/FORCE_SET_MESSAGES_AND_LOCALE',
}

export const InternationalizationActions = {
  forceFetchMessagesAndLocale: (locale: Locale, app: AppType, market: Market) =>
    createPromiseAction(ActionKeys.FETCH_MESSAGES_AND_LOCALE, forceLoadMessagesAndLocale(locale, app, market)),
  fetchMessagesAndLocale: (market: Market) =>
    createPromiseAction(ActionKeys.FETCH_MESSAGES_AND_LOCALE, loadMessagesAndLocale(market)),
  fetchMessagesAndLocaleSupplierPortal: (locale: Locale, market: Market) =>
    createPromiseAction(ActionKeys.FETCH_MESSAGES_AND_LOCALE, loadMessagesAndLocaleCancellationPortal(locale, market)),
  forceSetMessagesAndLocale: (messages: Messages, locale: Locale) =>
    createAction(ActionKeys.FORCE_SET_MESSAGES_AND_LOCALE, { messages, locale }),
};

type Actions = ActionsUnion<typeof InternationalizationActions>;

export function reducer(state: InternationalizationState = initialState, action: Actions): InternationalizationState {
  switch (action.type) {
    case ActionKeys.FETCH_MESSAGES_AND_LOCALE:
      return fetchableHandle(state, action, (fetchable) => ({
        ...state,
        messages: mapSuccess(fetchable, (d) => d.messages),
        locale: mapSuccess(fetchable, (d) => d.locale),
      }));

    case ActionKeys.FORCE_SET_MESSAGES_AND_LOCALE:
      return {
        messages: FetchableSuccess(action.payload.messages),
        locale: FetchableSuccess(action.payload.locale),
      };

    default:
      return state;
  }
}

interface State {
  internationalization: InternationalizationState;
}

export const selectorMessagesFetchable = (state: State) => state.internationalization.messages;
export const selectorLocaleFetchable = (state: State) => state.internationalization.locale;

export function selectorIsMessagesFetched(state: State): boolean {
  return Boolean(state.internationalization.messages);
}
