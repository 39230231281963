import type { SupplierTerminationDecision } from '@cancellation-portal/models/SupplierTerminationDecision';
import type { SupplierTerminationOverview } from '@cancellation-portal/models/SupplierTerminationOverview';
import type { AxiosResponse } from 'axios';
import axios from 'axios';

export async function getOverview(supplierAuthToken: string): Promise<SupplierTerminationOverview> {
  return axios
    .get(`/api/v1/supplier-portal/cancellations/overview?token=${supplierAuthToken}`)
    .then(({ data }: AxiosResponse<SupplierTerminationOverview>) => data);
}

export async function postSupplierTerminationDecision(
  supplierAuthToken: string,
  terminationDecision: SupplierTerminationDecision
): Promise<SupplierTerminationOverview> {
  return axios
    .post(`/api/v1/supplier-portal/cancellations/decision?token=${supplierAuthToken}`, terminationDecision)
    .then(({ data }: AxiosResponse<SupplierTerminationOverview>) => data);
}
