import { selectorAppContext } from '@cancellation-portal/modules/app-context/duck';
import { LoadingPage } from '@cancellation-portal/modules/fetching/LoadingPage';
import type { ZendeskCategory, ZendeskCategorySection } from '@cancellation-portal/modules/Zendesk/models';
import { ArticlesResult } from '@cancellation-portal/modules/Zendesk/ZendeskHelpCenter/components/ArticlesResult';
import { ZendeskSubHeader } from '@cancellation-portal/modules/Zendesk/ZendeskSubHeader';
import { useMountEffect } from '@cancellation-portal/utils/hooks/use-mount-effect';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { CardRowLink } from '@minna-technologies/minna-ui/components/CardRowLink';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import type { FC } from 'react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigateToSection } from '../../../ducks';
import {
  ARTICLES_FILTERED_BY_CATEGORY,
  filterArticlesByCategory,
  selectorLoadingArticles,
} from '../../../ducks/zendesk-help-center';

const useStyles = makeStyles((theme) => ({
  displayCategoryCard: {
    padding: theme.spacing(2, 0, 1),
  },
  displayCategorySubheader: {
    padding: theme.spacing(0, 2),
  },
  articlesDivider: {
    width: '100%',
    margin: theme.spacing(2, 0),
  },
}));

interface Props {
  category: ZendeskCategory;
}

export const DisplayCategory: FC<Props> = ({ category }) => {
  const { sections, id } = category;
  const classes = useStyles();
  const dispatch = useDispatch();

  const appContext = useSelector(selectorAppContext);
  const loadingArticles = useSelector(selectorLoadingArticles);

  useMountEffect(() => {
    dispatch(filterArticlesByCategory(appContext.features.helpCenter.zendeskBaseUrl, appContext.locale, category.id));
  });

  const handleNavigateToSection = (categoryId: number, sectionId: number) => {
    dispatch(navigateToSection(categoryId, sectionId));
  };

  return (
    <>
      {loadingArticles && <LoadingPage />}
      {!loadingArticles && (
        <div>
          <Card className={classes.displayCategoryCard} variant="border">
            <div className={classes.displayCategorySubheader}>
              <ZendeskSubHeader title={category.name} />
            </div>
            <Sections sections={sections} onNavigateToSection={handleNavigateToSection} categoryId={id} />
          </Card>
          <Divider className={classes.articlesDivider} />
          <ArticlesResult articlesFilteredBy={ARTICLES_FILTERED_BY_CATEGORY} />
        </div>
      )}
    </>
  );
};

interface SectionProps {
  categoryId: number;
  sections: ZendeskCategorySection[];
  onNavigateToSection(categoryId: number, sectionId: number): void;
}

const Sections: FC<SectionProps> = ({ categoryId, sections, onNavigateToSection }) => {
  const sectionsLength = sections.length;

  return (
    <>
      {sections.map((section: ZendeskCategorySection, index: number) => (
        <div key={section.id}>
          <CardRowLink onClick={() => onNavigateToSection(categoryId, section.id)} body={section.name} />
          {index !== sectionsLength - 1 && <Divider slim />}
        </div>
      ))}
    </>
  );
};
