import type { BaseIconProps } from '@cancellation-portal/modules/shared-components/icons/BaseIconProps';
import type { FC } from 'react';
import React from 'react';

export const AssignmentLateIcon: FC<BaseIconProps> = (props) => {
  return (
    // eslint-disable-next-line
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" {...props}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-6 15h-2v-2h2v2zm0-4h-2V8h2v6zm-1-9c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"
        fill={props.nativeColor}
      />
    </svg>
  );
};
