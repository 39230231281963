import { Collapse, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import { ChevronDownIcon } from '@minna-technologies/minna-ui/icons/ChevronDown';
import { ChevronUpIcon } from '@minna-technologies/minna-ui/icons/ChevronUp';
import React from 'react';

const noPaddingStyle = {
  padding: 0,
};

interface ExpandablePaperProps {
  children?: React.ReactNode;
  className?: string;
  contentClassName?: string;
  header: React.ReactNode;
  initiallyOpen?: boolean;
}

const useStyles = makeStyles(() => ({
  listItemIcon: {
    justifyContent: 'flex-end',
  },
}));

export const ExpandablePaper = ({
  children,
  className,
  contentClassName,
  header,
  initiallyOpen,
}: ExpandablePaperProps) => {
  const [open, setOpen] = React.useState(initiallyOpen);
  const classes = useStyles();

  return (
    <Paper className={className}>
      <List style={noPaddingStyle}>
        <ListItem onClick={() => setOpen(!open)}>
          <ListItemText primary={header} />
          <ListItemIcon className={classes.listItemIcon}>{open ? <ChevronUpIcon /> : <ChevronDownIcon />}</ListItemIcon>
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ContentHolder key={0} className={contentClassName}>
              {children}
            </ContentHolder>
          </List>
        </Collapse>
      </List>
    </Paper>
  );
};

interface ContentHolderProps {
  children?: React.ReactNode;
  className?: string;
}

const ContentHolder = ({ children, className }: ContentHolderProps) => {
  return <div className={className}>{children}</div>;
};
