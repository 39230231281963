import { useDispatch as reduxUseDispatch } from 'react-redux';
import type { AnyAction, Dispatch as ReduxDispatch } from 'redux';

export interface Action<T extends string = any> {
  type: T;
}
export interface ActionWithPayload<T extends string, P> extends Action<T> {
  payload: P;
}
export interface ActionWithPromise<T extends string, P> extends Action<T> {
  promise: Promise<P>;
}
export function createAction<T extends string>(type: T): Action<T>;
export function createAction<T extends string, P>(type: T, payload: P): ActionWithPayload<T, P>;
export function createAction<T extends string, P>(type: T, payload?: P) {
  return payload === undefined ? { type } : { type, payload };
}
export function createPromiseAction<T extends string, P>(type: T, promise: Promise<P>): ActionWithPromise<T, P> {
  return { type, promise };
}
interface StringMap<T> {
  [key: string]: T;
}
type AnyFunction = (...args: any[]) => any;
// For every action function in A give us a union of the return types which will either be an Action, ActionWithPayload or ActionWithPromise.
export type ActionsUnion<A extends StringMap<AnyFunction>> = ReturnType<A[keyof A]>;

/**
 * We have middleware that modifies how dispatch works.
 * This is just a re-export that you can use to get typescript to accept the types when working with promises.
 *
 * @example
 * //Assume we have an action creator defined somewhere with this type
 * const actionCreatorThatCreatesPromise: () => ActionWithPromise<'GET_SUBSCRIPTION', Subscription>;
 *
 * const mapDispatchToProps = (dispatch: Dispatch) => ({
 *   onClick(): {
 *      //then we get access to the promise outside of dispatch!
 *     dispatch(actionCreatorThatCreatesPromise()).then(subscription => ...
 *   }
 * });
 */
export interface Dispatch<Actions extends Action = AnyAction> extends ReduxDispatch {
  <T extends string, A extends Action<T> & Actions>(action: A): A extends ActionWithPromise<T, infer PromiseType>
    ? Promise<{ payload: PromiseType }>
    : A extends ActionWithPayload<T, infer PayloadType>
    ? ActionWithPayload<T, PayloadType>
    : Action<T>;
}

/**
 * We have middleware that modifies how dispatch works.
 * This is just a re-export that you can use to get typescript to accept the types when working with promises.
 *
 * @example
 * //Assume we have an action creator defined somewhere with this type
 * const actionCreatorThatCreatesPromise: () => ActionWithPromise<'GET_SUBSCRIPTION', Subscription>;
 *
 * //then we get access to the promise outside of dispatch!
 * const dispatch = useDispatch();
 * dispatch(actionCreatorThatCreatesPromise()).then(subscription => ...
 */
export const useDispatch: <Actions extends Action = AnyAction>() => Dispatch<Actions> = reduxUseDispatch;
