import get from 'lodash/get';

export const eventsArrayToNumChatEvents = (events: any = []) => {
  if (!Array.isArray(events)) return 0;

  const chatEvents = events.filter(isChatEvent);

  return chatEvents.length;
};

export const isChatEvent = (event: any) => {
  const type = get(event, 'type', '');

  return /^chat/.test(type);
};
