import { Locale } from '@cancellation-portal/modules/app-context/constants';
import { selectorBaseAppContext } from '@cancellation-portal/modules/app-context/duck';
import { MultiDeviceLayout } from '@cancellation-portal/shared-components/layout/MultiDeviceLayout';
import { getWhiteLogo } from '@cancellation-portal/utils/logo';
import AppBar from '@material-ui/core/AppBar';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import type { FC, ReactNode } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.scss';

export const TerminationNotAvailable = 'TERMINATION_NOT_AVAILABLE';
export const Unauthorized = 'UNAUTHORIZED_ERROR';
export const NetworkError = 'NETWORK_ERROR';
export const UnknownError = 'UNKNOWN_ERROR';

export type ErrorStatusTypes = 'TERMINATION_NOT_AVAILABLE' | 'NETWORK_ERROR' | 'UNKNOWN_ERROR' | 'UNAUTHORIZED_ERROR';

interface Props {
  errorStatus?: ErrorStatusTypes;
}

export const MerchantCancellationErrorPage: FC<Props> = ({ errorStatus = UnknownError }) => {
  const appContext = useSelector(selectorBaseAppContext);

  switch (errorStatus) {
    case TerminationNotAvailable:
      return (
        <Wrapper>
          {appContext.locale == Locale.svSE && (
            <div className={styles.marginTop}>
              <Body className={styles.center}>Sidan hittades inte. Var god kontrollera länken.</Body>
              <Body className={styles.center}>
                Vid frågor, kontakta <a href="mailto:uppsagning@minatjanster.se">uppsagning@minatjanster.se</a>
              </Body>
            </div>
          )}
          {appContext.locale != Locale.svSE && (
            <div className={styles.marginTop}>
              <Body className={styles.center}>Page not found. Please check the link.</Body>
              <Body className={styles.center}>
                If you have any questions please contact
                <a href="mailto:uppsagning@minatjanster.se">uppsagning@minatjanster.se</a>
              </Body>
            </div>
          )}
        </Wrapper>
      );

    case NetworkError:
      return (
        <Wrapper>
          {appContext.locale == Locale.svSE && (
            <div className={styles.marginTop}>
              <Body className={styles.center}>
                Ett nätverksfel uppstod. Var god kontrollera att er anslutning och eventuell brandvägg eller proxy är
                korrekt konfigurerad.
              </Body>
              <Body className={styles.center}>
                Vid frågor, kontakta <a href="mailto:uppsagning@minatjanster.se">uppsagning@minatjanster.se</a>
              </Body>
            </div>
          )}
          {appContext.locale != Locale.svSE && (
            <div className={styles.marginTop}>
              <Body className={styles.center}>
                A network error occurred. Please verify that your connection works and that potential firewall or proxy
                is configured correctly.
              </Body>
              <Body className={styles.center}>
                If you have any questions, please contact{' '}
                <a href="mailto:uppsagning@minatjanster.se">uppsagning@minatjanster.se</a>
              </Body>
            </div>
          )}
        </Wrapper>
      );

    case Unauthorized:
      return (
        <Wrapper>
          <div className={styles.marginTop}>
            <Body className={styles.center}>
              We could not find what you are looking for. This could be because the url is incorrect or the time limit
              for this cancellation link has expired.
            </Body>
            <Body className={styles.center}>
              Please send us an email at{' '}
              <a href="mailto:support@minnatechnologies.zendesk.com">support@minnatechnologies.zendesk.com</a> if you
              need further assistance.
            </Body>
          </div>
        </Wrapper>
      );

    default:
      return (
        <Wrapper>
          {appContext.locale == Locale.svSE && (
            <div className={styles.marginTop}>
              <Body className={styles.center}>Var god och prova igen. Fungerar inte det, försök igen senare.</Body>
            </div>
          )}
          {appContext.locale != Locale.svSE && (
            <div className={styles.marginTop}>
              <Body className={styles.center}>An error occurred. Please try again later.</Body>
            </div>
          )}
        </Wrapper>
      );
  }
};

interface WrapperProps {
  children: ReactNode;
}

const Wrapper: FC<WrapperProps> = ({ children }) => {
  const appContext = useSelector(selectorBaseAppContext);

  const header = (
    <AppBar position="sticky" className={styles.appBar}>
      <div className={styles.appBarItem}>
        <img src={getWhiteLogo(appContext.appType)} alt={appContext.displayName} className={styles['mt-logo']} />
      </div>
    </AppBar>
  );

  return (
    <MultiDeviceLayout header={header} desktop>
      <Card className={styles.container}>{children}</Card>
    </MultiDeviceLayout>
  );
};
