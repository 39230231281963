import { LocalizedMessage } from '@cancellation-portal/internationalization';
import {
  increaseNumberOfAdditionalArticlesToDisplay,
  selectorArticle,
  selectorArticles,
  selectorNumberOfAdditionalArticlesToDisplay,
} from '@cancellation-portal/modules/Zendesk/ducks/zendesk-help-center';
import type { Article } from '@cancellation-portal/modules/Zendesk/models';
import { ArticleResultItem } from '@cancellation-portal/modules/Zendesk/ZendeskHelpCenter/components/ArticlesResult/articlesResultItem';
import styles from '@cancellation-portal/modules/Zendesk/ZendeskHelpCenter/components/ArticlesResult/styles.scss';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import { Subheading2 } from '@minna-technologies/minna-ui/components/Typography/Subheading2';
import filter from 'lodash/filter';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import map from 'lodash/map';
import size from 'lodash/size';
import slice from 'lodash/slice';
import take from 'lodash/take';
import type { FC } from 'react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigateToArticle } from '../../../ducks';
import {
  ARTICLES_FILTERED_BY_CATEGORY,
  ARTICLES_FILTERED_BY_LABELS,
  ARTICLES_FILTERED_BY_SECTION,
  ARTICLES_FILTERED_BY_USER_SEARCH,
  resetNumberOfAdditionalArticlesToDisplay,
} from '../../../ducks/zendesk-help-center/zendesk-help-center-actions';

const useStyles = makeStyles((theme) => ({
  articlesResultCard: {
    padding: theme.spacing(2, 0, 1),
  },
  articlesResultSubheader: {
    padding: theme.spacing(0, 2),
  },
}));

const DEFAULT_NUMBER_VISIBLE_ARTICLES = 3;
const INCREMENT_ADDITIONAL_ARTICLES_AMOUNT = 3;

interface Props {
  articlesFilteredBy: string;
  embedded?: boolean;
}

export const ArticlesResult: FC<Props> = ({ articlesFilteredBy, embedded }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const articles = useSelector(selectorArticles);
  const currentArticle = useSelector(selectorArticle);
  const numberOfAdditionalArticlesToDisplay = useSelector(selectorNumberOfAdditionalArticlesToDisplay);

  const handleNavigateToArticle = (sectionId: number, articleId: number): void => {
    dispatch(navigateToArticle(sectionId, articleId));
  };

  const increaseNumberOfAdditionalArticles = (amount: number): void => {
    dispatch(increaseNumberOfAdditionalArticlesToDisplay(amount));
  };

  useEffect(() => {
    return function cleanup() {
      dispatch(resetNumberOfAdditionalArticlesToDisplay());
    };
  }, [dispatch]);

  const title = () => {
    switch (articlesFilteredBy) {
      case ARTICLES_FILTERED_BY_USER_SEARCH:
        return <LocalizedMessage id="articleResultTitleSearchResult" />;
      case ARTICLES_FILTERED_BY_LABELS:
      case ARTICLES_FILTERED_BY_SECTION:
        return <LocalizedMessage id="articleResultTitleRelatedArticles" />;
      case ARTICLES_FILTERED_BY_CATEGORY:
        return <LocalizedMessage id="articleResultTitltaPopularArticles" />;
      default:
        return undefined;
    }
  };

  const isViewingArticle = !isUndefined(currentArticle);
  const isArticleCurrentArticle = (articleId: number) => get(currentArticle, 'id') === articleId;
  const articlesWithoutCurrentArticle = isViewingArticle
    ? filter(articles, (article: Article) => !isArticleCurrentArticle(article.id))
    : articles;

  // Show all results if we are showing search results or viewing the category results,
  // otherwise show default number of articles.
  const isSearchResults = articlesFilteredBy === ARTICLES_FILTERED_BY_USER_SEARCH;
  const isSectionResults = articlesFilteredBy === ARTICLES_FILTERED_BY_SECTION && !isViewingArticle;
  const numberOfVisibleArticles =
    isSearchResults || isSectionResults ? size(articlesWithoutCurrentArticle) : DEFAULT_NUMBER_VISIBLE_ARTICLES;

  const maximumNumberOfArticlesToBeDisplayed =
    articlesWithoutCurrentArticle.length < numberOfVisibleArticles
      ? articlesWithoutCurrentArticle.length
      : numberOfVisibleArticles;

  const articleListItems = map(
    take(articlesWithoutCurrentArticle, numberOfVisibleArticles),
    (article: Article, index: number) => {
      return (
        <div key={article.id}>
          <ArticleResultItem article={article} onNavigateToArticle={handleNavigateToArticle} />
          {index !== maximumNumberOfArticlesToBeDisplayed - 1 && <Divider slim />}
        </div>
      );
    }
  );

  // If there are more articles to display and we're viewing an article, display the show more button.
  // Increases the amount of additional items by 3 when clicked.
  const moreArticlesToDisplay =
    numberOfVisibleArticles + numberOfAdditionalArticlesToDisplay < size(articlesWithoutCurrentArticle);
  const showMoreButton =
    moreArticlesToDisplay && isViewingArticle ? (
      <div className={styles['show-more-button-container']}>
        <Button onClick={() => increaseNumberOfAdditionalArticles(INCREMENT_ADDITIONAL_ARTICLES_AMOUNT)}>
          <div className={styles['show-more-button']}>
            <LocalizedMessage id="articleResultShowMoreButton" />
          </div>
        </Button>
      </div>
    ) : null;

  // If requested to see additional items
  const additionalArticlesIndex = numberOfVisibleArticles + numberOfAdditionalArticlesToDisplay;
  const additionalArticlesToDisplay = slice(
    articlesWithoutCurrentArticle,
    numberOfVisibleArticles,
    additionalArticlesIndex
  );

  const additionalArticlesToDisplayLength = additionalArticlesToDisplay.length;

  const additionalArticleToDisplayListItems =
    numberOfAdditionalArticlesToDisplay !== 0 && isViewingArticle
      ? map(additionalArticlesToDisplay, (article: Article, index) => (
          <div>
            <ArticleResultItem key={article.id} article={article} onNavigateToArticle={handleNavigateToArticle} />
            {index !== additionalArticlesToDisplayLength - 1 && <Divider slim />}
          </div>
        ))
      : null;

  const ResultsContainer = ({ children }: { children: React.ReactNode }) => {
    if (embedded) {
      return <div>{children}</div>;
    }

    return (
      <Card className={classes.articlesResultCard} variant="border">
        {children}
      </Card>
    );
  };

  return (
    <ResultsContainer>
      <div className={classes.articlesResultSubheader}>
        <Subheading2>{title()}</Subheading2>
      </div>
      {articleListItems}
      {additionalArticleToDisplayListItems}
      {showMoreButton}
    </ResultsContainer>
  );
};
