import { selectorSupplierName } from '@cancellation-portal/ducks/termination';
import type { FirstMessageForm } from '@cancellation-portal/modules/Zendesk/models';
import get from 'lodash/get';
import includes from 'lodash/includes';
import last from 'lodash/last';

export const selectorZChatIsDoneInitializing = (state: any): boolean => state.zendeskChat.isDoneInitializing;
export const selectorShouldInitialize = (state: any) =>
  !state.zendeskChat.isInitializing && !state.zendeskChat.isDoneInitializing;
export const selectorIsChatting = (state: any): boolean => state.zendeskChat.isChatting;
export const selectorIsChatConnected = (state: any) => state.zendeskChat.connectionStatus === 'connected';
export const selectorAccountStatus = (state: any) => state.zendeskChat.agentStatus;
export const selectorIsAgentAvailable = (state: any) => includes(['away', 'online'], selectorAccountStatus(state));
export const selectorChatEvents = (state: any) => state.zendeskChat.events;
export const selectorFirstMessageForm = (state: any): FirstMessageForm => state.zendeskChat.firstMessageForm;
export const selectorMessageForm = (state: any) => state.zendeskChat.messageForm;
export const selectorAgentIsTyping = (state: any) => state.zendeskChat.agentIsTyping;
export const selectorVisitorIsTyping = (state: any) => state.zendeskChat.visitorIsTyping;
export const selectorAgentData = (state: any) => state.zendeskChat.agentData;
export const selectorAdditionalUserInfoWhenSetVisitorInfo = (state: any) => {
  return selectorSupplierName(state);
};
export const selectorUnreadMessagesCount = (state: any): number => state.zendeskChat.unreadMessages.length;

export const selectorRecentMessageText = (state: any) => get(last(state.zendeskChat.unreadMessages), 'msg');
export const selectorShowRecentMessage = (state: any) => state.zendeskChat.showRecentMessage;
