import type { AppFeatureToggles } from './modules/app-context';

export const defaultFeatures: AppFeatureToggles = {
  helpCenter: {
    enabled: true,
    chatEnabled: true,
    callUsEnabled: true,
    callBackEnabled: false,
    emailUsEnabled: true,
    zendeskBaseUrl: 'https://minatjanster.zendesk.com',
    supportEmail: 'kontakt@minatjanster.se',
  },
};
