import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import last from 'lodash/last';
import moment from 'moment';
import React from 'react';
import { Message } from './Message';

const useStyles = makeStyles((theme) => ({
  messageGroup: {
    display: 'flex',
    flexShrink: 0,
    flexDirection: 'column',
    '&.user': {
      alignSelf: 'flex-end',
      alignItems: 'flex-end',
      width: '80%',
    },
    '&.agent': {
      alignSelf: 'flex-start',
      alignItems: 'flex-start',
      width: '90%',
    },
  },
  timestamp: {
    color: '#9f9f9f',
    fontSize: '12px',
    padding: theme.spacing(0.25),
  },
}));

interface MessageGroupProps {
  messages: any[];
}

export const MessageGroup = ({ messages }: MessageGroupProps) => {
  const classes = useStyles();
  const fromUser = messages[0].nick === 'visitor';
  const messageElements = messages.map((message: any, i: any) => (
    <Message message={message} fromUser={fromUser} key={i} />
  ));
  const lastTimestamp = last<any>(messages).timestamp;
  const lastTime = moment(lastTimestamp).format('HH:mm');

  const userTypeClass = fromUser ? 'user' : 'agent';

  return (
    <div className={classNames(classes.messageGroup, userTypeClass)}>
      {messageElements}
      <div className={classes.timestamp}>{lastTime}</div>
    </div>
  );
};
