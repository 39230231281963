import bankIdImage from '@cancellation-portal/assets/images/signing/bankid-logo.svg';
import nemIdImage from '@cancellation-portal/assets/images/signing/nemid-logo-black.svg';
import { SigneeType } from '@cancellation-portal/models/LoaInformation';

export function getSigningIcon(classes: any, signeeType?: SigneeType): SigningInfo | undefined {
  switch (signeeType) {
    case SigneeType.BankIdSignee:
      return {
        icon: bankIdImage,
        styling: classes.bankId,
        infoLink: 'https://www.bankid.com/en/om-bankid/detta-ar-bankid',
      };
    case SigneeType.NemIdSignee:
      return {
        icon: nemIdImage,
        styling: classes.nemId,
        infoLink: 'https://www.nemid.nu/dk-en/about_nemid/',
      };
    default:
      return undefined;
  }
}

export interface SigningInfo {
  icon: string;
  styling: string;
  infoLink: string;
}
