import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { getEventMessage } from './chat-history-utils';

const useStyles = makeStyles((theme) => ({
  event: {
    textAlign: 'center',
    color: '#9f9f9f',
    fontSize: '12px',
    padding: theme.spacing(1),
  },
}));

interface ChatEventProps {
  event: unknown;
}

export const ChatEvent = ({ event }: ChatEventProps) => {
  const classes = useStyles();

  return <div className={classes.event}>{getEventMessage(event)}</div>;
};
