import { LocalizedMessage, LocalizedMessageWithElementsInjected } from '@cancellation-portal/internationalization';
import get from 'lodash/get';
import React from 'react';

export const eventToAccountStatusString = (event: any) => {
  const data = get(event, 'data', '');

  return data === 'away' ? (
    <LocalizedMessage id="chatEventAccountStatusAway" />
  ) : (
    <LocalizedMessage id="chatEventAccountStatus" />
  );
};

export const eventToMemberJoinedString = (event: any) => {
  const displayName = get(event, 'display_name', '');
  const displayNameElement = <span>{displayName}</span>;

  return <LocalizedMessageWithElementsInjected id="chatEventMemberJoined" elements={{ displayNameElement }} />;
};

export const isUserJoinedEvent = (event: any) => {
  const nick = get(event, 'nick', '');
  const type = get(event, 'type', '');

  return nick === 'visitor' && type === 'chat.memberjoin';
};

export const isNotUserJoinedEvent = (event: any) => !isUserJoinedEvent(event);

export const eventToMemberLeftString = (event: any) => {
  const nick = get(event, 'nick', '');
  const displayName = get(event, 'display_name', '');
  const displayNameElement = <span>{displayName}</span>;

  if (nick === 'visitor') {
    return <LocalizedMessage id="chatEventMemberLeftVisitor" />; // Doesn't happen ordinarily; this event is filtered out by reducer
  } else {
    return <LocalizedMessageWithElementsInjected id="chatEventMemberLeft" elements={{ displayNameElement }} />;
  }
};

export const getEventMessage = (event: any) => {
  const type = get(event, 'type', '');

  switch (type) {
    case 'account_status':
      return eventToAccountStatusString(event);

    case 'chat.memberjoin':
      return eventToMemberJoinedString(event);

    case 'chat.memberleave':
      return eventToMemberLeftString(event);

    default:
      return '';
  }
};
