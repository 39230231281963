import { LocalizedMessage } from '@cancellation-portal/internationalization';
import { LoadingPage } from '@cancellation-portal/modules/fetching/LoadingPage';
import { ArticlesResult } from '@cancellation-portal/modules/Zendesk/ZendeskHelpCenter/components/ArticlesResult';
import styles from '@cancellation-portal/modules/Zendesk/ZendeskHelpCenter/components/UserSearchResult/styles.scss';
import { ZendeskSubHeader } from '@cancellation-portal/modules/Zendesk/ZendeskSubHeader';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import type { FC } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  ARTICLES_FILTERED_BY_USER_SEARCH,
  selectorArticles,
  selectorLoadingArticles,
} from '../../../ducks/zendesk-help-center';

export const UserSearchResult: FC = () => {
  const resultHasArticles = useSelector(selectorArticles).length > 0;
  const loadingArticles = useSelector(selectorLoadingArticles);

  const searchResult = <ArticlesResult articlesFilteredBy={ARTICLES_FILTERED_BY_USER_SEARCH} />;

  const noResult = (
    <Card className={styles['padding-top']}>
      <div className={styles['padding-left-right']}>
        <ZendeskSubHeader title={<LocalizedMessage id="userSearchResultTitle" />} />
      </div>
      <div className={styles.padding}>
        <Body>
          <LocalizedMessage id="userSearchNoResult" />
        </Body>
      </div>
    </Card>
  );

  return loadingArticles ? <LoadingPage /> : resultHasArticles ? searchResult : noResult;
};
