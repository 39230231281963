import { LocalizedMessage } from '@cancellation-portal/internationalization';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Subheading1 } from '@minna-technologies/minna-ui/components/Typography/Subheading1';
import React from 'react';

interface AgentProps {
  agentName: string;
  agentRegNumber: string;
}

export const Agent = ({ agentName, agentRegNumber }: AgentProps) => {
  return (
    <div>
      <Subheading1 style={{ marginBottom: '16px', marginTop: '8px' }}>
        <LocalizedMessage id="agent" />
      </Subheading1>
      <Body>{agentName}</Body>
      <Body style={{ marginBottom: '16px' }}>{agentRegNumber}</Body>
    </div>
  );
};
