import isFunction from 'lodash/isFunction';
import type { FC } from 'react';
import React from 'react';
import { AppIntegration } from '../../modules/app-integration';

interface ExternalLinkProps {
  href: string;
  style?: any;
  className?: string;
  children?: React.ReactNode;
  onClick?(event: any): void;
}
/**
 * This component is for external links.
 * Automatically opens the link as a new page or in the mobile browser in case of running inside a mobile app.
 * Adds protection and avoids indexing by search engines.
 */
export const ExternalLink: FC<ExternalLinkProps> = ({
  children,
  href,
  style,
  onClick,
  className,
}: ExternalLinkProps) => {
  const onHyperLinkClick = (event: any) => {
    AppIntegration.openExternalWebPage(href, event);

    if (isFunction(onClick)) {
      onClick(event);
    }
  };

  return (
    <a
      className={className}
      href={href}
      style={style}
      target="_blank"
      rel="noopener noreferrer external nofollow"
      onClick={onHyperLinkClick}
    >
      {children}
    </a>
  );
};
