// See https://minnatechnologies.atlassian.net/wiki/spaces/DEV/pages/158007323/Best+practices+for+event+naming+in+Mixpanel
// for best practices of event naming in Mixpanel

export enum ButtonName {
  CLOSE = 'Close',
  CONTINUE = 'Continue',
  SWITCH = 'Switch',
}

export enum EventNames {
  CLICKED_BUTTON = 'Clicked button',
  CLICKED_CARD = 'Clicked card',
  ENTERED_GUIDE = 'Entered guide',
  ORDER_SENT = 'Order sent',
  RATED_EXPERIENCE = 'Rated experience',
  SELECTED_PRODUCT = 'Selected product',
  SUBMITTED_FORM = 'Submitted form',
  SUBMITTED_SURVEY = 'Submitted survey',
  VIEWED_CARD = 'Viewed card',
  VIEWED_DIALOG = 'Viewed dialog',
  VIEWED_GUIDE = 'Viewed guide',
  VIEWED_OVERVIEW = 'Viewed overview',
  VIEWED_PAGE = 'Viewed page',
  VIEWED_SURVEY = 'Viewed survey',
}

export enum ButtonLocation {
  BOTTOM = 'Bottom',
  BOTTOM_CENTER = 'Bottom center',
  BOTTOM_LEFT = 'Bottom left',
  BOTTOM_RIGHT = 'Bottom right',
  CENTER = 'Center',
  LEFT = 'Left',
  RIGHT = 'Right',
  TOP = 'Top',
  TOP_LEFT = 'Top left',
  TOP_RIGHT = 'Top right',
}
