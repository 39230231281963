import { Sentry } from '@cancellation-portal/assets/js/sentry';
import type { AxiosError } from 'axios';
import type { Dispatch } from 'redux';
import type { GetState } from 'redux-pack';
import { Market } from '../../app-context/constants';
import { CHAT_CLOSED, CHAT_MINIMIZED, CHAT_OPEN } from './zendesk';
import {
  ARTICLE_DETAILS_ROUTE,
  CALL_US_ROUTE,
  CATEGORY_ROUTE,
  CHAT_ROUTE,
  CONTACT_US_ROUTE,
  EMAIL_US_ROUTE,
  POP_ROUTE,
  PUSH_ROUTE,
  RESET_NAVIGATION_ON_CLOSE,
  SAVE_CALL_BACK_REQUEST_DATA,
  SECTION_ROUTE,
  SET_DIALOG_OPEN_STATE,
  USER_SEARCH_RESULT_ROUTE,
} from './zendesk-action-types';
import { onClose as chatOnClose, onMinimize as chatOnMinimize, onOpen as chatOnOpen } from './zendesk-chat';
import { hideRecentMessage, markAllMessagesAsRead } from './zendesk-chat/zendesk-chat-actions';
import { clearUserSearchQuery, initHelpCenter } from './zendesk-help-center/zendesk-help-center-actions';
import { selectorIsChatOnly } from './zendesk-selectors';

export const getFaqCategoryId = (market: Market): any => {
  //multiple-markets
  if (market === Market.Denmark) {
    return 360001180733;
  } else if (market === Market.Sweden) {
    return 115000550809;
  } else if (market === Market.Norway) {
    return 0; //TODO Need to exchange this one when we have the real articles in place
  }
};

export const open =
  (zendeskBaseUrl: string, currentLocale: any, labelsForSuggestedArticles?: any): any =>
  (dispatch: any, getState: GetState<any>) => {
    chatOnOpen()
      .then(() => {
        if (!selectorIsChatOnly(getState())) {
          dispatch(initHelpCenter(zendeskBaseUrl, currentLocale, labelsForSuggestedArticles));
        }
        dispatch({ type: SET_DIALOG_OPEN_STATE, state: CHAT_OPEN });
        dispatch(markAllMessagesAsRead());
        dispatch(hideRecentMessage());
        // HACK: hide rest of app to prevent scrolling issues. (no easy way to read dialogIsOpen state in "StandaloneApp")
        // multiple-markets
        const appEl = document.querySelector('#cancellation-portal-page');
        //@ts-ignore
        appEl.className += ' hidden-on-mobile';
      })
      .catch((error: AxiosError) => Sentry.captureExceptionWithMessage(error, 'Failed to open zendesk'));
  };

export const minimize = (keepChatRoute = true) => {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  chatOnMinimize();

  return closeOrMinimize(keepChatRoute, true);
};

export const close = (keepChatRoute = true) => {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  chatOnClose();

  return closeOrMinimize(keepChatRoute, false);
};

/**
 * @param keepChatRoute - if false, we'll navigate away from the chat route
 * @param mini - true if we should minimize, false if we should close
 */
const closeOrMinimize = (keepChatRoute: boolean, mini: boolean) => (dispatch: Dispatch) => {
  const openState = mini ? CHAT_MINIMIZED : CHAT_CLOSED;
  dispatch({ type: SET_DIALOG_OPEN_STATE, state: openState });
  dispatch(clearUserSearchQuery());
  dispatch({ type: RESET_NAVIGATION_ON_CLOSE, keepChatRoute });
  // HACK: show rest of app. (no easy way to read dialogIsOpen state in "StandaloneApp")
  // multiple-markets
  const appEl = document.querySelector('#cancellation-portal-page');
  //@ts-ignore
  appEl.className = appEl.className.replace(/ hidden-on-mobile/g, '');
};

export const navigateToContactUs = (): any => ({ type: PUSH_ROUTE, route: { route: CONTACT_US_ROUTE } });
export const navigateToCallUs = () => ({ type: PUSH_ROUTE, route: { route: CALL_US_ROUTE } });
export const navigateToEmailUs = () => ({ type: PUSH_ROUTE, route: { route: EMAIL_US_ROUTE } });

export const navigateToCategory = (categoryId: number) => ({
  type: PUSH_ROUTE,
  route: { route: CATEGORY_ROUTE, parameters: { categoryId } },
});

export const navigateBack = () => ({ type: POP_ROUTE });
export const navigateToUserSearchResult = () => ({ type: PUSH_ROUTE, route: { route: USER_SEARCH_RESULT_ROUTE } });

export const navigateToArticle = (sectionId: number, articleId: number) => ({
  type: PUSH_ROUTE,
  route: { route: ARTICLE_DETAILS_ROUTE, parameters: { sectionId, articleId } },
});

export const navigateToSection = (categoryId: number, sectionId: number) => ({
  type: PUSH_ROUTE,
  route: { route: SECTION_ROUTE, parameters: { categoryId, sectionId } },
});

export const navigateToChat = () => ({ type: PUSH_ROUTE, route: { route: CHAT_ROUTE } });

export const saveCallBackRequestData =
  (requestData: any): any =>
  (dispatch: Dispatch) => {
    dispatch({ type: SAVE_CALL_BACK_REQUEST_DATA, requestData: requestData });
  };
