// NOTE: a proper promisify would be able to take arguments. However, that is hard
// to type and we have no need of it currently
export async function promisify(func: any) {
  return new Promise((resolve, reject) => {
    func((err: any, value: any) => {
      if (err) reject(err);
      else resolve(value);
    });
  });
}
