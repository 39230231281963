import { selectorLocaleFetchable, selectorMessagesFetchable } from '@cancellation-portal/internationalization/duck';
import { LoadingState } from '@cancellation-portal/models/LoadingState';
import { ErrorLoading } from '@cancellation-portal/modules/fetching/ErrorPage/ErrorLoading';
import { LoadingPage } from '@cancellation-portal/modules/fetching/LoadingPage';
import { useMountEffect } from '@cancellation-portal/utils/hooks/use-mount-effect';
import moment from 'moment';
import type { FC } from 'react';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { extractSuccess, fetchableIsLoading } from '../models/Fetchable';
import { AppContextActions, selectorAppContext } from '../modules/app-context/duck';

export const LocalizationProvider: FC = ({ children }) => {
  const dispatch = useDispatch();

  const fetchableLocale = useSelector(selectorLocaleFetchable);
  const fetchableMessages = useSelector(selectorMessagesFetchable);
  const initialLocale = useSelector(selectorAppContext).locale;

  const formats = {
    date: {
      dayAndMonth: { day: 'numeric', month: 'long' },
      dayAndShortMonth: { day: 'numeric', month: 'short' },
      long: { day: 'numeric', month: 'long', year: 'numeric' },
      monthAndYear: { month: 'short', year: 'numeric' },
      shortMonth: { month: 'short' },
      shortMonthAndShortYear: { month: 'short', year: '2-digit' },
      fullYear: { year: 'numeric' },
    },
  };
  const textComponent = 'span';
  const locale = extractSuccess(fetchableLocale);
  const messages = extractSuccess(fetchableMessages);
  const key = Math.random(); //A key needs to be passed to IntlProvider so the context (for messages) refreshes. https://github.com/yahoo/react-intl/issues/1097#issuecomment-363114792

  const isLoading = fetchableIsLoading(fetchableLocale) || fetchableIsLoading(fetchableMessages);
  const showError = fetchableLocale.type === LoadingState.Failure || fetchableMessages.type === LoadingState.Failure;

  useMountEffect(() => {
    // Prevent unnecessary re-rendering
    if (locale !== initialLocale) {
      dispatch(AppContextActions.setLocale(locale ?? initialLocale));
      moment.locale(locale ?? initialLocale);
    }
  });

  return (
    <>
      {isLoading && !showError && <LoadingPage />}
      {showError && <ErrorLoading />}
      {!isLoading && !showError && (
        // @ts-ignore
        <IntlProvider locale={locale} formats={formats} messages={messages} key={key} textComponent={textComponent}>
          {children}
        </IntlProvider>
      )}
    </>
  );
};
