import { LocalizedMessage } from '@cancellation-portal/internationalization';
import { ReportProblemIcon } from '@cancellation-portal/modules/shared-components/icons/ReportProblemIcon';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import { CheckCircleIcon } from '@minna-technologies/minna-ui/icons/CheckCircle';
import type { FC } from 'react';
import React from 'react';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    maxWidth: '1000px',
    margin: 'auto',
  },
  stepper: {
    backgroundColor: 'transparent',
  },
}));

interface Props {
  finished: boolean;
  marketHasSigning: boolean;
}

export const LoaStepper: FC<Props> = ({ finished, marketHasSigning }) => {
  const { colors } = useTheme();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Stepper activeStep={3} className={classes.stepper}>
        <Step>
          <StepLabel icon={<CheckCircleIcon nativeColor={colors.action.primary} bold />}>
            <LocalizedMessage id="terminationRequested" />
          </StepLabel>
        </Step>
        {marketHasSigning && (
          <Step>
            <StepLabel icon={<CheckCircleIcon nativeColor={colors.action.primary} bold />}>
              <LocalizedMessage id="loaSigned" />
            </StepLabel>
          </Step>
        )}
        <Step>
          <StepLabel icon={<CheckCircleIcon nativeColor={colors.action.primary} bold />}>
            <LocalizedMessage id="terminationSent" />
          </StepLabel>
        </Step>
        <Step>
          <StepLabel
            icon={
              finished ? (
                <CheckCircleIcon nativeColor={colors.action.primary} bold />
              ) : (
                <ReportProblemIcon nativeColor={'rgb(220, 83, 0)'} />
              )
            }
          >
            {finished ? <LocalizedMessage id="confirmed" /> : <LocalizedMessage id="waitingForConfirmation" />}
          </StepLabel>
        </Step>
      </Stepper>
    </div>
  );
};
