import i18n from 'i18next';
import { initReactI18next } from 'react-i18next/initReactI18next';
import { getOptions } from './settings';
import ICU from 'i18next-icu';

/*
 * Initialize i18next with the options from settings.ts.
 * In order to change the language, you can use the i18n.changeLanguage() method.
 * Example: i18n.changeLanguage('es-ES');
 *
 * This change will update the language and we can do this on page load or when the user changes the language in the settings.
 * */
// @ts-ignore
i18n.use(ICU).use(initReactI18next).init(getOptions());
/* eslint-disable import/no-default-export */
export default i18n;
