import verifiedByBankImage from '@cancellation-portal/assets/images/cancellation-portal/verified-by-bank.svg';
import { LocalizedMessage } from '@cancellation-portal/internationalization';
import { Subheading1 } from '@minna-technologies/minna-ui/components/Typography/Subheading1';
import type { FC } from 'react';
import React from 'react';
import styles from './styles.scss';

interface Props {
  bank?: 'Lloyds';
}

export const BankVerified: FC<Props> = () => {
  return (
    <div className={styles['paper-container']}>
      <Subheading1>
        <LocalizedMessage id="documentStatus" />
      </Subheading1>
      <div className={styles.container}>
        <img src={verifiedByBankImage} alt="verified" className={styles.verifiedByBank} />
      </div>
    </div>
  );
};
