import responsiveHelpers from '@cancellation-portal/styles/responsive-helpers.css';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import StableFullDeviceHeight from '../StableFullDeviceHeight';
import styles from './styles.scss';

const FullscreenContainerView = ({ isOpen, children, name }: any) => {
  return (
    <div data-test={name}>
      <StableFullDeviceHeight
        className={classNames(
          responsiveHelpers['visible-mobile-chat'],
          styles['fullscreen-container'],
          !isOpen && styles.hidden
        )}
        heightProperty="height"
      >
        {children}
      </StableFullDeviceHeight>
    </div>
  );
};

FullscreenContainerView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  name: PropTypes.string,
  children: PropTypes.node,
};

// eslint-disable-next-line import/no-default-export
export default FullscreenContainerView;
