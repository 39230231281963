import { promisify } from '@cancellation-portal/utils/promise';
import debounce from 'lodash/debounce';
import type { Dispatch } from 'redux';
import type { GetState } from 'redux-pack';
//@ts-ignore
import zChat from '../../../../../../../../vendor/web_sdk';
import { SET_VISITOR_IS_TYPING } from './zendesk-chat-event-types';
import { selectorVisitorIsTyping } from './zendesk-chat-selectors';
import { logErrors } from './zendesk-chat-utils';

const STOPPED_TYPING_DELAY_MS = 3000;
/**
 * Sends a "Stopped typing" event to Zendesk after x seconds.
 * If invoked again before x seconds has passed, will delay the event further.
 */
const visitorStoppedTypingDebounced = debounce((dispatch) => {
  const sendTyping = zChat.sendTyping.bind(zChat, false);
  promisify(sendTyping).catch((err: any) => logErrors(err, 'sendTyping', false)); // TODO: show error message in GUI
  dispatch({ type: SET_VISITOR_IS_TYPING, typing: false });
}, STOPPED_TYPING_DELAY_MS);

export const ZendeskChatVisitorIsTypingEvent = () => (dispatch: Dispatch, getState: GetState<any>) => {
  if (!selectorVisitorIsTyping(getState())) {
    const sendTyping = zChat.sendTyping.bind(zChat, true);
    promisify(sendTyping).catch((err: any) => logErrors(err, 'sendTyping', true)); // TODO: show error message in GUI
    dispatch({ type: SET_VISITOR_IS_TYPING, typing: true });
  }
  visitorStoppedTypingDebounced(dispatch);
};
