import responsiveHelpers from '@cancellation-portal/styles/responsive-helpers.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.scss';

/**
 * Expandable/contractable view that sticks to the bottom-right of user's screen.
 * (I.e. how chat conversations typically behave)
 */
const StickyContainerView = ({ isOpen, children, name }: any) => {
  return (
    <div
      className={classNames(
        responsiveHelpers['hidden-mobile-chat'],
        styles['sticky-container'],
        !isOpen && styles.hidden
      )}
      data-test={name}
    >
      {children}
    </div>
  );
};

StickyContainerView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node,
  name: PropTypes.string,
};

// eslint-disable-next-line import/no-default-export
export default StickyContainerView;
