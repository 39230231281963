/* eslint-disable import/no-unassigned-import */
// Import polyfill first - https://github.com/facebook/react/issues/8379#issuecomment-309916013
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { Sentry } from '@cancellation-portal/assets/js/sentry';
import '@cancellation-portal/locale';
import { setAppIntegrationImplementation } from '@cancellation-portal/modules/app-integration';
import { defaultWebBrowserNativeImplementation } from '@cancellation-portal/modules/app-integration/default-web-browser-native-app-integration';
import { getMixpanelKey } from '@cancellation-portal/tracking/mixpanel';
import { createBrowserHistory } from 'history';
import 'intl';
// @ts-ignore js import, remove this when the import is typed
import mixpanel from 'mixpanel-browser';
import React from 'react';
import ReactDOM from 'react-dom';
import { CancellationPortalApp } from './CancellationPortalApp';
import './style.scss';

/**
 * Returns the correct mixpanel domain. When we are in US we should send requests to "normal" mixpanel, which have servers in US
 * When we are in EU we should send request to eu mixpanel.
 * We default to EU to be fully backward compatible, since we only had EU before.
 */
export const getMixpanelDomain = (hostname: string): string => {
  const isUsRegion = hostname.includes('.us.') || hostname.includes('-us-');
  if (isUsRegion) {
    return 'api.mixpanel.com';
  } else return 'api-eu.mixpanel.com';
};

const mixPanelKey = getMixpanelKey(window.location.hostname);
if (mixPanelKey) {
  mixpanel.init(mixPanelKey, {
    api_host: `https://${getMixpanelDomain(window.location.hostname)}`,
  });
  //@ts-ignore
  window.mixpanel = mixpanel;
}

const history = createBrowserHistory();

Sentry.setGlobalTag('application', 'cancellation-portal');

setAppIntegrationImplementation(defaultWebBrowserNativeImplementation(history));

ReactDOM.render(<CancellationPortalApp history={history} />, document.getElementById('root'));
