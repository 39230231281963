import forEach from 'lodash/forEach';

export enum AppIntegrationType {
  MOBILE_APPLICATION,
  WEB_BROWSER,
}

export enum AppPlatform {
  ANDROID_WEBVIEW,
  IOS_WEBVIEW,
  WEB_BROWSER_NATIVE,
  WEB_BROWSER_IFRAME,
}

/**
 * Integration with surrounding application. For example web browser or mobile application.
 *
 * TODO: As of 2019-01-08 this design is very early stage. Maybe it should have completely different methods and enums.
 */
export interface AppIntegrationImplementation {
  getAppIntegrationType(): AppIntegrationType;

  getAppPlatform(): AppPlatform;

  /**
   * @returns true if the application could be closed.
   */
  closeApplication(): boolean;

  closeApplicationOrGoTo(url: string): void;

  /**
   * @returns true if the link most likely opened, no guarantee that it succeeded.
   */
  openExternalWebPage(url: string, event?: Event): boolean;

  /**
   * @param event TODO: This event format type needs to be thought out to something general that works well inside our app. And possibly in the AppIntegrationImplementation changes it to the banks custom format.
   */
  sendAnalyticsEvent(event: Record<string, unknown>): boolean;
}
// When a new member is added to the interface, add it to this array as well
const interfaceMembers = [
  'getAppIntegrationType',
  'getAppPlatform',
  'closeApplication',
  'closeApplicationOrGoTo',
  'openExternalWebPage',
  'sendAnalyticsEvent',
];

let currentImplementation: AppIntegrationImplementation | undefined;

// A real ES6 Proxy can't be used since it is not supported by all targets
// @ts-ignore
const proxy: AppIntegrationImplementation = {};

function getProperty(property: PropertyKey): () => any {
  return (...args) => {
    if (!currentImplementation) {
      throw new Error('App integration implementation is not specified.');
    }

    // @ts-ignore The type is correct as long as the proxy is called from TypeScript
    return currentImplementation[property](...args);
  };
}

forEach(interfaceMembers, (propertyName) => {
  // @ts-ignore
  proxy[propertyName] = getProperty(propertyName);
});

export const AppIntegration: AppIntegrationImplementation = proxy;

export function setAppIntegrationImplementation(implementation: AppIntegrationImplementation) {
  currentImplementation = implementation;
}
