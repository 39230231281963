import { LABEL_FAQ_GENERAL } from '@cancellation-portal/zendesk';
import axios from 'axios';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import type { Dispatch } from 'redux';
import type { GetState } from 'redux-pack';
import {
  zendeskArticleEndpoint,
  zendeskCategoriesEndpoint,
  zendeskSectionsEndpoint,
} from './zendesk-help-center-api-endpoint';
import { fetchZendeskArticlesPromise } from './zendesk-help-center-articles';
import { selectorCategories, selectorFilter } from './zendesk-help-center-selector';

export const FETCH_ARTICLES = 'ZENDESK/HELP_CENTER/FETCH_ARTICLES';
export const STORE_ARTICLES_FILTER = 'ZENDESK/HELP_CENTER/STORE_ARTICLES_FILTER';
export const UPDATE_ARTICLES_USER_SEARCH_QUERY = 'ZENDESK/HELP_CENTER/UPDATE_ARTICLES_USER_SEARCH_QUERY';
export const FETCH_CATEGORIES = 'ZENDESK/HELP_CENTER/FETCH_CATEGORIES';
export const SET_LABELS_FOR_SUGGESTED_ARTICLES = 'ZENDESK/HELP_CENTER/SET_LABELS_FOR_SUGGESTED_ARTICLES';
export const FETCH_ARTICLE = 'ZENDESK/HELP_CENTER/FETCH_ARTICLE';
export const CLEAR_USER_SEARCH_QUERY = 'ZENDESK/HELP_CENTER/CLEAR_USER_SEARCH_QUERY';
export const CLEAR_ARTICLE_STATE = 'ZENDESK/HELP_CENTER/CLEAR_ARTICLE_STATE';
export const INCREASE_NUMBER_OF_ADDITIONAL_ARTICLES_TO_DISPLAY =
  'ZENDESK/HELP_CENTER/INCREASE_NUMBER_OF_ADDITIONAL_ARTICLES_TO_DISPLAY';
export const RESET_ADDITIONAL_NUMBER_OF_ARTICLES_TO_DISPLAY =
  'ZENDESK/HELP_CENTER/RESET_ADDITIONAL_NUMBER_OF_ARTICLES_TO_DISPLAY';

export const initHelpCenter = (
  zendeskBaseUrl: string,
  currentLocale: any,
  labelsForSuggestedArticles = LABEL_FAQ_GENERAL
) => {
  const fetchCategoriesIfNecessary = (dispatch: Dispatch, state: GetState<any>) => {
    const currentCategories = selectorCategories(state);

    if (!currentCategories || currentCategories.length === 0) {
      const fetchCategoriesPromise = axios
        .get(zendeskCategoriesEndpoint(zendeskBaseUrl, currentLocale))
        .then(({ data }) => data.categories);
      const fetchSectionsPromise = async (category: any) =>
        axios
          .get(zendeskSectionsEndpoint(zendeskBaseUrl, currentLocale, category.id))
          .then(({ data }) => ({ category, sections: data.sections }));
      const fetchCategoriesWithSectionsPromise = fetchCategoriesPromise.then(async (categories) =>
        Promise.all(categories.map(fetchSectionsPromise))
      );
      const mapResult = (categoriesWithSections: any) =>
        map(categoriesWithSections, ({ category, sections }) => ({
          id: category.id,
          name: category.name,
          sections: map(sections, ({ id, name }) => ({ id, name })),
        }));
      dispatch({
        type: FETCH_CATEGORIES,
        promise: fetchCategoriesWithSectionsPromise.then(mapResult),
      });
    }
  };

  return (dispatch: Dispatch, getState: GetState<any>) => {
    const state = getState();
    dispatch({
      type: SET_LABELS_FOR_SUGGESTED_ARTICLES,
      labels: labelsForSuggestedArticles.labels,
    });
    fetchCategoriesIfNecessary(dispatch, state);
  };
};

export const ARTICLES_FILTERED_BY_LABELS = 'ARTICLES_FILTERED_BY_LABELS';
export const ARTICLES_FILTERED_BY_USER_SEARCH = 'ARTICLES_FILTERED_BY_USER_SEARCH';
export const ARTICLES_FILTERED_BY_CATEGORY = 'ARTICLES_FILTERED_BY_CATEGORY';
export const ARTICLES_FILTERED_BY_SECTION = 'ARTICLES_FILTERED_BY_SECTION';

const mapDataFromAxiosResponse = ({ data }: any) => data;

const searchArticlesByFilter =
  (zendeskBaseUrl: string, currentLocale: any, filter: any) => (dispatch: Dispatch, getState: GetState<any>) => {
    const filterUpdated = !isEqual(filter, selectorFilter(getState()));
    if (filterUpdated) {
      dispatch(storeFilter(filter));
      dispatch({
        type: FETCH_ARTICLES,
        promise: fetchZendeskArticlesPromise(zendeskBaseUrl, currentLocale, filter),
      });
    }
  };

export const updateArticlesUserSearchQuery = (userSearchQuery: any) => ({
  type: UPDATE_ARTICLES_USER_SEARCH_QUERY,
  userSearchQuery,
});

export const filterArticlesByQuery =
  (zendeskBaseUrl: string, currentLocale: any, userSearchQuery: any) => (dispatch: any) => {
    const filter = {
      filterType: ARTICLES_FILTERED_BY_USER_SEARCH,
      userSearchQuery,
    };
    dispatch(searchArticlesByFilter(zendeskBaseUrl, currentLocale, filter));
  };

export const filterArticlesByCategory =
  (zendeskBaseUrl: string, currentLocale: any, categoryId: any) => (dispatch: any) => {
    const filter = { filterType: ARTICLES_FILTERED_BY_CATEGORY, categoryId };
    dispatch(searchArticlesByFilter(zendeskBaseUrl, currentLocale, filter));
  };

export const filterArticlesByLabels = (zendeskBaseUrl: string, currentLocale: any, labels: any) => (dispatch: any) => {
  const filter = { filterType: ARTICLES_FILTERED_BY_LABELS, labels: labels };
  dispatch(searchArticlesByFilter(zendeskBaseUrl, currentLocale, filter));
};

export const filterArticlesBySection =
  (zendeskBaseUrl: string, currentLocale: any, sectionId: any) => (dispatch: any) => {
    const filter = { filterType: ARTICLES_FILTERED_BY_SECTION, sectionId };
    dispatch(searchArticlesByFilter(zendeskBaseUrl, currentLocale, filter));
  };

export const fetchArticle = (zendeskBaseUrl: string, currentLocale: any, articleId: any) => (dispatch: Dispatch) => {
  const articlePromise = axios
    .get(zendeskArticleEndpoint(zendeskBaseUrl, currentLocale, articleId))
    .then(mapDataFromAxiosResponse);
  dispatch({ type: FETCH_ARTICLE, promise: articlePromise });
};

export const storeFilter = (filter: any) => ({ type: STORE_ARTICLES_FILTER, filter });
export const clearUserSearchQuery = () => ({ type: CLEAR_USER_SEARCH_QUERY });
export const clearZendeskArticleState = () => ({ type: CLEAR_ARTICLE_STATE });
export const increaseNumberOfAdditionalArticlesToDisplay = (amount: number) => ({
  type: INCREASE_NUMBER_OF_ADDITIONAL_ARTICLES_TO_DISPLAY,
  amount,
});
export const resetNumberOfAdditionalArticlesToDisplay = () => ({
  type: RESET_ADDITIONAL_NUMBER_OF_ARTICLES_TO_DISPLAY,
});
