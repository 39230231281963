import type { OffsetDateTime } from '@cancellation-portal/models/OffsetDateTime';

export interface PersonName {
  first: string;
  last: string;
}

export type IpAddress = string;

export interface Signee {
  type: SigneeType;
  name: PersonName;
  ipAddress?: IpAddress;
}

export enum SigneeType {
  BankIdSignee = 'BankIdSignee',
  ApiSignee = 'ApiSignee',
  DefaultMarketSignee = 'DefaultMarketSignee',
  NemIdSignee = 'NemIdSignee',
}

export interface LoaInformation {
  text: string;
  signee: Signee;
  signingStartedAt: OffsetDateTime;
  signedAt: OffsetDateTime;
}
