import checkMarkImage from '@cancellation-portal/assets/images/icons/checkmark-circle-green.svg';
import { AuthenticationMethod } from '@cancellation-portal/components/AuthenticationMethod';
import { LocalizedMessage } from '@cancellation-portal/internationalization';
import { parseDate, parseTime } from '@cancellation-portal/utils/date';
import useTheme from '@material-ui/core/styles/useTheme';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Subheading1 } from '@minna-technologies/minna-ui/components/Typography/Subheading1';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import React from 'react';
import styles from './styles.scss';

export const DocumentStatus: FC<DocumentStatusProps> = ({ userSignedLoaAt, supplierLanguage }, { localizeDate }) => {
  const { colors } = useTheme();

  return (
    <div className={styles['paper-container']}>
      <Subheading1>
        <LocalizedMessage id="documentStatus" />
      </Subheading1>

      <div className={styles.badges}>
        <div className={styles.container}>
          <img src={checkMarkImage} alt="check-mark" className={styles.checkMark} />
          <Body>
            <LocalizedMessage id="signed" />
          </Body>
          <Body color={colors.textOn.surfaceSubdued}>{`${localizeDate(parseDate(userSignedLoaAt))} ${parseTime(
            userSignedLoaAt
          )}`}</Body>
        </div>
        <AuthenticationMethod supplierLanguage={supplierLanguage} />
      </div>
    </div>
  );
};

interface DocumentStatusProps {
  userSignedLoaAt: string;
  supplierLanguage: string;
}

DocumentStatus.contextTypes = {
  localizeDate: PropTypes.func.isRequired,
};
