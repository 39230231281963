import { Agent } from '@cancellation-portal/components/Agent';
import { CompanyInfo } from '@cancellation-portal/components/CompanyInfo';
import { ExpandablePaper } from '@cancellation-portal/components/ExpandablePaper';
import { SupplierInfo } from '@cancellation-portal/components/MerchantInfo';
import { SigneeInfo } from '@cancellation-portal/components/SigneeInfo';
import { LocalizedMessage } from '@cancellation-portal/internationalization';
import type { SupplierTerminationOverview } from '@cancellation-portal/models/SupplierTerminationOverview';
import { platformNameToBankLegalName, platformNameToBankRegNumber } from '@cancellation-portal/utils';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import get from 'lodash/get';
import type { FC } from 'react';
import React from 'react';
import styles from './styles.scss';

interface Props {
  overviewInformation: SupplierTerminationOverview;
  showPersonalNumber: boolean;
  useAgencyAgreementCopy: boolean;
  verifiedByBank: boolean;
}

export const Parties: FC<Props> = ({
  overviewInformation,
  showPersonalNumber,
  useAgencyAgreementCopy,
  verifiedByBank,
}) => {
  return (
    <>
      {(useAgencyAgreementCopy && (
        <ExpandablePaper
          header={<LocalizedMessage id="parties" />}
          className={styles['paper-outer-margin']}
          contentClassName={styles['paper-inner-spacing-sides']}
          initiallyOpen
        >
          <Agent
            agentName={platformNameToBankLegalName(overviewInformation.termination.marketPlatform?.platform?.name)}
            agentRegNumber={platformNameToBankRegNumber(overviewInformation.termination.marketPlatform?.platform?.name)}
          />
          <Divider />
          <CompanyInfo terminationEmailAddress={overviewInformation.termination.emailAddress} titleId="subagent" />
          <Divider />
          <SigneeInfo
            user={overviewInformation.user}
            userSignedLoaAt={get(overviewInformation, 'loa.signedAt')}
            showPersonalNumber={showPersonalNumber}
            userSignedLoaFromIpAddress={get(overviewInformation, 'loa.signee.ipAddress')}
            showEmail
            showIpAddress
            titleId="signedPartyPrincipal"
          />
          <Divider />
          <SupplierInfo supplier={overviewInformation.supplier} />
        </ExpandablePaper>
      )) ||
        (verifiedByBank && (
          <ExpandablePaper
            header={<LocalizedMessage id="parties" />}
            className={styles['paper-outer-margin']}
            contentClassName={styles['paper-inner-spacing-sides']}
            initiallyOpen
          >
            <CompanyInfo
              terminationEmailAddress={overviewInformation.termination.emailAddress}
              titleId="plenipotentiary"
            />
            <Divider />
            <SigneeInfo
              user={overviewInformation.user}
              userSignedLoaAt={get(overviewInformation, 'loa.signedAt')}
              showPersonalNumber={showPersonalNumber}
              showEmail={false}
              userSignedLoaFromIpAddress={get(overviewInformation, 'loa.signee.ipAddress')}
              showIpAddress
              titleId="signedParty"
            />
            <Divider />
            <SupplierInfo supplier={overviewInformation.supplier} />
          </ExpandablePaper>
        )) || (
          <ExpandablePaper
            header={<LocalizedMessage id="parties" />}
            className={styles['paper-outer-margin']}
            contentClassName={styles['paper-inner-spacing-sides']}
            initiallyOpen
          >
            <CompanyInfo
              terminationEmailAddress={overviewInformation.termination.emailAddress}
              titleId="plenipotentiary"
            />
            <Divider />
            <SigneeInfo
              user={overviewInformation.user}
              userSignedLoaAt={get(overviewInformation, 'loa.signedAt')}
              showPersonalNumber={showPersonalNumber}
              showEmail={false}
              showIpAddress={false}
              titleId="signedParty"
            />
            <Divider />
            <SupplierInfo supplier={overviewInformation.supplier} />
          </ExpandablePaper>
        )}
    </>
  );
};
