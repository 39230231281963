import { Locale, Market } from '@cancellation-portal/modules/app-context/constants';

export function getDefaultLocale(market: Market) {
  switch (market) {
    case Market.Sweden:
      return Locale.svSE;
    case Market.Denmark:
      return Locale.daDK;
    case Market.Finland:
      return Locale.fiFI;
    case Market.Belgium:
      return Locale.enGB;
    case Market.UnitedKingdom:
      return Locale.enGB;
    case Market.Norway:
      // TODO: Default Locale should be changed to NO?
      return Locale.enGB;
    case Market.Slovakia:
      return Locale.enGB;
    case Market.Spain:
      return Locale.esES;
    case Market.UnitedStates:
      return Locale.enUS;

    default:
      return Locale.svSE;
  }
}
