import isString from 'lodash/isString';

export const emailRegexp = /^\S+@\S+\.\S{2,}$/i;

export const isValidEmail = (string = '') => {
  if (!isString(string)) return false;

  return emailRegexp.test(string);
};

export const emailValidation = (value: any, errorMessage?: string): boolean | string | undefined =>
  !value || isValidEmail(value) ? true : errorMessage;
