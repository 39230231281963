import type { SupplierTerminationOverview } from '@cancellation-portal/models/SupplierTerminationOverview';
import { getMixpanel } from '@cancellation-portal/tracking/mixpanel';
import { EventNames } from '@cancellation-portal/tracking/mixpanel-constants';
import { formatToIsoDate } from '@cancellation-portal/utils/date';
import get from 'lodash/get';

export function finishedCancellationInCancellationPortalMixpanelEvent(
  overview: SupplierTerminationOverview,
  form: any,
  token: any,
  ip: string,
  userPlatform: string
) {
  const terminationDate = form.contractEndsAt ? formatToIsoDate(form.contractEndsAt) : undefined;
  const contractBindingPeriod = get(form, 'reason.bindingPeriodEndsAt')
    ? formatToIsoDate(get(form, 'reason.bindingPeriodEndsAt'))
    : undefined;
  getMixpanel().track(EventNames.SUBMITTED_FORM, {
    'Supplier name': get(overview, 'supplier.name'),
    Category: get(overview, 'termination.terminatingCategoryName'),
    'Supplier termination decision': form.type,
    'Cancellation declined reason': get(form, 'reason.name'),
    'Cancel date': terminationDate,
    'Cancellation binding period': contractBindingPeriod,
    Page: 'Cancel Portal',
    Token: token,
    IP: ip,
    'User platform': userPlatform,
  });
}

export function viewedCancellationPageMixpanelEvent(
  serviceProviderName: string,
  token: any,
  ip: string,
  userPlatform: string
) {
  getMixpanel().track(EventNames.VIEWED_PAGE, {
    'Supplier name': serviceProviderName,
    Token: token,
    IP: ip,
    Page: 'Cancel Portal',
    'User platform': userPlatform,
  });
}
