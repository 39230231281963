import type { BaseIconProps } from '@cancellation-portal/modules/shared-components/icons/BaseIconProps';
import type { FC } from 'react';
import React from 'react';

export const PrintIcon: FC<BaseIconProps> = (props) => {
  return (
    // eslint-disable-next-line
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" {...props}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        d="M19 8H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zm-3 11H8v-5h8v5zm3-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-1-9H6v4h12V3z"
        fill={props.nativeColor}
      />
    </svg>
  );
};
