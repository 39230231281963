import { reduxMiddleware as sentryReduxMiddleware } from '@cancellation-portal/assets/js/sentry';
import { CHAT_ONLY, defaultState as zendeskDefaultState } from '@cancellation-portal/modules/Zendesk/ducks/zendesk';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { middleware as reduxPackMiddleware } from 'redux-pack';
import promiseMiddleware from 'redux-promise-middleware';
import thunkMiddleware from 'redux-thunk';
import { rootReducer } from './ducks';

const composeEnhancers = composeWithDevTools({});
const defaultState = {
  zendesk: {
    ...zendeskDefaultState,
    featureMode: CHAT_ONLY,
  },
};

export const store = createStore(
  rootReducer,
  defaultState,
  composeEnhancers(applyMiddleware(sentryReduxMiddleware(), thunkMiddleware, reduxPackMiddleware, promiseMiddleware()))
);
