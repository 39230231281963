import type { EffectCallback } from 'react';
import { useEffect } from 'react';

/**
 * Like useEffect, but run only once on first mount. This function is equivalent to
 * `useEffect(effect, [])`. It exists so that you do not have to ignore ESLint warnings
 * in many places in our code base.
 *
 * Do note that using `useEffect` with proper dependencies is very much preferred where
 * that is the behavior you want.
 *
 * @param effect The effect to run
 */
export function useMountEffect(effect: EffectCallback): void {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, []);
}
