import { AppType } from '@cancellation-portal/modules/app-context/constants';
import { selectorAppContext } from '@cancellation-portal/modules/app-context/duck';
import { LoadingPage } from '@cancellation-portal/modules/fetching/LoadingPage';
import { navigateToContactUs } from '@cancellation-portal/modules/Zendesk/ducks';
import { ArticlesResult } from '@cancellation-portal/modules/Zendesk/ZendeskHelpCenter/components/ArticlesResult';
import { ContactUsButton } from '@cancellation-portal/modules/Zendesk/ZendeskHelpCenter/components/ContactUsButton';
import styles from '@cancellation-portal/modules/Zendesk/ZendeskHelpCenter/components/DisplayArticle/styles.scss';
import { getIngBankContactUrl } from '@cancellation-portal/utils/support';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import type { FC } from 'react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ARTICLES_FILTERED_BY_SECTION,
  clearZendeskArticleState,
  fetchArticle,
  filterArticlesBySection,
  selectorArticle,
  selectorLoadingArticle,
  selectorLoadingArticles,
} from '../../../ducks/zendesk-help-center';

const useStyles = makeStyles((theme) => ({
  articlesDivider: {
    width: '100%',
    margin: theme.spacing(2, 0),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

interface Props {
  articleId: number;
  sectionId: number;
}

export const DisplayArticle: FC<Props> = ({ articleId, sectionId }) => {
  const classes = useStyles();
  const { colors } = useTheme();
  const dispatch = useDispatch();

  const appContext = useSelector(selectorAppContext);
  const article = useSelector(selectorArticle);
  const loadingArticle = useSelector(selectorLoadingArticle);
  const loadingArticles = useSelector(selectorLoadingArticles);

  const handleNavigateToContactUs = () => {
    // TODO: use feature toggle when adding full support for 'contact your bank' in Zendesk
    if (appContext.appType === AppType.ING) {
      window.open(getIngBankContactUrl(appContext.locale), '_blank', 'noopener noreferrer external nofollow');
    } else {
      dispatch(navigateToContactUs());
    }
  };

  useEffect(() => {
    dispatch(fetchArticle(appContext.features.helpCenter.zendeskBaseUrl, appContext.locale, articleId));
    dispatch(filterArticlesBySection(appContext.features.helpCenter.zendeskBaseUrl, appContext.locale, sectionId));
  }, [appContext.features.helpCenter.zendeskBaseUrl, appContext.locale, articleId, dispatch, sectionId]);

  useEffect(() => {
    return function cleanup() {
      dispatch(clearZendeskArticleState());
    };
  }, [dispatch]);

  return !article || loadingArticle || loadingArticles ? (
    <LoadingPage />
  ) : (
    <>
      <Card variant="border">
        <Title className={classes.title}>{article.title}</Title>
        <Body color={colors.textOn.surfaceSubdued} className={styles['margin-top']}>
          <span
            className={styles['display-article__img-container']}
            /* eslint-disable  */
            dangerouslySetInnerHTML={{ __html: article.body }}
          />
        </Body>
      </Card>
      <Divider className={classes.articlesDivider} />
      <div>
        <ContactUsButton onNavigateToContactUs={handleNavigateToContactUs} />
      </div>
      <Divider className={classes.articlesDivider} />
      <ArticlesResult articlesFilteredBy={ARTICLES_FILTERED_BY_SECTION} />
    </>
  );
};
