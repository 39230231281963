import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import typingImageUrl from '../../../../icons/typing.svg';

const useStyles = makeStyles((theme) => ({
  typingImageContainer: {
    background: theme.colors.base.surface,
    height: '32px',
    paddingTop: theme.spacing(0.5),
  },
}));

export const TypingImage = () => {
  const classes = useStyles();

  return (
    <div className={classes.typingImageContainer}>
      <img src={typingImageUrl} alt="typing" />
    </div>
  );
};
