import type { BaseIconProps } from '@cancellation-portal/modules/shared-components/icons/BaseIconProps';
import type { FC } from 'react';
import React from 'react';

export const SendIcon: FC<BaseIconProps> = (props) => {
  return (
    // eslint-disable-next-line
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" {...props}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" fill={props.nativeColor} />
    </svg>
  );
};
