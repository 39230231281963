import { CannotCancelReason } from '@cancellation-portal/components/CannotCancelReason';
import { DatePickerSection } from '@cancellation-portal/components/DatePickerSection';
import {
  merchantCancellationDecisions,
  paymentsDecisions,
} from '@cancellation-portal/components/MerchantCancellationPage/merchantCancellationDecisions';
import type { SupplierInputForm } from '@cancellation-portal/ducks/termination';
import { LocalizedMessage } from '@cancellation-portal/internationalization';
import type { PlatformName } from '@cancellation-portal/models/platform';
import type {
  DecisionType,
  SupplierTerminationDecision,
} from '@cancellation-portal/models/SupplierTerminationDecision';
import { PaymentsDecision, RejectReason } from '@cancellation-portal/models/SupplierTerminationDecision';
import type { CancellationInformation } from '@cancellation-portal/models/SupplierTerminationOverview';
import { Market } from '@cancellation-portal/modules/app-context/constants';
// @ts-ignore js import, remove this when the import is typed
import Form from '@cancellation-portal/modules/shared-components/form-validation/Form';
import { FlagIcon } from '@cancellation-portal/modules/shared-components/icons/FlagIcon';
import { SendIcon } from '@cancellation-portal/modules/shared-components/icons/SendIcon';
import VerticalSpacing from '@cancellation-portal/shared-components/layout/VerticalSpacing';
import { platformNameToBankLegalName } from '@cancellation-portal/utils';
import { formatToIsoDate, parseDate } from '@cancellation-portal/utils/date';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import useTheme from '@material-ui/core/styles/useTheme';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import { TextArea } from '@minna-technologies/minna-ui/components/Inputs/TextArea';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import { Subheading1 } from '@minna-technologies/minna-ui/components/Typography/Subheading1';
import { ChatIcon } from '@minna-technologies/minna-ui/icons/Chat';
import classNames from 'classnames';
import classNamesBind from 'classnames/bind';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isNil from 'lodash/isNil';
import type { FC } from 'react';
import React, { useState } from 'react';
import grid from '../../../../styles/grid.css';
import styles from '../../styles.scss';

const cx = classNamesBind.bind(grid);

interface Props {
  firstName: string;
  lastName: string;
  supplierInputForm: SupplierInputForm;
  isFormSubmitting: boolean;
  market: Market;
  termination: CancellationInformation;
  useAgencyAgreementCopy: boolean;
  platformName: PlatformName;
  onFormChange(property: string, value: any): void;
  onFormSubmit(supplierTerminationDecision: SupplierTerminationDecision): void;
}

export const MerchantQuestion: FC<Props> = ({
  firstName,
  lastName,
  supplierInputForm,
  onFormChange,
  onFormSubmit,
  isFormSubmitting,
  market,
  useAgencyAgreementCopy,
  termination,
  platformName,
}) => {
  const [showErrorChooseTerminationResult, setErrorChooseTerminationResultVisibility] = useState(false);
  const [showErrorChoosePaymentResult, setShowErrorChoosePaymentResultVisibility] = useState(false);
  const [showErrorChooseDeclineReason, setErrorChooseDeclineReasonVisibility] = useState(false);
  const { colors } = useTheme();

  const fullName = `${firstName} ${lastName}`;
  const supplierDecisionProperty = 'type';
  const paymentsDecisionProperty = 'payment';
  const supplierDecisionChosen = get(supplierInputForm, supplierDecisionProperty);
  const paymentsDecisionChosen = get(supplierInputForm, paymentsDecisionProperty);
  const decisionRequiresContractEndsAtDate = includes(
    [merchantCancellationDecisions.terminationApproved.value, merchantCancellationDecisions.alreadyTerminated.value],
    supplierDecisionChosen
  );

  const decisionRequiresLastPaymentDate = includes([paymentsDecisions.yesPayments.value], paymentsDecisionChosen);

  const contractEndsAtLabel =
    supplierDecisionChosen === merchantCancellationDecisions.terminationApproved.value ? (
      <LocalizedMessage id="theSubscriptionWillEndAt" />
    ) : (
      <LocalizedMessage id="theSubscriptionEndedAt" />
    );
  const isTerminationDeclined = supplierDecisionChosen === merchantCancellationDecisions.terminationDeclined.value;
  const selectedReason = supplierInputForm.declinedReason;
  const declineReasonRequiresBindingPeriod = isTerminationDeclined && selectedReason === 'HasBindingPeriod';
  const hasTakenDecision = !isNil(supplierDecisionChosen);
  const supplierMessageRequired =
    merchantCancellationDecisions.alreadyTerminated.value === supplierDecisionChosen ||
    (isTerminationDeclined && selectedReason === RejectReason.OtherReason);
  const supplierMessageHintText = <LocalizedMessage id="messageToUserHintText" values={{ firstName: firstName }} />;
  const hasMarketSpecificLawText = market === Market.Sweden || market === Market.Denmark;
  const isUKMarket = market === Market.UnitedKingdom;
  const isTerminationApproved =
    get(supplierInputForm, supplierDecisionProperty) === merchantCancellationDecisions.terminationApproved.value;

  const onSubmit = () => {
    const reasonChosenOrNotRequired = !isTerminationDeclined || !isNil(selectedReason);
    setErrorChooseTerminationResultVisibility(!hasTakenDecision);
    setErrorChooseDeclineReasonVisibility(!reasonChosenOrNotRequired);
    setShowErrorChoosePaymentResultVisibility(!paymentsDecisionChosen);

    const yesPaymentsWithTerminationApproved =
      supplierDecisionChosen === merchantCancellationDecisions.terminationApproved.value &&
      paymentsDecisionChosen === PaymentsDecision.YesPayments;

    if (
      hasTakenDecision &&
      reasonChosenOrNotRequired &&
      (yesPaymentsWithTerminationApproved || paymentsDecisionChosen === PaymentsDecision.NoPayments)
    ) {
      let form;
      if (yesPaymentsWithTerminationApproved) {
        form = {
          type: merchantCancellationDecisions.terminatedWithPendingPayments.value,
          messageToUser: supplierInputForm.messageToUser,
          paymentsDecision: supplierInputForm.payment,
          lastPaymentAt: formatToIsoDate(supplierInputForm.lastPaymentAt as string),
        };
      } else {
        form = {
          type: supplierInputForm.type,
          messageToUser: supplierInputForm.messageToUser,
          paymentsDecision: supplierInputForm.payment,
        };
      }

      if (decisionRequiresContractEndsAtDate) {
        onFormSubmit(
          // @ts-ignore
          {
            ...form,
            contractEndsAt: formatToIsoDate(supplierInputForm.contractEndsAt as string),
          }
        );
      } else if (isTerminationDeclined && declineReasonRequiresBindingPeriod) {
        onFormSubmit(
          // @ts-ignore
          {
            ...form,
            reason: {
              name: supplierInputForm.declinedReason,
              bindingPeriodEndsAt: formatToIsoDate(supplierInputForm.bindingPeriodEndsAt as string),
            },
          }
        );
      } else if (isTerminationDeclined) {
        onFormSubmit(
          // @ts-ignore
          {
            ...form,
            reason: { name: supplierInputForm.declinedReason },
          }
        );
      } else {
        // @ts-ignore
        onFormSubmit(form);
      }
    }
  };
  const decisionRadioButton = (decisionType: { text: JSX.Element; value: DecisionType | PaymentsDecision }) => {
    return (
      <FormControlLabel
        value={decisionType.value}
        control={<Radio />}
        label={decisionType.text}
        data-test={`decision-${decisionType.value}`}
      />
    );
  };

  const descriptionText = (useAgencyAgreementCopy && (
    <LocalizedMessage
      id="merchantAgencyAgreementDescriptionText2"
      values={{ fullName: fullName, bankLegalName: platformNameToBankLegalName(platformName) }}
    />
  )) || <LocalizedMessage id="supplierLoaDescriptionText" values={{ fullName: fullName }} />;

  return (
    <div className={cx('row')}>
      <Subheading1 color={colors.action.primary} className={cx('col-xs-12')}>
        <LocalizedMessage id="confirmTermination" />
      </Subheading1>
      <VerticalSpacing sizeWithUnit="16px" />
      <Body className={cx('col-xs-12')}>{descriptionText}</Body>
      <VerticalSpacing sizeWithUnit="8px" />
      <div className={cx('col-xs-12')}>
        <Form onValidSubmit={onSubmit}>
          <div className={cx('row')} data-test="cancellation-form">
            <FlagIcon nativeColor={colors.textOn.surfaceSubdued} className={cx('col-xs-1')} />
            <Caption color={colors.textOn.surfaceSubdued} className={classNames(cx('col-xs-4'), styles['form-label'])}>
              <LocalizedMessage id="theSubscription" />
            </Caption>
            <div className={cx('col-xs-7')}>
              {showErrorChooseTerminationResult ? <ErrorMessageRadioButtonNotSelected /> : null}
              <RadioGroup
                name={supplierDecisionProperty}
                onChange={(_, property) => {
                  setErrorChooseTerminationResultVisibility(false);
                  onFormChange(supplierDecisionProperty, property);
                }}
                className={styles['radio-button-group']}
                value={get(supplierInputForm, supplierDecisionProperty)}
              >
                {decisionRadioButton(merchantCancellationDecisions.terminationApproved)}
                {decisionRadioButton(merchantCancellationDecisions.alreadyTerminated)}
                {decisionRadioButton(merchantCancellationDecisions.terminationDeclined)}
              </RadioGroup>
            </div>
          </div>
          {decisionRequiresContractEndsAtDate ? (
            <DatePickerSection
              minDate={
                termination.minimumCancellationDate && parseDate(termination.minimumCancellationDate).toDateString()
              }
              label={contractEndsAtLabel}
              onChange={(date) => onFormChange('contractEndsAt', date)}
              value={termination.desiredTerminationAt || supplierInputForm.contractEndsAt}
            />
          ) : null}
          {isTerminationDeclined ? (
            <CannotCancelReason
              firstName={firstName}
              selectedReason={selectedReason}
              onFormChange={onFormChange}
              showErrorChooseDeclineReason={showErrorChooseDeclineReason}
              setErrorChooseDeclineReasonVisibility={setErrorChooseDeclineReasonVisibility}
            />
          ) : null}
          {declineReasonRequiresBindingPeriod ? (
            <DatePickerSection
              label={<LocalizedMessage id="subscriptionBindingPeriodUntil" />}
              onChange={(date) => onFormChange('bindingPeriodEndsAt', date)}
              value={supplierInputForm.bindingPeriodEndsAt}
            />
          ) : null}
          <VerticalSpacing sizeWithUnit="16px" />
          {!isUKMarket && hasTakenDecision && isTerminationApproved ? (
            <>
              <div className={cx('row')} data-test="cancellation-form">
                <SendIcon nativeColor={colors.textOn.surfaceSubdued} className={cx('col-xs-1')} />
                <Caption
                  color={colors.textOn.surfaceSubdued}
                  className={classNames(cx('col-xs-4'), styles['form-label'])}
                >
                  <LocalizedMessage id="paymentsRemaining" />
                </Caption>
                <div className={cx('col-xs-7')}>
                  {showErrorChoosePaymentResult ? (
                    <Caption style={{ color: 'red', marginTop: 0 }}>
                      <LocalizedMessage id="requiredPaymentOption" />
                    </Caption>
                  ) : null}
                  <RadioGroup
                    name={paymentsDecisionProperty}
                    onChange={(_, property) => {
                      onFormChange(paymentsDecisionProperty, property);
                    }}
                    className={styles['radio-button-group']}
                    value={get(supplierInputForm, paymentsDecisionProperty)}
                    defaultValue={paymentsDecisions.noPayments.value}
                  >
                    {decisionRadioButton(paymentsDecisions.noPayments)}
                    {decisionRadioButton(paymentsDecisions.yesPayments)}
                  </RadioGroup>
                </div>
              </div>
            </>
          ) : null}
          {hasTakenDecision && isTerminationApproved && decisionRequiresLastPaymentDate ? (
            <>
              <DatePickerSection
                label={<LocalizedMessage id="lastPaymentDate" />}
                onChange={(date) => onFormChange('lastPaymentAt', date)}
                value={supplierInputForm.lastPaymentAt}
              />
            </>
          ) : null}

          {hasTakenDecision ? (
            <div className={classNames(cx('row'), styles['margin-top-16'])}>
              <ChatIcon nativeColor={colors.textOn.surfaceSubdued} className={cx('col-xs-1')} />
              <div className={cx('col-xs-4')}>
                <Caption color={colors.textOn.surfaceSubdued} className={classNames(styles['form-label'])}>
                  <LocalizedMessage id="messageToUser" values={{ firstName: firstName }} />
                </Caption>
                {supplierMessageRequired ? (
                  <span className={classNames(styles['form-label-required'])}>
                    *<LocalizedMessage id="required" />
                  </span>
                ) : null}
              </div>

              <div className={classNames(cx('col-xs-7'), styles['form-textfield'])}>
                <TextArea
                  // @ts-ignore
                  errorMessage={supplierMessageRequired ? <LocalizedMessage id="messageRequired" /> : undefined}
                  name="messageToClient"
                  label={<LocalizedMessage id="messageToUser" values={{ firstName: firstName }} />}
                  rows={2}
                  multiline
                  onChange={(event: any) => onFormChange('messageToUser', event.target.value)}
                  helpText={supplierMessageHintText}
                  fullWidth
                  defaultValue={get(supplierInputForm, 'messageToUser')}
                />
              </div>
            </div>
          ) : null}
          <VerticalSpacing sizeWithUnit="16px" />
          <div className={styles['flex-row-reverse']} data-test="submit-decision">
            <PrimaryButton
              label={<LocalizedMessage id="send" />}
              icon={<SendIcon nativeColor={colors.base.surface} />}
              iconPlacement={'right'}
              type="submit"
              disabled={isFormSubmitting}
            />
          </div>
          {hasMarketSpecificLawText && (
            <>
              <VerticalSpacing sizeWithUnit="24px" />
              <Divider />
              <VerticalSpacing sizeWithUnit="16px" />
              <Body className={cx('col-xs-12', styles.italic)}>{getLoaLawTextForMarket(market)}</Body>
            </>
          )}
        </Form>
      </div>
    </div>
  );
};

function getLoaLawTextForMarket(market: Market) {
  switch (market) {
    case Market.Sweden:
      return <LocalizedMessage id="supplierLoaLawTextSweden" />;
    case Market.Denmark:
      return <LocalizedMessage id="supplierLoaLawTextDenmark" />;
    default:
      throw new Error('No loa law text available for the market');
  }
}

const ErrorMessageRadioButtonNotSelected = () => {
  return (
    <Caption style={{ color: 'red', marginTop: 0 }}>
      <LocalizedMessage id="requiredToChooseOption" />
    </Caption>
  );
};
