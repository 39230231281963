import { AppIntegration, AppIntegrationType } from './index';

export function isMobileApp(): boolean {
  const type = AppIntegration.getAppIntegrationType();

  return type === AppIntegrationType.MOBILE_APPLICATION;
}

export function isMobileBrowser(): boolean {
  // https://developer.mozilla.org/en-US/docs/Browser_detection_using_the_user_agent#Mobile.2C_Tablet_or_Desktop
  return /Mobi/.test(navigator.userAgent);
}

/**
 * Checks if we are currently running on a mobile app or mobile browser.
 */
export function isMobileDevice(): boolean {
  const type = AppIntegration.getAppIntegrationType();

  return type === AppIntegrationType.MOBILE_APPLICATION || isMobileBrowser();
}
