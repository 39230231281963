import { LocalizedMessage } from '@cancellation-portal/internationalization';
import { selectorAppContext } from '@cancellation-portal/modules/app-context/duck';
import { getMinnaTechonoligesLegalName } from '@cancellation-portal/utils';
import { makeStyles } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Subheading1 } from '@minna-technologies/minna-ui/components/Typography/Subheading1';
import React from 'react';
import { useSelector } from 'react-redux';

interface Props {
  terminationEmailAddress?: string;
  titleId: string;
}

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.colors.action.primary,
    textDecoration: 'none',
    fontWeight: 'normal',
    width: '100%',
    wordWrap: 'break-word',
    display: 'inline-block',
  },
}));

export const CompanyInfo = ({ terminationEmailAddress, titleId }: Props) => {
  const { colors } = useTheme();
  const classes = useStyles();
  const appContext = useSelector(selectorAppContext);

  return (
    <div>
      <Subheading1 style={{ marginBottom: '16px', marginTop: '8px' }}>
        <LocalizedMessage id={titleId} />
      </Subheading1>
      <Body>{getMinnaTechonoligesLegalName(appContext.market)}</Body>
      <Body color={colors.textOn.surfaceSubdued}>
        <LocalizedMessage id="subAgentOrgNumber" />
      </Body>
      {terminationEmailAddress && (
        <Body color={colors.textOn.surfaceSubdued} style={{ marginBottom: '16px' }}>
          <LocalizedMessage id="errandEmailAddress" />:
          <a className={classes.link} href={`mailto:${terminationEmailAddress}`}>
            {terminationEmailAddress}
          </a>
        </Body>
      )}
    </div>
  );
};
