import { enGBTranslations } from '@cancellation-portal/internationalization/localization-settings/locale-settings/en-GB-settings';
import { nbNOTranslations } from '@cancellation-portal/internationalization/localization-settings/locale-settings/nb-NO-settings';
import { fiFITranslations } from '@cancellation-portal/internationalization/localization-settings/locale-settings/fi-FI-settings';
import { frBETranslations } from '@cancellation-portal/internationalization/localization-settings/locale-settings/fr-BE-settings';
import { nlBETranslations } from '@cancellation-portal/internationalization/localization-settings/locale-settings/nl-BE-settings';
import { enUSTranslations } from '@cancellation-portal/internationalization/localization-settings/locale-settings/en-US-settings';
import { esUSTranslations } from '@cancellation-portal/internationalization/localization-settings/locale-settings/es-US-settings';
import { svSETranslations } from '@cancellation-portal/internationalization/localization-settings/locale-settings/sv-SE-settings';

/**
 * fallbackLng: 'en-GB' - The default language to use if a translation is not found in the current language.
 * lng: 'en-GB' - The current language.
 * interpolation: { escapeValue: false } - This is to prevent XSS attacks.
 * This can be set to true if you want to allow interpolation of the message.
 * resources: - The resources object contains the translations for each language.
 * These translations are loaded from the JSON files in the localization folder of the module.
 * The keys are the language codes and the values are the translation objects.
 * For example, 'en-GB': { 'ticketDetails/specificTicketDetails': ticketDetails_specificTicketDetails_en_GB, ticketDetails: ticketDetails_en_GB } means that
 * the 'en-GB' language has two namespaces: 'ticketDetails/specificTicketDetails' and 'ticketDetails'.
 * The 'ticketDetails/specificTicketDetails' namespace has the translations from ticketDetails_specificTicketDetails_en_GB and the 'ticketDetails'
 * namespace has the translations from ticketDetails_en_GB.
 * Also, 'es-ES': { ticketDetails: ticketDetails_es_ES } means that the 'es-ES' language has one namespace: 'ticketDetails'
 * with the translations from ticketDetails_es_ES.
 * react: { nsMode: 'fallback' } - This is to allow the use of fallback namespaces.
 *
 * @returns The i18next options object.
 * */
export function getOptions() {
  return {
    fallbackLng: 'en-GB',
    lng: 'en-GB',
    interpolation: { escapeValue: false, prefix: '{', suffix: '}' },
    resources: {
      'en-GB': enGBTranslations,
      'nb-NO': nbNOTranslations,
      'fi-FI': fiFITranslations,
      'fr-BE': frBETranslations,
      'nl-BE': nlBETranslations,
      'en-US': enUSTranslations,
      'es-US': esUSTranslations,
      'sv-SE': svSETranslations,
    },
    react: {
      nsMode: 'fallback',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'a'],
    },
  };
}
