import type { ReactNode } from 'react';
import React from 'react';

interface VisibleProps {
  // eslint-disable-next-line
  on?: 'only-xs' | 'max-xs' | 'min-sm' | 'only-sm' | 'max-sm' | 'min-md';
  cssQuery?: string;
  children?: ReactNode;
}

// eslint-disable-next-line import/no-default-export, react/no-unsafe
export default class Visible extends React.Component<VisibleProps, any> {
  public constructor(props: VisibleProps) {
    super(props);
    this.state = {};
    this.onChange = this.onChange.bind(this);
  }

  public UNSAFE_componentWillMount() {
    this.startMediaQuery(this.props);
  }

  public UNSAFE_componentWillReceiveProps(props: VisibleProps) {
    this.startMediaQuery(props);
  }

  public startMediaQuery({ on, cssQuery }: VisibleProps) {
    if (this.state.mediaQuery) {
      this.stopMediaQuery();
    }

    const cssQueryOrDefault =
      cssQuery ||
      (() => {
        switch (on) {
          case 'only-xs':
            return 'only screen and (max-width: 639px)';
          case 'max-xs':
            return 'only screen and (max-width: 639px)';
          case 'min-sm':
            return 'only screen and (min-width: 640px)';
          case 'only-sm':
            return 'only screen and (min-width: 640px) and (max-width: 1079px)';
          case 'max-sm':
            return 'only screen and (max-width: 1079px)';
          case 'min-md':
            return 'only screen and (min-width: 1080px)';
          default:
            return '';
        }
      })();

    const mediaQuery = window.matchMedia(cssQueryOrDefault);
    mediaQuery.addListener(this.onChange);
    this.onChange(mediaQuery);

    this.setState({ mediaQuery });
  }

  public componentWillUnmount() {
    this.stopMediaQuery();
  }

  public stopMediaQuery() {
    this.state.mediaQuery.removeListener(this.onChange);
  }

  public onChange(mediaQuery: any) {
    if (mediaQuery.matches) {
      if (!this.state.visible) {
        this.setState({ visible: true });
        this.forceUpdate();
      }
    } else {
      if (this.state.visible) {
        this.setState({ visible: false });
        this.forceUpdate();
      }
    }
  }

  public render() {
    return this.state.visible ? this.props.children : null;
  }
}
