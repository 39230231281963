import type { BaseIconProps } from '@cancellation-portal/modules/shared-components/icons/BaseIconProps';
import type { FC } from 'react';
import React from 'react';

export const FlagIcon: FC<BaseIconProps> = (props) => {
  return (
    // eslint-disable-next-line
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" {...props}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M14.4 6L14 4H5v17h2v-7h5.6l.4 2h7V6z" fill={props.nativeColor} />
    </svg>
  );
};
