import type { Article, ZendeskCategory } from '@cancellation-portal/modules/Zendesk/models';
import get from 'lodash/get';
import { defaultState } from './zendesk-help-center';

export const undefinedOrTrue = (value: any) => value === undefined || value;

export const selectorArticles = (state: any): Article[] => state.zendeskHelpCenter.articles;

export const selectorArticlesUserSearchQuery = (state: any): string => state.zendeskHelpCenter.userSearchQuery;

export const selectorLoadingArticles = (state: any) => undefinedOrTrue(state.zendeskHelpCenter.loadingArticles);

export const selectorFilter = (state: any) => state.zendeskHelpCenter.filter;

export const selectorCategories = (state: any): ZendeskCategory[] => state.zendeskHelpCenter.categories;

export const selectorLoadingCategories = (state: any) => undefinedOrTrue(state.zendeskHelpCenter.loadingCategories);

export const selectorLabels = (state: any) => state.zendeskHelpCenter.labels;

export const selectorArticle = (state: any): Article => state.zendeskHelpCenter.article;

export const selectorLoadingArticle = (state: any) => undefinedOrTrue(state.zendeskHelpCenter.loadingArticle);

export const selectorCommunicationWithZendeskApiFailed = (state: any) =>
  state.zendeskHelpCenter.fetchingArticlesFailed ||
  state.zendeskHelpCenter.fetchingArticleFailed ||
  state.zendeskHelpCenter.fetchingCategoriesFailed;

export const selectorNumberOfAdditionalArticlesToDisplay = (state: any) =>
  get(state.zendeskHelpCenter, 'numberOfAdditionalArticlesToDisplay', defaultState.numberOfAdditionalArticlesToDisplay);
