// When updating this model also update a copy in '/integration-tests/cypress/models/index.ts'
export enum Currency {
  GBP = 'GBP',
  DKK = 'DKK',
  EUR = 'EUR',
  SEK = 'SEK',
  NOK = 'NOK',
  USD = 'USD',
  AUD = 'AUD',
}

/**
 * Also known as `CostWithCurrency` in Core.
 */
export interface AmountWithCurrency {
  amount: number;
  currency: Currency;
}
