import { handle } from 'redux-pack';
import { AccountStatusEvent } from './AccountStatusEvent';
import {
  ADD_LOCAL_MESSAGE_TO_HISTORY,
  ADD_UNREAD_MESSAGE,
  CLEAR_MESSAGES,
  END_CHAT,
  FORM_CHANGE,
  HIDE_RECENT_MESSAGE,
  INITIALIZE,
  MARK_ALL_MESSAGES_AS_READ,
  SERVER_EVENT,
  SET_VISITOR_IS_TYPING,
  SHOW_RECENT_MESSAGE,
  START_CHAT,
} from './zendesk-chat-event-types';

interface IState {
  isInitializing: boolean;
  initializingFailed: boolean;
  isDoneInitializing: boolean;
  // true if a chat is in progress, false if chat has ended (Not necessarily equal to events.length>0)
  isChatting: boolean;
  firstMessageForm: Record<string, unknown>;
  messageForm: {
    message: string;
  };
  visitorData: any;
  agentData: {
    display_name: string;
  };
  agentIsTyping: boolean;
  visitorIsTyping: boolean;
  events: any[];
  unreadMessages: any[];
  showRecentMessage: boolean;
}

const defaultState: IState = {
  isInitializing: false,
  initializingFailed: false,
  isDoneInitializing: false,
  // true if a chat is in progress, false if chat has ended (Not necessarily equal to events.length>0)
  isChatting: false,
  firstMessageForm: {},
  messageForm: {
    message: '',
  },
  visitorData: {},
  agentData: {
    display_name: 'Kundtjänst',
  },
  agentIsTyping: false,
  visitorIsTyping: false,
  events: [],
  unreadMessages: [],
  showRecentMessage: false,
};

export function reducer(state: IState = defaultState, action: any) {
  switch (action.type) {
    case SERVER_EVENT: {
      return serverEventReducer(state, action);
    }
    case INITIALIZE: {
      return handle(state, action, {
        start: (s) => ({ ...s, isInitializing: true }),
        finish: (s) => ({ ...s, isInitializing: false }),
        failure: (s) => ({ ...s, initializingFailed: true }),
        success: (s) => ({ ...s, isDoneInitializing: true }),
      });
    }
    case FORM_CHANGE: {
      return {
        ...state,
        [action.form]: {
          //@ts-ignore
          ...state[action.form],
          [action.property]: action.value,
        },
      };
    }
    case START_CHAT: {
      return {
        ...state,
        isChatting: true,
      };
    }
    case END_CHAT: {
      return {
        ...state,
        isChatting: false,
      };
    }
    case ADD_LOCAL_MESSAGE_TO_HISTORY: {
      return {
        ...state,
        events: [
          ...state.events,
          {
            type: 'chat.msg',
            display_name: state.visitorData.display_name,
            nick: 'visitor',
            timestamp: action.timestamp,
            msg: action.message,
          },
        ],
      };
    }
    case CLEAR_MESSAGES: {
      return {
        ...state,
        events: [],
      };
    }
    case SET_VISITOR_IS_TYPING: {
      return {
        ...state,
        visitorIsTyping: action.typing,
      };
    }
    case ADD_UNREAD_MESSAGE: {
      return {
        ...state,
        unreadMessages: [...state.unreadMessages, action.message],
      };
    }
    case MARK_ALL_MESSAGES_AS_READ: {
      return {
        ...state,
        unreadMessages: defaultState.unreadMessages,
      };
    }
    case SHOW_RECENT_MESSAGE: {
      return {
        ...state,
        showRecentMessage: true,
      };
    }
    case HIDE_RECENT_MESSAGE: {
      return {
        ...state,
        showRecentMessage: false,
      };
    }
    default:
      return state;
  }
}

function serverEventReducer(state: any, action: any) {
  const event = action.event;
  switch (event.type) {
    case 'connection_update': {
      return {
        ...state,
        connectionStatus: event.data,
      };
    }
    case 'account_status': {
      return {
        ...state,
        events: [...state.events, AccountStatusEvent(event)],
        agentStatus: event.data,
      };
    }
    case 'visitor_update': {
      return {
        ...state,
        visitorData: event.data,
      };
    }
    case 'agent_update': {
      return {
        ...state,
        agentData: event.data,
      };
    }
    case 'chat': {
      return chatDataTypeReducer(state, event);
    }
    default: {
      return state;
    }
  }
}

const chatDataTypeReducer = (state: any, event: any) => {
  switch (event.data.type) {
    case 'chat.memberjoin': {
      return {
        ...state,
        events: [...state.events, event.data],
      };
    }
    case 'chat.memberleave': {
      // Do not add "visitor left" to event log, since we want to clear the event log when this happens (for now)
      // TODO or at least when it happens intentionally.
      // When leaving by kicked / inactivity, we SHOULD keep this event :s
      // set "is leaving" boolean, if set, don't add event to array?
      // can we do this by listening to the endChat promise?
      // Probably not, since chat.memberleave should get sent after promise is done.
      // then should do manually...
      if (event.data.nick === 'visitor') {
        return state;
      }

      return {
        ...state,
        events: [...state.events, event.data],
      };
    }
    /* eslint-disable  */
    case 'chat.msg': {
      return {
        ...state,
        events: [...state.events, event.data],
      };
    }
    case 'typing': {
      return {
        ...state,
        agentIsTyping: event.data.typing,
      };
    }
    default: {
      return state;
    }
  }
};
