import { LoadingPage } from '@cancellation-portal/modules/fetching/LoadingPage';
import { minimize } from '@cancellation-portal/modules/Zendesk/ducks';
import { FirstMessage } from '@cancellation-portal/modules/Zendesk/ZendeskChat/components/FirstMessage';
import { Messaging } from '@cancellation-portal/modules/Zendesk/ZendeskChat/components/Messaging';
import { eventsArrayToNumChatEvents } from '@cancellation-portal/modules/Zendesk/ZendeskChat/zendesk-component-utils';
import { promisify } from '@cancellation-portal/utils/promise';
import clone from 'lodash/clone';
import isArray from 'lodash/isArray';
import last from 'lodash/last';
import reduce from 'lodash/reduce';
import type { FC } from 'react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
//@ts-ignore
import zChat from '../../../../../../../vendor/web_sdk';
import {
  addLocalMessageToHistory,
  firstMessageFormChange,
  logErrors,
  messageFormChange,
  scrollToBottom,
  selectorAdditionalUserInfoWhenSetVisitorInfo,
  selectorAgentIsTyping,
  selectorChatEvents,
  selectorFirstMessageForm,
  selectorIsAgentAvailable,
  selectorIsChatConnected,
  selectorMessageForm,
  selectorZChatIsDoneInitializing,
  startChat,
  ZendeskChatVisitorIsTypingEvent,
} from '../ducks/zendesk-chat';

/* eslint-disable  */
window.onresize = () => scrollToBottom();

/**
 * Group adjacent chat messages from the same sender into arrays. Events that aren't messages aren't grouped.
 * e.g. [ "Agent joined", "Niklas:a", "Niklas:b", "Agent: "c"]
 * ->   [ "Agent joined", ["Niklas:a", "Niklas:b"], ["Agent:c"] ]
 */
/* eslint-disable @typescript-eslint/no-param-reassign */
export const partitionEventsBySender = (events: any) => {
  function nicksAreEqual(nick1: any, nick2: any) {
    nick1 = nick1.split(':')[0];
    nick2 = nick2.split(':')[0];

    return nick1 === nick2;
  }

  return reduce(
    events,
    (partitionedEvents: any, event: any) => {
      if (event.type !== 'chat.msg') {
        // don't create a group
        return partitionedEvents.concat(event);
      }
      const maybeMessageGroup: any = last(partitionedEvents);
      const messageBelongsToGroup = isArray(maybeMessageGroup) && nicksAreEqual(maybeMessageGroup[0].nick, event.nick);
      if (messageBelongsToGroup) {
        // add to latest group
        const newPartitionedEvents = clone(partitionedEvents);
        newPartitionedEvents[partitionedEvents.length - 1] = maybeMessageGroup.concat(event);

        return newPartitionedEvents;
      } else {
        // create a new group
        return partitionedEvents.concat([[event]]);
      }
    },
    []
  );
};

export const ZendeskChat: FC = () => {
  const dispatch = useDispatch();

  const isChatConnected = useSelector(selectorIsChatConnected);
  const chatIsDoneInitializing = useSelector(selectorZChatIsDoneInitializing);
  const isLoading = !isChatConnected || !chatIsDoneInitializing;
  const isAgentAvailable = useSelector(selectorIsAgentAvailable);
  const partitionedEvents = partitionEventsBySender(useSelector(selectorChatEvents));
  const firstMessageForm = useSelector(selectorFirstMessageForm);
  const messageForm = useSelector(selectorMessageForm);
  const agentIsTyping = useSelector(selectorAgentIsTyping);
  const additionalUserInfo = useSelector(selectorAdditionalUserInfoWhenSetVisitorInfo);

  const handleFirstMessageFormChange = (property: unknown, value: unknown) => {
    dispatch(firstMessageFormChange(property, value));
  };

  const handleMessageFormChange = (property: unknown, value: string) => {
    dispatch(messageFormChange(property, value));
    if (value && value.trim()) {
      dispatch(ZendeskChatVisitorIsTypingEvent());
    }
  };

  const handleMinimizeChat = () => {
    dispatch(minimize());
  };

  const onStartChat = () => {
    dispatch(startChat());
  };

  const addMessageLocally = (message: string) => {
    dispatch(addLocalMessageToHistory(message));
  };

  const onStartChatInner = async (name: string, email: string, message: string) => {
    await setVisitorInfo({ name, email });
    await sendChatMsg(message);
    onStartChat();
  };

  const setVisitorInfo = async (options: { name: string; email: string }) => {
    const displayName = additionalUserInfo ? `${options.name} (${additionalUserInfo})` : options.name;
    const visitorInfo = {
      display_name: displayName,
      email: options.email,
    };
    const setVisitorInfo = zChat.setVisitorInfo.bind(zChat, visitorInfo);
    // TODO: show error message in GUI once we have a standardized component for showing errors to users

    return promisify(setVisitorInfo).catch((err: unknown) => logErrors(err, 'setVisitorInfo', visitorInfo));
  };

  const sendChatMsg = async (message: string) => {
    const sendChatMsg = zChat.sendChatMsg.bind(zChat, message);
    addMessageLocally(message);
    // TODO: show error message in GUI once we have a standardized component for showing errors to users
    await promisify(sendChatMsg).catch((err: unknown) => logErrors(err, 'sendChatMsg', message));
  };

  return isLoading ? (
    <LoadingPage />
  ) : eventsArrayToNumChatEvents(partitionedEvents) > 0 ? (
    <Messaging
      partitionedEvents={partitionedEvents}
      agentIsTyping={agentIsTyping}
      isAgentAvailable={isAgentAvailable}
      messageForm={messageForm}
      onMessageFormChange={handleMessageFormChange}
      onMinimizeChat={handleMinimizeChat}
      sendChatMsg={async (message: string) => sendChatMsg(message)}
    />
  ) : (
    <FirstMessage
      name={firstMessageForm.name}
      email={firstMessageForm.email}
      message={firstMessageForm.message}
      isAgentAvailable={isAgentAvailable}
      onStartChat={async (name: string, email: string, message: string) => onStartChatInner(name, email, message)}
      onChange={async (key: string, value: string) => handleFirstMessageFormChange(key, value)}
    />
  );
};
