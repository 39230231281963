import { reducer as internationalization } from '@cancellation-portal/internationalization/duck';
import { reducer as appContext } from '@cancellation-portal/modules/app-context/duck';
import { reducer as zendesk } from '@cancellation-portal/modules/Zendesk/ducks/zendesk';
import { reducer as zendeskChat } from '@cancellation-portal/modules/Zendesk/ducks/zendesk-chat/zendesk-chat';
import { combineReducers } from 'redux';
import { NAMESPACE as TERMINATION_NAMESPACE, reducer as termination } from './termination';

export const reducers = {
  termination,
  internationalization,
  zendesk,
  zendeskChat,
  appContext,
};

export const selectorIsCancellationPortalPage = (state: any) =>
  Boolean(state.termination && state.termination.namespace === TERMINATION_NAMESPACE) || false;

export const rootReducer = combineReducers(reducers);
