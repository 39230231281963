import type { AppType, DisplayName, Locale, Market } from './constants';

export const zendeskOnlyEmailEnabled = (features: AppFeatureToggles) =>
  features.helpCenter.enabled && !features.helpCenter.callUsEnabled && !features.helpCenter.chatEnabled;

export interface AppFeatureToggles {
  helpCenter: HelpCenter;
}

export interface HelpCenter {
  enabled: boolean;
  chatEnabled: boolean;
  callUsEnabled: boolean;
  /**
   * @deprecated Callback form is no longer used
   */
  callBackEnabled: boolean;
  emailUsEnabled: boolean;
  zendeskBaseUrl: string;
  supportEmail: string;
}

export interface BaseAppContext {
  appType: AppType;
  displayName: DisplayName;
  features: AppFeatureToggles;
  locale: Locale;
}

export interface AppContext extends BaseAppContext {
  market: Market;
}
