export const INITIALIZE = 'ZENDESK/CHAT/INITIALIZE';
export const FORM_CHANGE = 'ZENDESK/CHAT/FORM_CHANGE';
export const START_CHAT = 'ZENDESK/CHAT/START_CHAT';
export const END_CHAT = 'ZENDESK/CHAT/END_CHAT';
export const SERVER_EVENT = 'ZENDESK/CHAT/SERVER_EVENT';
export const ADD_LOCAL_MESSAGE_TO_HISTORY = 'ZENDESK/CHAT/ADD_LOCAL_MESSAGE_TO_HISTORY';
export const CLEAR_MESSAGES = 'ZENDESK/CHAT/CLEAR_MESSAGES';
export const SET_VISITOR_IS_TYPING = 'ZENDESK/CHAT/SET_VISITOR_IS_TYPING';
export const ADD_UNREAD_MESSAGE = 'ZENDESK/CHAT/ADD_UNREAD_MESSAGE';
export const MARK_ALL_MESSAGES_AS_READ = 'ZENDESK/CHAT/MARK_ALL_MESSAGES_AS_READ';
export const SHOW_RECENT_MESSAGE = 'ZENDESK/CHAT/SHOW_RECENT_MESSAGE';
export const HIDE_RECENT_MESSAGE = 'ZENDESK/CHAT/HIDE_RECENT_MESSAGE';
