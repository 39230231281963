import { formatPersonalNumber } from '@cancellation-portal/assets/js/formatters';
import { LocalizedMessage } from '@cancellation-portal/internationalization';
import type { LoaInformation } from '@cancellation-portal/models/LoaInformation';
import type { UserInformation } from '@cancellation-portal/models/UserInformation';
import type { AppContext } from '@cancellation-portal/modules/app-context';
import { withAppContext } from '@cancellation-portal/modules/app-context/react';
import { FileDownloadIcon } from '@cancellation-portal/modules/shared-components/icons/FileDownloadIcon';
import VerticalSpacing from '@cancellation-portal/shared-components/layout/VerticalSpacing';
import { getSigningName } from '@cancellation-portal/utils';
import { parseDate, parseTime } from '@cancellation-portal/utils/date';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import useTheme from '@material-ui/core/styles/useTheme';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { ButtonText } from '@minna-technologies/minna-ui/components/Typography/ButtonText';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import React from 'react';
import styles from './styles.scss';

interface Props {
  appContext: AppContext;
  loa: LoaInformation;
  showPersonalNumber: boolean;
  signingProofDownloadLink: string;
  user: UserInformation;
  onDownloadSigningProof(): void;
}

const SigningLogInner: FC<Props> = (
  { loa, user, signingProofDownloadLink, onDownloadSigningProof, showPersonalNumber },
  { localizeMessage, localizeDate }
) => {
  const { colors } = useTheme();
  const fullUserName = `${user.name.first} ${user.name.last}`;
  const fullSigneeName = `${loa.signee.name.first} ${loa.signee.name.last}`;
  const signingName = getSigningName(localizeMessage)(loa?.signee?.type);

  const ipAddressComponent = loa.signee.ipAddress ? (
    <Caption color={colors.textOn.surfaceSubdued} style={{ marginTop: '0' }}>
      <LocalizedMessage id="ipAddress" />: {loa.signee.ipAddress}
    </Caption>
  ) : null;

  return (
    <div className={styles.container}>
      <Body>{`${localizeDate(parseDate(loa.signingStartedAt))} ${parseTime(loa.signingStartedAt)}`}</Body>
      {ipAddressComponent}

      <Body color={colors.textOn.surfaceSubdued}>
        {showPersonalNumber && (
          <LocalizedMessage
            id="signingIntiatedLog"
            values={{
              fullUserName: fullUserName,
              personalNumber: formatPersonalNumber(user.personalNumber),
              authMethod: signingName,
            }}
          />
        )}
        {!showPersonalNumber && (
          <LocalizedMessage
            id="signingIntiatedLogWithoutPersonalNumber"
            values={{ fullUserName: fullUserName, authMethod: signingName }}
          />
        )}
      </Body>

      <VerticalSpacing sizeWithUnit="16px" />
      <Divider />
      <VerticalSpacing sizeWithUnit="16px" />

      <Body>{`${localizeDate(parseDate(loa.signedAt))} ${parseTime(loa.signedAt)}`}</Body>
      {ipAddressComponent}

      <Body color={colors.textOn.surfaceSubdued}>
        {showPersonalNumber && (
          <LocalizedMessage
            id="signingCompletedLog"
            values={{
              fullUserName: fullUserName,
              personalNumber: formatPersonalNumber(user.personalNumber),
              fullSigneeName: fullSigneeName,
              authMethod: signingName,
            }}
          />
        )}
        {!showPersonalNumber && (
          <LocalizedMessage
            id="signingCompletedLogWithoutPersonalNumber"
            values={{ fullUserName: fullUserName, fullSigneeName: fullSigneeName, authMethod: signingName }}
          />
        )}
      </Body>

      <Button
        href={signingProofDownloadLink}
        onClick={onDownloadSigningProof}
        color="primary"
        style={{ alignSelf: 'flex-end' }}
      >
        <FileDownloadIcon nativeColor={colors.action.primary} />
        <ButtonText color={colors.action.primary}>
          <LocalizedMessage id="proofOfSigning" />
        </ButtonText>
      </Button>
    </div>
  );
};

SigningLogInner.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
  localizeDate: PropTypes.func.isRequired,
};

export const SigningLog = withAppContext(SigningLogInner);
