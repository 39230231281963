import { LocalizedMessage } from '@cancellation-portal/internationalization';
import { DateIcon } from '@cancellation-portal/modules/shared-components/icons/DateIcon';
import useTheme from '@material-ui/core/styles/useTheme';
import { DatePicker } from '@minna-technologies/minna-ui/components/Inputs/DatePicker';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import classNames from 'classnames';
import classNamesBind from 'classnames/bind';
import PropTypes from 'prop-types';
import type { ChangeEvent, FC, ReactNode } from 'react';
import React from 'react';
import grid from '../../styles/grid.css';
import styles from './styles.scss';
const cx = classNamesBind.bind(grid);

interface Props {
  label: ReactNode;
  value?: string;
  minDate?: string;
  onChange(date: string): void;
}

export const DatePickerSection: FC<Props> = ({ label, value, onChange, minDate }, { localizeMessage }) => {
  const { colors } = useTheme();

  const handleDateInput = (event: ChangeEvent<HTMLInputElement>) => {
    const date = event.target ? event.target.value : undefined;
    if (date) onChange(date);
  };

  return (
    <div className={classNames(cx('row'), styles['margin-top-16'])}>
      <DateIcon nativeColor={colors.textOn.surfaceSubdued} className={cx('col-xs-1')} />
      <div className={cx('col-xs-4')}>
        <Title color={colors.textOn.surfaceSubdued} className={styles['form-label']}>
          {label}
        </Title>
        <span className={classNames(styles['form-label-required'])}>
          *<LocalizedMessage id="required" />
        </span>
      </div>
      <div className={cx('col-xs-7')}>
        <DatePicker
          data-test="employment-date"
          label={label}
          name="contractEndsAt"
          min={minDate}
          onChange={handleDateInput}
          errorMessage={localizeMessage('dateRequired')}
          value={value}
        />
      </div>
    </div>
  );
};

DatePickerSection.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};
